import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderView from "../../reusable/loading/loaderView";
//import loader from "../../../styletheme/assets/img/svgicons/loader.svg";
import config from "../../../constants/config.json";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import {
  getNewTicketsByPropertyUnitId,
  getNewTicketsByUserId,
} from "../../../services/tickets/unitTicketsManagement";

//for the table
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import NoRecordsFound from "../tickets/list/sub/noRecordsFound";
import { getTop5UnitsWithHighestRevenueReturn } from "../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import defaultHouseImg from "../../../styletheme/images/buildings/house.png";
import { formatCurrency } from "../../../services/utils/currencyUtils";
import {
  completeTask,
  getUserTasksByLoggedInUser,
} from "../../../services/task/userTaskManagement";
import { v4 as uuidv4 } from "uuid";
//animation
import Lottie from "react-lottie";
import empty_list_animation from "../../reusable/animations/lottie/empty_people_list.json";
import { createPropertyUnitAudit } from "../../../services/propertyUnits/audit/propertyUnitAuditManagement";
import TopRevenueCard from "./reusable/topRevenueCard";
import TicketCard from "../tickets/list/kanban/reusable/ticketCard";
import { getTheRentedVsVacantUnitsByOwnership } from "../../../services/portfolio/ownership/portfolioOwnershipManagement";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from "../../../services/helpers/color/colorService";
import { useSubscriptionUsageDetail } from "../../../services/context/subscription/subscriptionUsageDetailContext";

// Import the ShimmerEffect component and CSS
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import "../../../styletheme/css/shimmer.css";

const Dashboard = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: "Relevant news about Gurenter and its offerings",
  });
  ChartJS.register(ArcElement, Tooltip, Legend);

  const navigate = useNavigate();

  const { subscriptionUsageDetail } = useSubscriptionUsageDetail();

  const first_name = localStorage.getItem(config.first_name);
  const last_name = localStorage.getItem(config.last_name);
  const profile_img = localStorage.getItem(config.profile_img);
  const email = localStorage.getItem(config.email);
  const subscriptionPlanName = subscriptionUsageDetail.subscription_name;
  const numberOfUnitsUsed = subscriptionUsageDetail.amt_of_units_used;
  const maxNumberOfUnits = subscriptionUsageDetail.max_allowed_units;
  const numberOfDaysLeftInTrial =
    subscriptionUsageDetail.number_of_free_trial_days_left;

  //put the components on another component
  const [newUnitTickets, setNewUnitTickets] = useState([
    {
      ticket_id: "",
      property_unit_id: "",
      ticket_type: "",
      ticket_title: "",
      ticket_description: "",
      ticket_priority_id: "",
      ticket_status_id: "",
      ticket_number: "",
      ticket_status: "",
      priority_info: {
        created_at: "",
        is_active: true,
        priority: "",
        priority_id: "",
        updated_at: "",
      },
    },
  ]);

  const [topRevenueUnitPeriod, setTopRevenueUnitPeriod] = useState({
    top_revenue_unit_period_id: "",
    period: "",
    date_reported: "",
    units: [
      {
        top_revenue_unit_id: "",
        property_unit_id: "",
        unit_name: "",
        unit_image_url: "",
        revenue: 0,
        date_reported: "",
        market_rent_amount: 0,
        number_of_bathrooms: 0,
        number_of_bedrooms: 0,
        square_footage: 0,
      },
    ],
  });

  //tasks groups start
  const [taskGroups, setTaskGroups] = useState([
    {
      task_group_id: "",
      property_unit_id: "",
      created_by: "",
      created_at: "",
      updated_at: "",
      is_deleted: false,
      tasks: [
        {
          task_name: "",
          task_description: "",
          status_id: "",
          priority_id: "",
          task_due_date: "",
          is_done: false,
          created_by: "",
          task_id: "",
          task_group_id: "",
          is_deleted: false,
          priority: {
            priority: "",
            created_at: "",
            is_active: false,
            priority_id: "",
            updated_at: "",
          },
          status: {
            status: "",
            created_at: "",
            is_active: false,
            status_id: "",
            updated_at: "",
          },
        },
      ],
    },
  ]);

  //flatten version of the task
  const [tasks, setTasks] = useState([
    {
      task_name: "",
      task_description: "",
      status_id: "",
      priority_id: "",
      task_due_date: "",
      is_done: false,
      created_by: "",
      task_id: "",
      task_group_id: "",
      is_deleted: false,
      priority: {
        priority: "",
        created_at: "",
        is_active: false,
        priority_id: "",
        updated_at: "",
      },
      status: {
        status: "",
        created_at: "",
        is_active: false,
        status_id: "",
        updated_at: "",
      },
    },
  ]);

  //tasks groups end

  //chart data
  const [vacancyChartData, setVacancyChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Budget Breakdown",
        data: [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  //table components start

  const topRevenueColumns = [
    {
      field: "unit_image_url",
      headerName: "",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <img
          src={defaultHouseImg}
          className="rounded-circle avatar-xs"
          alt={`Unit ${params.row.unit_name} image`}
        />
      ),
    },
    {
      field: "unit_name",
      headerName: "Unit Name",
      width: 190,
      editable: false,
      renderCell: (params) => <strong>{params.row.unit_name}</strong>,
    },
    {
      field: "revenue_category",
      headerName: "Category",
      width: 200,
      editable: false,
      renderCell: (params) => <strong>{params.row.revenue_category}</strong>,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <strong>{formatCurrency(params.row.revenue)}</strong>
      ),
    },
  ];

  //table components end

  //table components start

  const ticketColumns = [
    {
      field: "ticket_number",
      headerName: "Number",
      width: 190,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_number}</strong>,
    },
    {
      field: "ticket_title",
      headerName: "Title",
      width: 300,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_title}</strong>,
    },
    {
      field: "ticket_status",
      headerName: "Status",
      width: 120,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_status}</strong>,
    },

    {
      field: "ticket_type",
      headerName: "Type",
      width: 150,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_type}</strong>,
    },
  ];

  //table components end

  const [isLoadingTopRevenue, setIsLoadingTopRevenue] = useState(true);

  const [isLoadingVacancyChart, setIsLoadingVacancyChart] = useState(true);

  const [isLoadingTickets, setIsLoadingTickets] = useState(true);

  const [isLoadingTasks, setIsLoadingTasks] = useState(true);

  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);

  const fetchAllNewTickets = async () => {
    setIsLoadingTickets(true);
    try {
      const response = await getNewTicketsByUserId();
      console.log("new tickets");
      console.log(response);
      setNewUnitTickets(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTickets(false);
    }
  };

  const fetchTopFiveUnitsWithHighestRevenueReturn = async () => {
    setIsLoadingTopRevenue(true);
    try {
      const response = await getTop5UnitsWithHighestRevenueReturn();
      //console.log('Top 5 units with highest revenue return');
      // console.log(response);
      setTopRevenueUnitPeriod(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTopRevenue(false);
    }
  };

  //getUserTasksByLoggedInUser

  const emptyTaskLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_list_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchAllUserTasks = async () => {
    setIsLoadingTasks(true);
    try {
      const response = await getUserTasksByLoggedInUser();
      if (response.length > 0) {
        setTaskGroups(response);
        let allTasks = [];
        response.forEach((taskGroup) => {
          taskGroup.tasks.forEach((task) => {
            allTasks.push(task);
          });
        });
        setTasks(allTasks);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTasks(false);
    }
  };

  //task is done checkbox
  const handleCheckboxChange = async (event, _unitTask) => {
    console.log(_unitTask);
    const { name, checked } = event.currentTarget;
    //const updatedTaskData = { ...taskData, [name]: checked };
    //setTaskData(updatedTaskData);

    // Update the task in the list immediately for UI feedback
    const updatedTasks = tasks.map((task) =>
      task.task_id === _unitTask.task_id ? { ...task, is_done: checked } : task
    );
    setTasks({ ...tasks, tasks: updatedTasks });

    //alert(`Task is done via checked : ${checked}`);
    //alert(`Task is done via unit: ${_unitTask.is_done}`);
    // Use _unitTask.task_id directly for accurate task identification
    if (checked) {
      console.log("Task is done");
      const response = await completeTask(_unitTask.task_id, true);
      console.log(response);

      if (response) {
        fetchAllUserTasks();

        //log the property unit audit
        logPropertyUnitAudit({
          audit_type: "Task",
          action_name: "Task Completed",
          description: `Task ${_unitTask.task_name} has been completed.`,
          _task_id: _unitTask.task_id,
        });
      }
    } else {
      console.log("Task is not done");
      const response = await completeTask(_unitTask.task_id, false);
      console.log(response);

      if (response) {
        fetchAllUserTasks();

        //log the property unit audit
        logPropertyUnitAudit({
          audit_type: "Task",
          action_name: "Task Uncompleted",
          description: `Task ${_unitTask.task_name} has been uncompleted.`,
          _task_id: _unitTask.task_id,
        });
      }
    }
  };

  const logPropertyUnitAudit = async ({
    audit_type,
    action_name,
    description,
    _task_id,
    icon_name = "ri-edit-box-line",
    icon_color = "blue",
  }) => {
    //alert(`Task ID: ${_task_id}`);
    const first_name = localStorage.getItem(config.first_name);
    const last_name = localStorage.getItem(config.last_name);
    const created_by = `${first_name} ${last_name}`;
    //use the _task_id to get the property_unit_id which is found at the task_group level
    const propertyUnit = taskGroups.find((taskGroup) =>
      taskGroup.tasks.find((task) => task.task_id === _task_id)
    );
    const property_unit_id = propertyUnit.property_unit_id;

    //alert(`Property Unit ID: ${property_unit_id}`);

    const audit = {
      property_unit_audit_id: uuidv4(),
      property_unit_id: property_unit_id,
      audit_date: new Date(),
      audit_type: audit_type,
      description: description,
      icon_details: {
        icon_name: icon_name,
        icon_color: icon_color,
      },
      action_name: action_name,
      created_by: created_by,
    };

    const response = await createPropertyUnitAudit(audit);

    console.log(response);
  };

  const getRentedVsVacantUnits = async () => {
    setIsLoadingVacancyChart(true);
    try {
      const response = await getTheRentedVsVacantUnitsByOwnership();

      if (response) {
        console.log(response);

        const rentedCount = response.rented_units;
        const vacantCount = response.vacant_units;

        const labels = [
          `(${rentedCount}) Rented Units`,
          `(${vacantCount}) Vacant Units`,
        ];

        // Define the colors for rented and vacant units
        const predefinedColors = ["#28a745", "#dc3545"]; // Green for rented, Red for vacant

        const borderColor = predefinedColors.map(
          (color) => color.replace("0.2", "1") // Set alpha channel to 1 for solid color
        );

        const hoverColors = predefinedColors.map((color) =>
          darkenColor(color, 0.7)
        ); // darken by 30%

        setVacancyChartData({
          ...vacancyChartData,
          labels: labels,
          datasets: [
            {
              label: "Rented vs Vacant Units",
              data: [response.rented_units, response.vacant_units],
              backgroundColor: predefinedColors, // Use the predefined colors
              borderColor: isDarkMode ? "#6c757d" : "white",
              borderWidth: 3,
              hoverBackgroundColor: hoverColors,
            },
          ],
        });
      }
    } catch (error) {
      //setIsLoading(false);
      console.error(error);
    } finally {
      setIsLoadingVacancyChart(false);
    }
  };

  //guage start
  const getProgressBarColor = (usagePercentage) => {
    if (usagePercentage < 50) {
      return "bg-success"; // Green
    } else if (usagePercentage < 75) {
      return "bg-warning"; // Yellow
    } else {
      return "bg-danger"; // Red
    }
  };

  const usagePercentage = (numberOfUnitsUsed / maxNumberOfUnits) * 100;
  const progressBarColor = getProgressBarColor(usagePercentage);
  //guage end

  useEffect(() => {
    setIsLoading(true);

    //stop animation after 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    window.scrollTo(0, 0);

    fetchAllNewTickets();

    fetchTopFiveUnitsWithHighestRevenueReturn();

    fetchAllUserTasks();

    getRentedVsVacantUnits();

    // Simulate loading user info
    setTimeout(() => {
      setIsLoadingUserInfo(false);
    }, 2000);
    //after loading
  }, []);
  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      <div
        className="page-content"
        data-bs-theme={isDarkMode ? "dark" : "light"}
      >
        <div className="container-fluid">
          {/* start page title */}
          <PageTitleBox pageTitle="Dashboard" previousPageTitle="" />
          {/* end page title */}

          <div className="row">
            <div className="col-md-3">
              <div className="d-flex flex-column h-100">
                <div className="row h-100">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body p-0">
                        {isLoadingUserInfo ? (
                          <ShimmerEffect type="user-info" height="300px" />
                        ) : (
                          <>
                            {numberOfDaysLeftInTrial > 0 && (
                              <div
                                className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center"
                                role="alert"
                              >
                                <i
                                  data-feather="alert-triangle"
                                  className="text-warning me-2 icon-sm"
                                ></i>

                                <div className="flex-grow-1 text-truncate">
                                  Your free trial expired in{" "}
                                  <b>{numberOfDaysLeftInTrial}</b> days.
                                </div>

                                <div className="flex-shrink-0">
                                  <Link
                                    to={"/paywall"}
                                    className="text-reset text-decoration-underline"
                                  >
                                    <b>Upgrade</b>
                                  </Link>
                                </div>
                              </div>
                            )}
                            <div className="row align-items-end">
                              <div className="col-sm-12">
                                <div className="p-4">
                                  <h5 className="fs-18 lh-base">
                                    Hi {first_name} {last_name},
                                  </h5>
                                  <p className="fs-16 lh-base">
                                    <span className="fw-semibold">
                                      {subscriptionPlanName}
                                    </span>
                                    ,
                                  </p>

                                  <div className="col-sm-12">
                                    <div className="progress progress-lg">
                                      <div
                                        className={`progress-bar ${progressBarColor}`}
                                        role="progressbar"
                                        style={{ width: `${usagePercentage}%` }}
                                        aria-valuenow={numberOfUnitsUsed}
                                        aria-valuemin="0"
                                        aria-valuemax={maxNumberOfUnits}
                                      ></div>
                                    </div>
                                  </div>
                                  <p className="fs-12 text-muted mb-0">
                                    <span className="fw-semibold">
                                      {numberOfUnitsUsed}
                                    </span>{" "}
                                    used of maximum{" "}
                                    <span className="fw-semibold">
                                      {maxNumberOfUnits}
                                    </span>{" "}
                                    units
                                  </p>

                                  <div className="mt-3">
                                    <Link
                                      to={"/paywall"}
                                      className="btn btn-success"
                                    >
                                      Upgrade Account!
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="px-3">
                                  <img
                                    src="assets/images/user-illustarator-2.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/* end card-body*/}
                    </div>
                  </div>
                  {/* end col*/}
                </div>
                {/* end row*/}

                <div className="col-xl-12">
                  <div className="card card-height-100">
                    <div className="card-header">
                      <h4 className="card-title mb-0">
                        Rented VS Vacant Units
                      </h4>
                    </div>
                    <div className="card-body">
                      {isLoadingVacancyChart ? (
                        <ShimmerEffect type="chart" height="250px" />
                      ) : (
                        <div
                          className="chart-container-1"
                          style={{
                            width: "100%",
                            height: "100%",
                            minHeight: "250px",
                          }}
                        >
                          <Doughnut
                            data={vacancyChartData}
                            options={{
                              maintainAspectRatio: false,
                              responsive: true,
                              plugins: {
                                legend: {
                                  display: true,
                                  labels: {
                                    color: isDarkMode ? "white" : "#6c757d",
                                  },
                                },
                                tooltip: {
                                  enabled: false,
                                },
                              },
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end col*/}
            <div className="col-xl-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Top Revenue Units YTD
                  </h4>
                  <div className="flex-shrink-0">
                    <div hidden className="dropdown card-header-dropdown">
                      <a
                        className="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="text-muted">
                          Report<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          Download Report
                        </a>
                        <a className="dropdown-item" href="#">
                          Export
                        </a>
                        <a className="dropdown-item" href="#">
                          Import
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="table-responsive table-card"
                    style={{ height: "450px", overflowY: "auto" }}
                  >
                    {isLoadingTopRevenue
                      ? Array(5)
                          .fill()
                          .map((_, index) => (
                            <ShimmerEffect
                              key={index}
                              type="card"
                              className="mb-3"
                            />
                          ))
                      : topRevenueUnitPeriod &&
                        topRevenueUnitPeriod.units &&
                        topRevenueUnitPeriod.units.map((unit, index) => (
                          <TopRevenueCard
                            key={index}
                            unit={unit}
                            defaultHouseImg={defaultHouseImg}
                          />
                        ))}
                  </div>
                </div>
              </div>
            </div>
            {/* end col*/}
            <div className="col-xl-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Ticket Requests
                  </h4>
                  <div className="flex-shrink-0">
                    <div hidden className="dropdown card-header-dropdown">
                      {/* ... (dropdown menu code remains unchanged) ... */}
                    </div>
                  </div>
                </div>
                {/*end card header */}
                <div className="card-body">
                  <div
                    className="table-responsive table-card p-3"
                    style={{ height: "450px", overflowY: "auto" }}
                  >
                    {isLoadingTickets
                      ? Array(5)
                          .fill()
                          .map((_, index) => (
                            <ShimmerEffect
                              key={index}
                              type="card"
                              className="mb-3"
                            />
                          ))
                      : newUnitTickets &&
                        newUnitTickets.map((ticket, index) => (
                          <TicketCard ticket={ticket} key={index} />
                        ))}
                  </div>
                </div>
                {/* end card-body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
            {/* end col*/}
            <div className="col-xl-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">My Tasks</h4>
                  <div className="flex-shrink-0">
                    <div className="dropdown card-header-dropdown">
                      <a
                        className="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        hidden
                      >
                        <span className="text-muted">
                          <i className="ri-list-check-2"></i>
                          Show More{" "}
                          {/* Open option to duplicate to other units and portfolios*/}
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          Remove
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*end card header */}

                <div className="card-body p-0">
                  <div className="align-items-center p-3 justify-content-between d-flex">
                    {tasks && tasks.length > 0 && tasks[0].task_name && (
                      <div className="p-0 mt-auto">
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                          className="text-muted text-decoration-underline"
                        >
                          <i className="ri-list-check-2"></i>
                          Show more...
                        </a>
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      disabled
                      onClick={() => {
                        //setShowModal(true);
                        //TODO: create a add task or bulk tasks view so this navigates to it
                      }}
                    >
                      <i className="ri-add-line align-middle me-1"></i> Add Task
                    </button>
                  </div>
                  {/*end card header */}
                  <div className="card-body p-0">
                    <div
                      style={{ height: "450px", overflowY: "auto" }}
                      className="p-3"
                    >
                      {isLoadingTasks ? (
                        // Shimmer effect while loading
                        Array(5)
                          .fill()
                          .map((_, index) => (
                            <ShimmerEffect
                              key={index}
                              type="task-card"
                              className="mb-3"
                            />
                          ))
                      ) : tasks && tasks.length > 0 && tasks[0].task_name ? (
                        <ul className="list-group list-group-flush border-dashed">
                          {tasks.map((unitTask, index) => (
                            <li
                              className="list-group-item ps-2 card card-body border"
                              key={index}
                              style={
                                unitTask.is_done
                                  ? { textDecoration: "line-through" }
                                  : {}
                              }
                            >
                              <div className="d-flex align-items-start">
                                <div className="form-check ps-0 flex-sharink-0">
                                  <input
                                    type="checkbox"
                                    className="form-check-input ms-0"
                                    id="task_one"
                                    name="is_done"
                                    checked={unitTask.is_done}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, unitTask)
                                    }
                                  />
                                </div>
                                <div className="flex-grow-1">
                                  <label
                                    className="form-check-label mb-0 ps-2"
                                    htmlFor="task_one"
                                    style={
                                      unitTask.is_done
                                        ? {
                                            textDecoration: "line-through",
                                          }
                                        : {}
                                    }
                                  >
                                    {unitTask.task_name}
                                  </label>

                                  <p className="text-muted fs-12 mb-0 ps-2">
                                    {unitTask.task_description.length > 150
                                      ? `${unitTask.task_description.slice(
                                          0,
                                          150
                                        )}...`
                                      : unitTask.task_description}
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <p className="text-muted fs-12 mb-0">
                                    {new Date(
                                      unitTask.task_due_date
                                    ).toLocaleDateString("en-US", {
                                      day: "numeric",
                                      month: "short",
                                    })}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="text-center">
                          <Lottie
                            options={emptyTaskLottieOptions}
                            height={200}
                            width={300}
                          />
                          <p className="text-muted">No tasks available</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/*end card body */}
              </div>
              {/*end card */}
            </div>
            {/*end col */}
          </div>
          {/* end row*/}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Dashboard;
