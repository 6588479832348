import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getContractorsLinkedToUser } from '../../../../services/contractor/contractorManagement';
import PageTitleBox from '../../../reusable/title/pageTitleBox';
import LoaderView from '../../../reusable/loading/loaderView';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import NoRecordsFound from '../../tickets/list/sub/noRecordsFound';
import {
  getUserRentalContractTemplateById,
  getUserRentalContractTemplatesByUserId,
} from '../../../../services/rental_contract/rentalContractService';
import config from '../../../../constants/config.json';

const AllContractTemplates = ({ isDarkMode }) => {
  const pageTitle = 'Contracts';
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [contractTemplates, setContractTemplates] = useState([
    {
      contract_template_id: '',
      user_id: '',
      portfolio_id: '',
      property_id: '',
      contract_name: '',
      contract_description: '',
      contract_template: '',
      created_at: Date.now(),
      updated_at: Date.now(),
      is_active: true,
      is_deleted: false,
    },
  ]);

  const contractColumns = [
    {
      field: 'contract_name',
      headerName: 'Contract Name',
      width: 200,
      editable: false,
      renderCell: (params) => <strong>{params.row.contract_name}</strong>,
    },
    {
      field: 'contract_description',
      headerName: 'Description',
      width: 200,
      editable: false,
      renderCell: (params) => (
        <strong>{params.row.contract_description}</strong>
      ),
    },
    {
      field: 'contract_template',
      headerName: 'Contract Template',
      width: 300,
      editable: false,
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.row.contract_template.replace(
              /<img /g,
              '<img style="max-width: 100%; height: auto;" '
            ),
          }}
          style={{
            overflow: 'auto',
            height: '840px',
          }}
          className='mb-5'
        />
      ),
    },
  ];

  const [isKanbanView, setIsKanbanView] = useState(false);

  const toggleView = () => setIsKanbanView(!isKanbanView);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getContractors();
  }, []);

  const getContractors = async () => {
    const user_id = localStorage.getItem(config.user_id);
    const contracts = await getUserRentalContractTemplatesByUserId(user_id);

    //contracts for length
    console.log('contracts', contracts?.length);

    if (contracts?.length === 0) {
      setIsLoading(false);
      return;
    } else {
      setContractTemplates(contracts);
      console.log(contracts);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      {/*UI Start */}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />

          {/*UI Start */}
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card' id='ticketsList'>
                <div className='card-header border-0'>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>
                      Rental Contracts
                    </h5>
                    <div className='d-flex flex-wrap gap-2'>
                      <button
                        onClick={toggleView}
                        className='btn btn-outline-primary me-2' // Added margin to the right
                      >
                        {isKanbanView ? (
                          <span>
                            <i
                              className='ri-list-check'
                              title='Switch to List View'
                            ></i>{' '}
                            List View
                          </span>
                        ) : (
                          <span>
                            <i
                              className='ri-layout-grid-fill'
                              title='Switch to Kanban View'
                            ></i>{' '}
                            Kanban View
                          </span>
                        )}
                      </button>
                      <Link
                        to={`/newContractTemplate`}
                        className='btn btn-info add-btn'
                      >
                        <i className='ri-add-line align-bottom me-1'></i> Create
                        Contract
                      </Link>
                    </div>
                  </div>
                </div>

                {/*end card-body*/}
              </div>
              {/*end card*/}
            </div>
            {/*end col*/}
          </div>
          {/*UI End */}
          <div className='row'>
            <div className='col-lg-12'>
              {isKanbanView ? (
                <div className=''>
                  <div className=''>
                    {/*up to 4 cards per row */}
                    <div className='tasks-wrapper px-3 mx-n3'>
                      <div className='row'>
                        {contractTemplates.map((template, index) => (
                          <div
                            className='col-md-4 col-lg-3 mb-4' // Adjusted this line for grid layout
                            key={index}
                          >
                            <div className='card tasks-box'>
                              <div className='card-body'>
                                <div className='d-flex mb-2'>
                                  <div className='flex-grow-1'>
                                    <h6 className='fs-15 mb-0 text-truncate task-title'>
                                      <Link
                                        to={`/contractTemplate/${template.contract_template_id}`}
                                        className='text-primary fw-medium fs-16 flex-grow-1'
                                      >
                                        {template.contract_name}
                                      </Link>
                                    </h6>
                                  </div>
                                </div>

                                <p className='text-muted'>
                                  {template &&
                                    template.contract_description.substring(
                                      0,
                                      100
                                    ) + '...'}
                                </p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      template.contract_template
                                        .replace(
                                          /<img /g,
                                          '<img style="max-width: 100%; height: auto;" '
                                        )
                                        .split(/\s+/) // Split by whitespace to get words
                                        .slice(0, 200) // Take the first 200 words
                                        .join(' ') + // Join them back into a string
                                      (template.contract_template.split(/\s+/)
                                        .length > 200
                                        ? '...'
                                        : ''), // Add ellipsis if more than 200 words
                                  }}
                                  style={{
                                    overflow: 'auto',
                                    height: '200px',
                                    border: '1px solid #ddd',
                                    borderRadius: '5px',
                                  }}
                                  className='mb-2'
                                />
                              </div>
                              {/*end card-body*/}
                              <div className='card-footer border-top-dashed'>
                                <div className='d-flex'>
                                  <div className='flex-grow-1'>
                                    <span className='text-muted'>
                                      <i className='ri-time-line align-bottom'></i>{' '}
                                      {new Date(
                                        template.updated_at
                                      ).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                      })}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='card'>
                  <div className='card-body'>
                    <div className='table-responsive table-card mb-4 px-3 mt-2'>
                      <Box sx={{ height: 650, width: '100%' }}>
                        <DataGrid
                          rows={contractTemplates || []}
                          getRowId={(row) => row.contract_template_id || 0}
                          columns={contractColumns}
                          checkboxSelection={false}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[5]}
                          disableRowSelectionOnClick
                          processRowUpdate={(updatedRow, originalRow) => {
                            console.log(updatedRow, originalRow);
                          }}
                          onProcessRowUpdateError={(
                            error,
                            updatedRow,
                            originalRow
                          ) => console.log(error, updatedRow, originalRow)}
                          onRowClick={(params) => {
                            // Navigate to the ticket detail page
                            // Assuming the route is '/ticket-detail/:ticketId'

                            navigate(
                              `/contractTemplate/${params.row.contract_template_id}`
                            );
                          }}
                          slots={{
                            toolbar: GridToolbar,
                            noResultsOverlay: NoRecordsFound,
                          }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllContractTemplates;
