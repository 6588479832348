export const actionTypeMap = {
  none: "None (Information Only)",
  schedule_viewing: "Schedule Viewing",
  complete_application: "Complete Application",
  upload_document: "Upload Document",
  schedule_interview: "Schedule Interview",
  sign_lease: "Sign Lease",
  submit_payment: "Submit Payment",
  schedule_move_in: "Schedule Move-In",
  report_issue: "Report Issue",
  request_maintenance: "Request Maintenance",
  update_contact_info: "Update Contact Information",
  review_policies: "Review Policies",
  confirm_details: "Confirm Details",
};
