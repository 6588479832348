import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  Check,
  X,
  Upload,
  FileText,
  Clock,
  CheckCircle,
  Circle,
  XCircle,
} from "lucide-react";
import {
  getTenantForTenantId,
  updateTenantApplicationStatus,
} from "../../../../../../../../services/propertyUnits/tenantScreening/prospectives/tenantRentalApplicationManagement";
import { Tab, Nav, Button, ProgressBar, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "./sub/ProspectiveTenantProfileDetail.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import { getAdvertisementListingByCampaignId } from "../../../../../../../../services/propertyUnits/advertisment/advertismentManagement";
import { getScreeningPackById } from "../../../../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement";
import PageTitleBox from "../../../../../../../reusable/title/pageTitleBox";

const screeningPhases = [
  { name: "Pre-screening", field: "has_passed_pre_screening" },
  { name: "Viewing", field: "has_passed_viewing_screening" },
  { name: "Application", field: "has_passed_application_screening" },
  { name: "Background Check", field: "has_passed_background_check" },
  { name: "Final Steps", field: "has_been_selected_for_the_rental" },
];

const ProspectiveTenantProfileDetail = ({ isDarkMode }) => {
  const pageTitle = "Prospective Tenant Profile";
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const [tenantData, setTenantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("personal");
  const [privateComments, setPrivateComments] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [advertisement, setAdvertisement] = useState(null);
  const [screeningPack, setScreeningPack] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getTenantForTenantId(tenantId);
        if (result.error) {
          throw new Error(result.error);
        }
        setTenantData(result);
        setApplicationStatus(result.application.application_status);

        // Fetch advertisement using campaign_id
        const advert = await getAdvertisementListingByCampaignId(
          result.application.campaign_id
        );
        setAdvertisement(advert);

        // Fetch screening pack using screening_pack_id from advertisement
        if (advert && advert.screening_pack_id) {
          const screeningPackData = await getScreeningPackById(
            advert.screening_pack_id
          );
          setScreeningPack(screeningPackData);
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load tenant data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, [tenantId]);

  const handleUpdateStatus = async (action) => {
    try {
      let updateData = { tenant_id: tenantId };

      switch (action) {
        case "approve_prescreening":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: false,
            has_passed_application_screening: false,
            has_passed_background_check: false,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
          };
          break;
        case "approve_viewing":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: false,
            has_passed_background_check: false,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
          };
          break;
        case "approve_application":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: true,
            has_passed_application_screening_date: new Date().toISOString(),
            has_completed_application: true,
            has_passed_background_check: false,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
          };
          break;
        case "approve_background_check":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: true,
            has_passed_background_check: true,
            has_passed_credit_check: true,
            has_passed_eviction_check: true,
            has_passed_criminal_check: true,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
          };
          break;
        case "approve_rental":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: true,
            has_passed_background_check: true,
            has_passed_credit_check: true,
            has_passed_eviction_check: true,
            has_passed_criminal_check: true,
            has_been_selected_for_the_rental: true,
            application_status: "approved",
          };
          break;
        case "reject":
          updateData = {
            ...updateData,
            has_been_hard_rejected: true,
            application_status: "rejected",
          };
          break;
        default:
          throw new Error("Invalid action");
      }

      await updateTenantApplicationStatus(updateData);
      const updatedTenant = await getTenantForTenantId(tenantId);
      setTenantData(updatedTenant);
      setApplicationStatus(updatedTenant.application.application_status);
      toast.success("Application status updated successfully");
      setShowModal(false);
    } catch (err) {
      console.error("Error updating application status:", err);
      toast.error("Failed to update application status. Please try again.");
    }
  };

  const handlePrivateCommentSubmit = (e) => {
    e.preventDefault();
    console.log("Private comment submitted:", privateComments);
    setPrivateComments("");
  };

  const handleActionClick = (action) => {
    setModalAction(action);
    setShowModal(true);
  };

  const getRejectionPhase = () => {
    if (applicationStatus === "rejected") {
      for (let i = 0; i < screeningPhases.length; i++) {
        if (!application[screeningPhases[i].field]) {
          return i;
        }
      }
    }
    return -1;
  };

  const getStatusDisplay = () => {
    if (applicationStatus === "rejected") {
      return "REJECTED";
    } else if (applicationStatus === "approved") {
      return "APPROVED";
    } else {
      const currentPhase = getCurrentPhase();
      switch (screeningPhases[currentPhase]?.name) {
        case "Pre-screening":
          return "AWAITING PRE-SCREENING";
        case "Viewing":
          return "SCHEDULED FOR VIEWING";
        case "Application":
          return "APPLICATION UNDER REVIEW";
        case "Background Check":
          return "BACKGROUND CHECK IN PROGRESS";
        case "Final Steps":
          return "FINAL APPROVAL PENDING";
        default:
          return "IN PROGRESS";
      }
    }
  };

  const handleStartInterview = () => {
    // Navigate to the interview mode screen
    //This screen will make quick acccess to the information easy and fast for making notes and so on
    console.log("Starting interview mode");
    // navigate('/interview-mode/' + tenantId);  // Uncomment and adjust path as needed
  };

  const handlePrepareContract = () => {
    // Navigate to the contract preparation screen or open a modal
    console.log("Preparing rental contract");
    // navigate('/prepare-contract/' + tenantId);  // Uncomment and adjust path as needed
  };

  const renderCriteriaIcon = (meets) => {
    return meets ? (
      <Tooltip title="Meets requirement">
        <CheckCircleIcon
          color="success"
          fontSize="small"
          style={{ marginLeft: "8px" }}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Does not meet requirement">
        <CancelIcon
          color="error"
          fontSize="small"
          style={{ marginLeft: "8px" }}
        />
      </Tooltip>
    );
  };

  if (loading) return <div className="text-center mt-5">Loading...</div>;
  if (error) return <div className="alert alert-danger mt-5">{error}</div>;
  if (!tenantData)
    return (
      <div className="alert alert-warning mt-5">No tenant data found.</div>
    );

  const { application, profile } = tenantData;

  const calculateProgress = () => {
    if (applicationStatus === "rejected") {
      return 100;
    }
    const completedSteps = screeningPhases.filter(
      (phase) => application[phase.field]
    ).length;
    return (completedSteps / screeningPhases.length) * 100;
  };

  const getCurrentPhase = () => {
    if (applicationStatus === "rejected") {
      return screeningPhases.length;
    }
    for (let i = screeningPhases.length - 1; i >= 0; i--) {
      if (application[screeningPhases[i].field]) {
        return i + 1;
      }
    }
    return 0;
  };

  return (
    <div className="page-content" data-bs-theme={isDarkMode ? "dark" : "light"}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={pageTitle}
          previousPageTitle="Applicant Pool"
          previousPageLink={`/applicantPool/${application.campaign_id}`}
        />
        <div className="row">
          <div className="col-md-3">
            <div className="card mb-4">
              <div className="card-body text-center">
                <img
                  src={
                    profile.profile_img_url || "https://via.placeholder.com/150"
                  }
                  alt={profile.first_name}
                  className="rounded-circle img-fluid mb-3"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
                <h4>{`${profile.first_name} ${profile.last_name}`}</h4>
                <p className="text-muted">Prospective Tenant</p>
                <p className="text-muted mb-4">{profile.email_address}</p>
                {applicationStatus === "rejected" && (
                  <div className="alert alert-danger mb-3">
                    <strong>Application Rejected</strong>
                  </div>
                )}
                <div className="screening-progress-summary mb-3">
                  <ProgressBar
                    now={calculateProgress()}
                    label={`${Math.round(calculateProgress())}%`}
                    variant={
                      applicationStatus === "rejected" ? "danger" : "primary"
                    }
                  />
                </div>
                <p className="small text-muted mb-2">Application Progress</p>
                <p className="text-center">
                  Current Phase:{" "}
                  <strong>
                    {applicationStatus === "rejected"
                      ? `Rejected at ${
                          screeningPhases[getRejectionPhase()]?.name
                        }`
                      : screeningPhases[getCurrentPhase()]?.name ||
                        "Not Started"}
                  </strong>
                </p>
                {applicationStatus && (
                  <p className="text-center">
                    Status: <strong>{getStatusDisplay()}</strong>
                  </p>
                )}
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Quick Actions</h5>
                <div className="d-grid gap-2">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleActionClick("approve_prescreening")}
                  >
                    Approve Pre-screening
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleActionClick("approve_viewing")}
                  >
                    Approve for Viewing
                  </Button>
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => handleActionClick("approve_application")}
                  >
                    Approve Application
                  </Button>
                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() =>
                      handleActionClick("approve_background_check")
                    }
                  >
                    Approve Background Check
                  </Button>
                  <Button
                    variant="outline-warning"
                    size="sm"
                    onClick={() => handleActionClick("approve_rental")}
                  >
                    Approve for Rental
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleActionClick("reject")}
                  >
                    Reject Application
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h3 className="card-title">Tenant Profile</h3>
                  <div>
                    <Button
                      variant="outline-primary"
                      className="me-2"
                      disabled={!application.has_passed_pre_screening}
                      onClick={() => handleStartInterview()}
                    >
                      Start Interview
                    </Button>
                    <Button
                      variant="outline-success"
                      disabled={!application.has_been_selected_for_the_rental}
                      onClick={() => handlePrepareContract()}
                    >
                      Prepare Rental Contract
                    </Button>
                  </div>
                </div>
                <Tab.Container
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Nav variant="tabs" className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="personal">
                        Personal Information
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="employment">
                        Employment Information
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="documents">Documents</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="screening">
                        Screening Progress
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="comments">Private Comments</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="personal">
                      <h5>Personal Information</h5>
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <strong>Full Name:</strong>{" "}
                            {`${profile.first_name} ${profile.last_name}`}
                          </p>
                          <p>
                            <strong>Email:</strong> {profile.email_address}
                          </p>
                          <p>
                            <strong>Phone:</strong>{" "}
                            {profile.phone_number || "N/A"}
                          </p>
                          <p>
                            <strong>Number of Occupants:</strong>{" "}
                            {application.prescreening_info.number_of_occupants}
                            {screeningPack &&
                              renderCriteriaIcon(
                                application.prescreening_info
                                  .number_of_occupants <=
                                  screeningPack.maximum_number_of_occupants
                              )}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <strong>Current Residence Duration:</strong>{" "}
                            {
                              application.prescreening_info
                                .duration_of_current_residence
                            }{" "}
                            years
                          </p>
                          <p>
                            <strong>Desired Move-in Date:</strong>{" "}
                            {new Date(
                              application.prescreening_info.desired_move_in_date
                            ).toLocaleDateString()}
                          </p>
                          <p>
                            <strong>Smoking:</strong>{" "}
                            {application.prescreening_info.does_any_one_smoke
                              ? "Yes"
                              : "No"}
                            {screeningPack &&
                              renderCriteriaIcon(
                                !application.prescreening_info
                                  .does_any_one_smoke ||
                                  screeningPack.is_smoking_allowed
                              )}
                          </p>
                          <p>
                            <strong>Pets:</strong>{" "}
                            {application.prescreening_info.has_pets
                              ? "Yes"
                              : "No"}
                            {screeningPack &&
                              renderCriteriaIcon(
                                !application.prescreening_info.has_pets ||
                                  screeningPack.is_pet_allowed
                              )}
                          </p>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="employment">
                      <h5>Employment Information</h5>
                      <p>
                        <strong>Job Category:</strong>{" "}
                        {
                          application.prescreening_info.sources_of_income[0]
                            .job_category
                        }
                      </p>
                      <p>
                        <strong>Total Monthly Income:</strong> $
                        {application.prescreening_info.total_monthly_income.toLocaleString()}
                        {screeningPack &&
                          advertisement &&
                          renderCriteriaIcon(
                            application.prescreening_info
                              .total_monthly_income >=
                              screeningPack.minimum_income_multiple *
                                advertisement.rental_unit_price
                          )}
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="documents">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Documents</h5>
                      </div>
                      <div className="list-group">
                        <div className="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <FileText size={16} className="me-2" />
                            Application Form
                          </div>
                          <span className="badge bg-success">Completed</span>
                        </div>
                        <div className="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <FileText size={16} className="me-2" />
                            Background Check
                          </div>
                          <span className="badge bg-warning text-dark">
                            Pending
                          </span>
                        </div>
                        <div className="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <FileText size={16} className="me-2" />
                            Rental Agreement
                          </div>
                          <span className="badge bg-secondary">
                            Not Started
                          </span>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="screening">
                      <h5 className="mb-4">Screening Progress</h5>
                      <div className="screening-progress mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                          {screeningPhases.map((phase, index) => {
                            const rejectionPhase = getRejectionPhase();
                            let icon;
                            if (applicationStatus === "rejected") {
                              if (index < rejectionPhase) {
                                icon = (
                                  <CheckCircle
                                    className="text-success mb-2"
                                    size={32}
                                  />
                                );
                              } else if (index === rejectionPhase) {
                                icon = (
                                  <XCircle
                                    className="text-danger mb-2"
                                    size={32}
                                  />
                                );
                              } else {
                                icon = (
                                  <Circle
                                    className="text-secondary mb-2"
                                    size={32}
                                  />
                                );
                              }
                            } else {
                              if (index < getCurrentPhase()) {
                                icon = (
                                  <CheckCircle
                                    className="text-success mb-2"
                                    size={32}
                                  />
                                );
                              } else if (index === getCurrentPhase()) {
                                icon = (
                                  <Circle
                                    className="text-primary mb-2"
                                    size={32}
                                    fill="currentColor"
                                  />
                                );
                              } else {
                                icon = (
                                  <Circle
                                    className="text-secondary mb-2"
                                    size={32}
                                  />
                                );
                              }
                            }
                            return (
                              <div
                                key={phase.field}
                                className="d-flex flex-column align-items-center"
                                style={{ width: "20%" }}
                              >
                                {icon}
                                <span
                                  className={`text-center ${
                                    (applicationStatus === "rejected" &&
                                      index === rejectionPhase) ||
                                    (applicationStatus !== "rejected" &&
                                      index === getCurrentPhase())
                                      ? "fw-bold"
                                      : ""
                                  }`}
                                >
                                  {phase.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="comments">
                      <h5>Private Comments</h5>
                      <form onSubmit={handlePrivateCommentSubmit}>
                        <div className="form-group mb-3">
                          <textarea
                            className="form-control"
                            rows="3"
                            value={privateComments}
                            onChange={(e) => setPrivateComments(e.target.value)}
                            placeholder="Add a private comment..."
                          ></textarea>
                        </div>
                        <Button type="submit" variant="primary">
                          Add Comment
                        </Button>
                      </form>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Button variant="outline-primary" onClick={() => navigate(-1)}>
            <ArrowLeft size={16} className="me-2" />
            Back to Applicant Pool
          </Button>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to {modalAction.replace("_", " ")} this
            application?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleUpdateStatus(modalAction)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ProspectiveTenantProfileDetail;
