import React, { useEffect, useState, useReducer } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';
import {
  loginUser,
  registerUser,
  sendLoginRequest,
} from '../../../services/authentication/auth';

import Input from '../../reusable/form/Input';
import uuid from 'react-uuid';
import { base64ImageString } from '../../../constants/images/defaultImgs';
import config from '../../../constants/config.json';
//import { fetchPalByInviteCode } from '../../../services/palz/palzManagement';
import { set } from 'lodash';
import logo from '../../../styletheme/logos/gurenter_logo.png';
import ImageCarousel from '../../reusable/carousel/imageCarousel';
import { UserProfile } from '../../../services/authentication/model/user';

const Signup = () => {
  //for login
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { inviteCode } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    loginCode: '',
    user_type: '',
  });

  const [errors, setErrors] = useState({});
  const schema = {
    first_name: Joi.string().required().label('First Name'),
    last_name: Joi.string().required().label('Last Name'),
    email_address: Joi.string().email().required().label('Email Address'), //use joi in node as well
    // loginCode: Joi.string().min(7).optional().label('Login Code'), //has its own schema
    user_type: Joi.string().required().label('User Type'),
    loginCode: Joi.string().optional().label('Login Code').allow(''), //has its own schema
  };

  const [tempAccInfo, setTempAccInfo] = useState({
    access_token: '',
    refresh_token: '',
    user_id: '',
  });

  //this is the home page (only authenticated people)
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: 'Relevant news about Gurenter and its offerings',
  });

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  const [isRequestEmailSent, setIsRequestEmailSent] = useState(false);

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);

    //  getAdditionalInfo();

    //after loading
    setLoading({ isLoading: false });
  }, []);

  //follow how its done for expense category to make the changes

  const userTypeOptions = [
    { value: 'Landlord', label: 'Landlord' },
    { value: 'PropertyManager', label: 'Property Manager' },
    { value: 'BookKeeper', label: 'Book Keeper' },
  ];

  const [selectedUserType, setSelectedUserType] = useState(null);

  const handleUserTypeChange = (option) => {
    setSelectedUserType(option);
    data.user_type = option.value;
  };

  function findExpenseCategoryByKey(key) {
    const category = userTypeOptions.find((option) => option.value === key);
    return category ? category.label : null;
  }
  /*
  TODO need to re-enable for inviting a user or landlord
  const getAdditionalInfo = async () => {
    if (inviteCode) {
      //alert('Login Code: ' + loginCode);
      //find the email address based on the login code
      //findEmailByLoginCode
      const response = await fetchPalByInviteCode(inviteCode);

      //console.log('Invite code Response');
      //console.log(response);

      //set the email address

      setData({
        first_name: response.pal_first_name,
        last_name: response.pal_last_name,
        email_address: response.email_address,
      });
    }
  };

  */

  //TODO: Form Validation (Need to find a way to make this more streamlined)
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var email_address = this.email_address.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    //console.log(errors);

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    if (input && input.currentTarget) {
      const errorMessage = validateProperty(input.currentTarget);

      const newErrors = { ...errors };
      if (errorMessage) {
        newErrors[input.currentTarget.name] = errorMessage;
      } else delete newErrors[input.currentTarget.name];

      const newData = {
        ...data,
        [input.currentTarget.name]: input.currentTarget.value,
      };

      setData(newData);
      setErrors(newErrors);
    } else if (input && input.value) {
      handleUserTypeChange({
        value: selectedUserType.value,
        label: findExpenseCategoryByKey(selectedUserType.label), // Replace this with the actual label if available
      });
    }
  };
  const verifyLoginCodeRequest = async () => {
    //verify function
    try {
      //payload
      const user_id = '';
      const first_name = '';
      const last_name = '';
      const email_address = data.email_address;
      const login_code = data.loginCode;

      let userPayload = new UserProfile({
        user_id: user_id,
        first_name: first_name,
        last_name: last_name,
        email_address: email_address,
        login_code: login_code,
      });

      const response = await loginUser(userPayload);

      if (response.isError) {
        toast.error(response.message);
        return;
      } else if (response.isError === false) {
        toast.success(response.message);

        //TODO: change this it should take you to the onboarding to set a new budget, then to set an expense set an config isOnboarding to handle this
        //force update
        forceUpdate();

        //if its a landlord go to the landlord onboarding (paywall)
        if (data.user_type === 'Landlord') {
          window.location = '/paywall'; //to implement
        } else {
          // Navigate to the dashboard
          window.location = '/dashboard';
        }
      }
    } catch (ex) {
      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.email_address = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  const doSubmit = async () => {
    //login function
    try {
      //payload
      const user_id = uuid();
      const first_name = data.first_name;
      const last_name = data.last_name;
      const email_address = data.email_address;
      const user_type = data.user_type;

      let registerPayLoad = new UserProfile({
        user_id: user_id,
        first_name: first_name,
        last_name: last_name,
        email_address: email_address,
        profile_img: base64ImageString,
        user_type: user_type,
      });

      //console.log('Register Payload');
      // console.log(registerPayLoad);

      //Sign Up confirmation here
      const response = await registerUser(registerPayLoad);

      console.log('API Response on UI');
      console.log(response);

      if (response.isError) {
        toast.error(response.message);
        return;
      } else if (response.isError === false) {
        toast.success(response.message);

        console.log('Temp');
        console.log(response);

        //set the temp values

        //show the login code textfield
        setIsRequestEmailSent(true);
      }

      //if success then show the confirm login code
      //alert(response.message);
      //get and set the profile
      /*
      if (response.isError) {
        toast.error(response.message);
        return;
      } else if (response.isError === false) {
        toast.success(response.message);
        //force update
        forceUpdate();

        // Navigate to the dashboard
        navigate('/dashboard');
        
      }
      */
      //Not working
      // alert(`Login called ${test}`);

      //toast.success('Logged In Successfully');
    } catch (ex) {
      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.email_address = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  //for the slide show
  const [slideShowImages, setSlideShowImages] = useState([
    {
      id: 1,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-06%20at%2011.42.25%E2%80%AFAM.png?alt=media&token=9621af52-4a8c-40d4-b89f-2b0b8281a501',
      title: 'Your Financial Hub',
      description: 'See your finances at a glance.',
    },
    {
      id: 2,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.43.09%E2%80%AFAM.png?alt=media&token=44bc09b9-7480-43ea-b7b9-0121ef298889',
      title: 'Goal Setting with your Pal',
      description: 'You can share budget goals with others.',
    },
    {
      id: 3,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.42.12%E2%80%AFAM.png?alt=media&token=eeabdaa9-56cc-488c-97f2-86d2d84d6ac6',
      title: 'How is my budget doing?',
      description: 'Easy to read breakdown of your budget and spending habits.',
    },
    {
      id: 4,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.42.28%E2%80%AFAM.png?alt=media&token=2b725bd3-3ab4-4c8e-b38f-fd999962ca95',
      title: 'Your Current Expenses',
      description: 'See your expenses for your current budget period.',
    },
  ]);

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && (
        <div
          id='global-loader'
          style={{
            zIndex: '99',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
          }}
        >
          <Bars
            height='80'
            width='80'
            color='#4fa94d'
            ariaLabel='bars-loading'
            wrapperStyle={{}}
            wrapperclassName=''
            visible={true}
          />
        </div>
      )}
      {/*/Loader */}
      {/*Page */}{' '}
      <div className='page'>
        <div className='container-fluid'>
          <div className='row no-gutter'>
            <div className='col-xl-12'>
              <div className='row justify-content-center'>
                <div
                  id='centerLoginForm'
                  className='col-md-4 col-lg-3 col-xl-3 center-login'
                >
                  {/* Sign Up Form */}

                  <div className='card mt-4 card-bg-fill'>
                    <div className='card-body p-4'>
                      <div className='text-center mt-2'>
                        <img
                          src={logo}
                          width='60'
                          height='60'
                          alt='description_of_image'
                        />
                        <h5 className='text-primary'>Welcome to Gurenter!</h5>
                        <p className='text-muted'>
                          Sign up to continue to Gurenter.
                        </p>
                      </div>
                      <div className='p-2 mt-4'>
                        {!isRequestEmailSent && (
                          <>
                            <form onSubmit={handleSubmit}>
                              <div className='mb-3'>
                                <Input
                                  name='first_name'
                                  type='text'
                                  error={errors.first_name}
                                  value={data.first_name}
                                  placeholder='Write your First Name.'
                                  label='First Name'
                                  onChange={handleChange}
                                />
                              </div>
                              <div className='mb-3'>
                                <Input
                                  name='last_name'
                                  type='text'
                                  error={errors.last_name}
                                  value={data.last_name}
                                  placeholder='Write your Last Name.'
                                  label='Last Name'
                                  onChange={handleChange}
                                />
                              </div>
                              <div className='mb-3'>
                                <label htmlFor='userType'>User Type</label>

                                <Select
                                  name='userType'
                                  value={selectedUserType}
                                  onChange={handleUserTypeChange}
                                  options={userTypeOptions}
                                />
                              </div>

                              <div className='mb-3'>
                                <Input
                                  name='email_address'
                                  type='email'
                                  error={errors.email_address}
                                  value={data.email_address}
                                  placeholder='Write your Email Address.'
                                  label='Email Address'
                                  onChange={handleChange}
                                />
                              </div>

                              <div className='mt-4'>
                                <button
                                  disabled={validate()}
                                  className='btn btn-success w-100'
                                >
                                  Sign Up
                                </button>
                              </div>
                            </form>
                          </>
                        )}
                        <div className='mb-3'>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            {isRequestEmailSent && (
                              <div className='form-group'>
                                <Input
                                  name='loginCode'
                                  type='password'
                                  error={errors.loginCode}
                                  value={data.loginCode}
                                  placeholder='Write your Login Code.'
                                  label='Login Code'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='mt-4'>
                          {isRequestEmailSent && (
                            <button
                              //disabled={validate()}
                              className='btn btn-success w-100'
                              onClick={() => {
                                verifyLoginCodeRequest();
                              }}
                            >
                              Verify Authentication Code
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*end card body*/}
                  </div>
                  {/*end card*/}

                  <div className='mt-4 text-center'>
                    <p className='mb-0'>
                      Have an account ?{' '}
                      <Link
                        to={'/'}
                        className='fw-semibold text-primary text-decoration-underline'
                      >
                        {' '}
                        Login{' '}
                      </Link>{' '}
                    </p>
                  </div>
                </div>

                <div className='col-md-8 col-lg-4 col-xl-4'>
                  {/* Slides Reusable */}
                  <ImageCarousel slideShowImages={slideShowImages} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signup;
