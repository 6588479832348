import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getContractorsLinkedToUser } from '../../../../services/contractor/contractorManagement';
import PageTitleBox from '../../../reusable/title/pageTitleBox';
import LoaderView from '../../../reusable/loading/loaderView';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import NoRecordsFound from '../../tickets/list/sub/noRecordsFound';

const AllContractors = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [contractors, setContractors] = useState([
    {
      contractor_id: '',
      portfolio_id: '',
      property_id: '',
      first_name: '',
      last_name: '',
      email_address: '',
      phone_number: '',
      company_name: '',
      company_address: '',
      company_address_2: '',
      company_city: '',
      company_state: '',
      company_zip: '',
      company_country: '',
      company_logo: '',
      company_website: '',
      company_phone: '',
      company_email: '',
      company_description: '',
      company_license: '',
      company_insurance: '',
      company_insurance_expiration: new Date(),
      company_first_licensure_date: new Date(),
      company_license_expiration: new Date(),
    },
  ]);

  const contractorColumns = [
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 200,
      editable: false,
      renderCell: (params) => <strong>{params.row.first_name}</strong>,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 200,
      editable: false,
      renderCell: (params) => <strong>{params.row.last_name}</strong>,
    },
    {
      field: 'company_name',
      headerName: 'Company',
      width: 220,
      editable: false,
      renderCell: (params) => <strong>{params.row.company_name}</strong>,
    },
    {
      field: 'email_address',
      headerName: 'Email Address',
      width: 220,
      editable: false,
      renderCell: (params) => <strong>{params.row.email_address}</strong>,
    },
    {
      field: 'phone_number',
      headerName: 'Phone Number',
      width: 150,
      editable: false,
      renderCell: (params) => <strong>{params.row.phone_number}</strong>,
    },
    {
      field: 'company_description',
      headerName: 'Description',
      width: 800,
      editable: false,
      renderCell: (params) => <strong>{params.row.company_description}</strong>,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getContractors();
  }, []);

  const getContractors = async () => {
    const contractors = await getContractorsLinkedToUser();

    if (contractors.length === 0) {
      setIsLoading(false);
      return;
    } else {
      setContractors(contractors);
      console.log(contractors);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      {/*UI Start */}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox pageTitle='All Contractors' />

          {/*UI Start */}
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card' id='ticketsList'>
                <div className='card-header border-0'>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Contractors</h5>
                    <div className='d-flex flex-wrap gap-2'>
                      <Link
                        to={`/createContractor`}
                        className='btn btn-info add-btn'
                      >
                        <i className='ri-add-line align-bottom me-1'></i> Create
                        Contractor
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='card-body'>
                  <div className='table-responsive table-card mb-4 px-3'>
                    <Box sx={{ height: 650, width: '100%' }}>
                      <DataGrid
                        rows={contractors || []}
                        getRowId={(row) => row.contractor_id || 0}
                        columns={contractorColumns}
                        checkboxSelection={false}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        processRowUpdate={(updatedRow, originalRow) => {
                          console.log(updatedRow, originalRow);
                        }}
                        onProcessRowUpdateError={(
                          error,
                          updatedRow,
                          originalRow
                        ) => console.log(error, updatedRow, originalRow)}
                        onRowClick={(params) => {
                          // Navigate to the ticket detail page
                          // Assuming the route is '/ticket-detail/:ticketId'
                          navigate(`/contractor/${params.row.contractor_id}`);
                        }}
                        slots={{
                          toolbar: GridToolbar,
                          noResultsOverlay: NoRecordsFound,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
                {/*end card-body*/}
              </div>
              {/*end card*/}
            </div>
            {/*end col*/}
          </div>
          {/*UI End */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllContractors;
