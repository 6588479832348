import React from 'react';
import { Link } from 'react-router-dom';
import TicketCard from './reusable/ticketCard';

const TicketsKanbanBoard = ({
  isKanbanView,
  toggleView,
  newTicketsCount,
  inProgressTicketsCount,
  pendingTicketsCount,
  completedTicketsCount,
  newUnitTickets,
  inProgressUnitTickets,
  pendingUnitTickets,
  completedUnitTickets,
  propertyUnitId,
}) => {
  return (
    <React.Fragment>
      {/* Kanban Start (will be a switch later on)*/}

      {/* Kanban Header Card */}

      <div className='card'>
        <div className='card-header border-0'>
          <div className='d-flex align-items-center'>
            <h5 className='card-title mb-0 flex-grow-1'>Tickets</h5>
            <div className='flex-shrink-0'>
              <div className='d-flex flex-wrap gap-2'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-wrap gap-2'>
                    <button
                      onClick={toggleView}
                      className='btn btn-outline-primary me-2' // Added margin to the right
                    >
                      {isKanbanView ? (
                        <span>
                          <i
                            className='ri-list-check'
                            title='Switch to List View'
                          ></i>{' '}
                          List View
                        </span>
                      ) : (
                        <span>
                          <i
                            className='ri-layout-grid-fill'
                            title='Switch to Kanban View'
                          ></i>{' '}
                          Kanban View
                        </span>
                      )}
                    </button>
                    <Link
                      to={
                        propertyUnitId
                          ? `/newUnitTicket/${propertyUnitId}`
                          : '/newUnitTicket'
                      }
                      className='btn btn-info add-btn'
                    >
                      <i className='ri-add-line align-bottom me-1'></i> Create
                      Ticket
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*end row*/}
        </div>
        {/*end card-body*/}
      </div>
      {/*end card*/}

      <div className='tasks-board mb-3 pb-5' id='kanbanboard'>
        {/* New Ticket Section start*/}
        <div className='tasks-list'>
          <div className='d-flex mb-3'>
            <div className='flex-grow-1'>
              <h6 className='fs-14 text-uppercase fw-semibold mb-0'>
                New{' '}
                <small className='badge bg-secondary align-bottom ms-1 totaltask-badge'>
                  {newTicketsCount}
                </small>
              </h6>
            </div>
            <div className='flex-shrink-0' hidden>
              <div className='dropdown card-header-dropdown'>
                <a
                  className='text-reset dropdown-btn'
                  href='#'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <span className='fw-medium text-muted fs-12'>
                    Priority<i className='mdi mdi-chevron-down ms-1'></i>
                  </span>
                </a>
                <div className='dropdown-menu dropdown-menu-end'>
                  <a className='dropdown-item' href='#'>
                    Priority
                  </a>
                  <a className='dropdown-item' href='#'>
                    Date Added
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div data-simplebar className='tasks-wrapper px-3 mx-n3'>
            <div id='todo-task' className='tasks'>
              {/*New Tickets */}
              {newUnitTickets.map((ticket, index) => (
                <TicketCard ticket={ticket} key={index} />
              ))}
              {/*end card*/}
            </div>
          </div>
        </div>
        {/*end tasks-list*/}
        {/* New Ticket Section end*/}

        {/* Inprogress Ticket Section start*/}
        <div className='tasks-list'>
          <div className='d-flex mb-3'>
            <div className='flex-grow-1'>
              <h6 className='fs-14 text-uppercase fw-semibold mb-0'>
                In Progress{' '}
                <small className='badge bg-warning align-bottom ms-1 totaltask-badge'>
                  {inProgressTicketsCount}
                </small>
              </h6>
            </div>
            <div className='flex-shrink-0' hidden>
              <div className='dropdown card-header-dropdown'>
                <a
                  className='text-reset dropdown-btn'
                  href='#'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <span className='fw-medium text-muted fs-12'>
                    Priority<i className='mdi mdi-chevron-down ms-1'></i>
                  </span>
                </a>
                <div className='dropdown-menu dropdown-menu-end'>
                  <a className='dropdown-item' href='#'>
                    Priority
                  </a>
                  <a className='dropdown-item' href='#'>
                    Date Added
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div data-simplebar className='tasks-wrapper px-3 mx-n3'>
            <div id='inprogress-task' className='tasks'>
              {/*In Progress Tickets */}
              {inProgressUnitTickets.map((ticket, index) => (
                <TicketCard ticket={ticket} key={index} />
              ))}
              {/*end card*/}
            </div>
          </div>
        </div>
        {/*end tasks-list*/}

        {/* Inprogress Ticket Section end*/}

        {/* Pending Ticket Section start*/}
        <div className='tasks-list'>
          <div className='d-flex mb-3'>
            <div className='flex-grow-1'>
              <h6 className='fs-14 text-uppercase fw-semibold mb-0'>
                Pending{' '}
                <small className='badge bg-info align-bottom ms-1 totaltask-badge'>
                  {pendingTicketsCount}
                </small>
              </h6>
            </div>
            <div className='flex-shrink-0' hidden>
              <div className='dropdown card-header-dropdown'>
                <a
                  className='text-reset dropdown-btn'
                  href='#'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <span className='fw-medium text-muted fs-12'>
                    Priority<i className='mdi mdi-chevron-down ms-1'></i>
                  </span>
                </a>
                <div className='dropdown-menu dropdown-menu-end'>
                  <a className='dropdown-item' href='#'>
                    Priority
                  </a>
                  <a className='dropdown-item' href='#'>
                    Date Added
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div data-simplebar className='tasks-wrapper px-3 mx-n3'>
            <div id='reviews-task' className='tasks'>
              {/*Pending Tickets */}
              {pendingUnitTickets.map((ticket, index) => (
                <TicketCard ticket={ticket} key={index} />
              ))}
              {/*end card*/}
            </div>
          </div>
        </div>
        {/*end tasks-list*/}

        {/* Pending Ticket Section end*/}

        {/* Completed Ticket Section start*/}
        <div className='tasks-list'>
          <div className='d-flex mb-3'>
            <div className='flex-grow-1'>
              <h6 className='fs-14 text-uppercase fw-semibold mb-0'>
                Completed{' '}
                <small className='badge bg-success align-bottom ms-1 totaltask-badge'>
                  {completedTicketsCount}
                </small>
              </h6>
            </div>
            <div className='flex-shrink-0' hidden>
              <div className='dropdown card-header-dropdown'>
                <a
                  className='text-reset dropdown-btn'
                  href='#'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <span className='fw-medium text-muted fs-12'>
                    Priority<i className='mdi mdi-chevron-down ms-1'></i>
                  </span>
                </a>
                <div className='dropdown-menu dropdown-menu-end'>
                  <a className='dropdown-item' href='#'>
                    Priority
                  </a>
                  <a className='dropdown-item' href='#'>
                    Date Added
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div data-simplebar className='tasks-wrapper px-3 mx-n3'>
            <div id='completed-task' className='tasks'>
              {/*Completed Tickets */}

              {completedUnitTickets.map((ticket, index) => (
                <TicketCard ticket={ticket} key={index} />
              ))}
              {/*end card*/}
            </div>
          </div>
        </div>
        {/*end tasks-list*/}
      </div>
      {/*end task-board*/}
    </React.Fragment>
  );
};

export default TicketsKanbanBoard;
