import http from "../../general/httpService";
import api from "../../../constants/config.json";
import config from "../../../constants/config.json";

///create-checkout-session
export async function createCheckoutSession(lookupKey) {
  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/create-checkout-session`;
  const { data: sessionId } = await http.post(fullApiEndpoint, {
    lookup_key: lookupKey,
  });
  return sessionId;
}

///create-portal-session
export async function createPortalSession(sessionId) {
  //if sessionId is null, return null
  if (!sessionId) {
    return null;
  }

  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/create-portal-session`;
  const { data: portalSession } = await http.post(fullApiEndpoint, {
    session_id: sessionId,
  });
  return portalSession;
}

///TODO: webhook to be set up in stripe

///get_last_payment_status and is a post and takes a stripe_customer_id
export async function getLastPaymentStatus() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);
  if (!customerStripeId) {
    return null;
  }

  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/get_last_payment_status`;

  try {
    const { data: paymentStatus } = await http.post(fullApiEndpoint, {
      stripe_customer_id: customerStripeId,
    });
    return paymentStatus;
  } catch (error) {
    console.log("Failed to fetch payment status:", error);
    return null; // or handle error as needed
  }
}

///create-customer-session
export async function createCustomerSession() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);
  //if customerStripeId is null, return null
  if (!customerStripeId) {
    return null;
  }

  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/create-customer-session`;
  const { data: customerSession } = await http.post(fullApiEndpoint, {
    stripe_customer_id: customerStripeId,
  });
  return customerSession; // This should be an object with a client_secret property
}

///get_all_subscription_history
export async function getAllSubscriptionHistory() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);
  //if customerStripeId is null, return null
  if (!customerStripeId) {
    return null;
  }

  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/get_all_subscription_history`;
  const { data: subscriptionHistory } = await http.post(fullApiEndpoint, {
    stripe_customer_id: customerStripeId,
  });
  return subscriptionHistory;
}

///get_active_subscriptions_from_stripe
//need to get the subscription name to display to the user and the number of units allowed vs how many are being used (count all
//the units that are being used) then update that info in the background
//Mainly focus on this and the tenant screening and showing the paywall after registration
export async function getActiveSubscriptionsFromStripe() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);

  //if customerStripeId is null, return null
  if (!customerStripeId) {
    return null;
  }
  const fullApiEndpoint =
    api.apiEndpoint +
    `/subscription_manager/get_active_subscriptions_from_stripe`;
  const { data: activeSubscriptions } = await http.post(fullApiEndpoint, {
    stripe_customer_id: customerStripeId,
  });

  return activeSubscriptions;
}

//pause subscription

//cancel subscription
///cancel_subscription
export async function cancelSubscription(subscription_id) {
  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/cancel_subscription`;
  const { data: subscriptionStatus } = await http.post(fullApiEndpoint, {
    subscription_id: subscription_id,
  });
  return subscriptionStatus;
}

//get_or_create_subscription_detail
export async function getOrCreateSubscriptionDetail() {
  const user_id = localStorage.getItem(config.user_id);
  //if user_id is null, return null
  if (!user_id) {
    return null;
  }
  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/get_or_create_subscription_detail`;
  const { data: subscriptionDetail } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });
  return subscriptionDetail;
}
