import React, { useEffect, useState } from 'react';
import Joi from 'joi-browser';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import PageTitleBox from '../../../../../reusable/title/pageTitleBox';
import Input from '../../../../../reusable/form/Input';
import { fetchAllCurrencies } from '../../../../../../services/systemDefined/currency/sysCurrencyManagement';
import Select from 'react-select';
import {
  getPropertyDetailByPropertyUnitId,
  getPropertyUnitById,
  getPropertyUnitsById,
} from '../../../../../../services/property/retrieve/propertyRetrievalService';
import config from '../../../../../../constants/config.json';
import { getScreeningPacksByUserCreatorId } from '../../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement';
import MultiImageUploader from '../../../../../reusable/uploader/multiImageUploader';
import { createPropertyUnitAdvertListing } from '../../../../../../services/propertyUnits/advertisment/advertismentManagement';
import LoaderView from '../../../../../reusable/loading/loaderView';
/*
TODO: need to work on the advertisment list, create and update screens

*/
//add the images manually if anything till the backend is ready

const CreateNewAdvertisement = ({ isDarkMode }) => {
  const pageTitle = 'Create New Advertisement';
  const { propertyUnitId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const user_id = localStorage.getItem(config.user_id);
  const [base64Data, setBase64Data] = useState([]);
  //images to upload for the advertisement
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);

  const navigate = useNavigate();

  //transform the flat form object into the nested object when saving
  const [advertFormData, setAdvertFormData] = useState({
    advert_id: '',
    landlord_id: user_id,
    property_unit_id: propertyUnitId,
    date_advert_ends: Date.now(),
    campaign_id: '',
    rental_unit_price: 0,
    rental_unit_amt_currency: 'USD',

    //security deposit
    security_deposit: 0,
    // unit_type: '',
    //unit_current_rental_status: 'Vacant',
    // inside_of_unit_image_urls: [],
    screening_pack_id: '',
  });
  //screening pack dropdown list options
  const [screeningPackOptions, setScreeningPackOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [propertyUnit, setPropertyUnit] = useState({
    property_unit_id: '',
    property_id: '',
    unit_name: '',
    market_rent_amount: 0.0,
    number_of_bathrooms: 0,
    number_of_bedrooms: 0,
    square_footage: 0,
    is_rented: false,
  });

  const [property, setProperty] = useState({
    property_id: '',
    portfolio_id: '',
    currency_id: '',
    property_type_id: '',
    sub_property_type_id: '',
    property_alias_name: '',
    street_address_1: '',
    street_address_2: '',
    city_town: '',
    state_parish: '',
    zip_postal_code: '',
    country_id: '',
    country: '',
  });

  const [currentAdvertisment, setCurrentAdvertisment] = useState({
    advert_id: '',
    landlord_id: '',
    property_unit_id: propertyUnitId,
    date_advert_ends: Date.now(),
    campaign_id: '',
    property_unit: {
      property_unit_id: '',
      rental_property_id: '',
      unit_name: '',
      rent_receipt_sequence: 1,
      rental_unit_price: 0,
      rental_unit_amt_currency: 'USD',
      unit_type: '',
      unit_current_rental_status: 'Vacant',
      inside_of_unit_image_urls: [],
      unit_specifications: {
        number_of_bedrooms: 0,
        unit_square_footage: 0,
        parking_detail: {
          driveway_type: '',
          parking_space: 0,
        },
        number_of_rooms: 0,
        number_of_bathrooms: 0,
        number_of_half_bathroom: 0,
        has_kitchen: false,
        has_patios: false,
        has_porch: false,
        has_dining_room: false,
        has_half_bathrooms: false,
        has_living_room: false,
        has_car_garage: false,
      },
      pet_specifications: {
        allow_pets: false,
        max_number_of_pets_allowed: 0,
        pets_allowed: [],
      },
      current_main_tenant_id: 'None',
      current_tenants: [
        {
          tenant_id: '',
          first_name: '',
          middle_name: '',
          last_name: '',
          phone_number: '',
          tenant_img_url: '',
          email_address: '',
        },
      ],
      date_last_rented: Date.now(),
      record_created_date: Date.now(),
      is_rented: false,
      is_activated: true,
    },
    unit_advert_image_urls: [],
    security_deposit: 0,
    screening_pack: {
      screening_pack_id: '',
      user_creator_id: '',
      ownership_group_id: '',
      pack_name: '',
      pack_description: '',
      minimum_income_multiple: 1.0,
      minimum_credit_score: 630,
      maximum_number_of_occupants: 1,
      allow_declared_bankruptcy: false, //can set defaults in settings
      allow_declared_eviction: false,
      must_provide_landlord_reference: false,
      allow_has_been_convicted_of_crime: false,
      is_smoking_allowed: false,
      is_pet_allowed: false,
      is_hard_reject: false,
    },
    currency_id: '',
    currency_name: '',
    is_advert_expired: false,
  });
  const [advertismentId, setAdvertismentId] = useState(uuidv4());

  //screening pack
  const [screeningPacks, setScreeningPacks] = useState([
    {
      screening_pack_id: '',
      user_creator_id: '',
      ownership_group_id: '',
      pack_name: '',
      pack_description: '',
      minimum_income_multiple: 0,
      minimum_credit_score: 0,
      maximum_number_of_occupants: 0,
      has_declared_bankruptcy: false,
      has_declared_eviction: false,
      can_provide_previous_landlord_reference: false,
      has_been_convicted: false,
      is_smoking_allowed: false,
      is_pet_allowed: false,
    },
  ]);

  const [errors, setErrors] = useState({});

  //schema for validation
  //TODO: set the real properties for the advertisement next
  const advertSchema = {
    advert_id: Joi.string().optional().allow('').label('Advertisement ID'),
    landlord_id: Joi.string().optional().label('Landlord ID'),
    property_unit_id: Joi.string().required().label('Property Unit ID'),
    date_advert_ends: Joi.date().required().label('Date Advert Ends'),
    campaign_id: Joi.string().optional().allow('').label('Campaign ID'),
    property_unit: Joi.object().optional().label('Property Unit'),
    unit_advert_image_uploads: Joi.array()
      .optional()
      .label('Unit Advert Image URLs'),
    security_deposit: Joi.number().required().label('Security Deposit'),
    screening_pack_id: Joi.string().optional().label('Screening Pack'),
    currency_id: Joi.string().optional().allow('').label('Currency ID'),
    currency_name: Joi.string().optional().allow('').label('Currency Name'),
    is_advert_expired: Joi.boolean().optional().label('Is Advert Expired'),
    rental_unit_price: Joi.number().required().label('Rental Amount'),
    rental_unit_amt_currency: Joi.string().required().label('Rental Currency'),
  };

  //validate the form
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(advertFormData, advertSchema, options);
    console.log('Error:');
    console.log(error);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
    }
    return errors;
  };

  //handle the change of the property unit

  const validateProperty = ({ name, value }) => {
    // console.log('Name:', name);
    //console.log('Value:', value);
    const obj = { [name]: value };
    const schemaLocal = { [name]: advertSchema[name] };
    const options = { abortEarly: false };
    console.log('Schema:', schemaLocal);

    const { error } = Joi.validate(obj, schemaLocal, options);

    console.log('Error in  validateProperty:', error);

    return error ? error.details[0].message : null;
  };

  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else {
      // If not, assume it's a date from DatePicker and use the provided datePickerName
      name = 'date_advert_ends'; // Use the passed datePickerName instead of hardcoding 'date'
      value = input;
    }
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...advertFormData };
    if (value !== undefined) {
      data[name] = value;
    }

    setAdvertFormData(data);
    setErrors(currentErrors);
  };

  //handle the submit of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    //passed validation so start loading animation
    setIsLoading(true);
    //TODO
    //TODO: call the api to save the advertisement
    //console.log('Submitted');
    //data to be saved
    //console.log(advertFormData);

    //save advertFormData into the current advertisement

    //get the currency name from the currency options
    let currency_name = selectedCurrencyOption.label;

    const dataToSend = {
      advert_id: uuidv4(),
      landlord_id: user_id,
      property_unit_id: propertyUnitId,
      date_advert_ends: advertFormData.date_advert_ends,
      campaign_id: uuidv4(), //need a campaign id generator
      property_unit: propertyUnit,
      unit_advert_image_uploads: imagesToUpload,
      security_deposit: advertFormData.security_deposit,
      screening_pack_id: advertFormData.screening_pack_id,
      currency_id: property.currency_id,
      currency_name: currency_name,
      is_advert_expired: false,
      rental_unit_price: advertFormData.rental_unit_price,
      rental_unit_amt_currency: advertFormData.rental_unit_amt_currency,
    };

    console.log('Data to send:');
    console.log(dataToSend);

    const response = await createPropertyUnitAdvertListing(dataToSend);

    if (response) {
      console.log('Advertisement Created:');
      console.log(response);
      setIsLoading(false);
      toast.success('Advertisement Created Successfully');
      //redirect to the advertisement list
      navigate(`/myAdvertisements/${propertyUnitId}`);
    } else {
      setIsLoading(false);
      toast.error('Failed to create Advertisement');
      return;
    }
  };

  const [currencyOptions, setCurrencyOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  const handleCurrencyChange = (option) => {
    //if (!option) return;
    setSelectedCurrencyOption(option);
    advertFormData.rental_unit_amt_currency = option.value;
  };

  const getCurrencyOptions = async () => {
    //set currencyOptions
    const response = await fetchAllCurrencies();
    //set currencyOptions
    const options = response.map((currency) => ({
      value: currency.currency_id,
      label: currency.currency_name,
      data: currency,
    }));
    setCurrencyOptions(options);

    // Set selectedCurrencyOption to the option where currency_code is "USD"
    const usdOption = options.find(
      (option) => option.data.currency_code === 'USD'
    );
    if (usdOption) {
      setSelectedCurrencyOption(usdOption);
    }

    return options; // Return the currencyOptions
  };

  //need to get infomation about the property unit to prefilled the form
  const getPropertyUnitByPropertyUnitId = async (_currencies) => {
    //get the property unit by the property unit id
    //getPropertyUnitById
    const propertyUnit = await getPropertyUnitById(propertyUnitId);

    if (propertyUnit) {
      setPropertyUnit(propertyUnit);

      console.log('Property Unit:');
      console.log(propertyUnit);

      //set the rent and security deposit to the market rent amount
      setAdvertFormData({
        ...advertFormData,
        rental_unit_price: propertyUnit.market_rent_amount,
        security_deposit: propertyUnit.market_rent_amount,
      });

      getCurrentPropertyProfile(propertyUnit.property_unit_id, _currencies);
      //TODO: when does the advert end control next
    }
  };

  const [selectedScreeningPack, setSelectedScreeningPack] = useState(null);

  const handleScreeningPackChange = (option) => {
    setSelectedScreeningPack(option);

    //alert('Screening Pack Selected: ' + option.value);

    advertFormData.screening_pack_id = option.value;
  };

  const getAllScreeningPacks = async () => {
    const screeningPacks = await getScreeningPacksByUserCreatorId(user_id);
    if (screeningPacks) {
      setScreeningPacks(screeningPacks);

      //set the screening pack options
      const options = screeningPacks.map((pack) => ({
        value: pack.screening_pack_id,
        label: pack.pack_name,
        data: pack,
      }));
      setScreeningPackOptions(options);
    }
  };

  const getCurrentPropertyProfile = async (_property_unit_id, _currencies) => {
    const response = await getPropertyDetailByPropertyUnitId(_property_unit_id);

    if (response.property) {
      console.log('Property:');
      console.log(response.property);
      setProperty(response.property);

      console.log('Currency Options:');
      console.log(_currencies);
      //set the currency
      const currencyOption = _currencies.find(
        (option) => option.value === response.property.currency_id
      );
      if (currencyOption) {
        setSelectedCurrencyOption(currencyOption);

        console.log('Currency Option:');
        console.log(currencyOption);
      }
    }
  };

  //fetch the screening packs so that the user can attach a screening pack to the advertisement
  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrencyOptions().then((currencyOptions) => {
      getPropertyUnitByPropertyUnitId(currencyOptions);
      getAllScreeningPacks();
    });
  }, []);

  //TODO: next the upload of the images for the advertisement control to aws s3, dont add the images to aws until the user selects to submit the form

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle='Property Unit'
            previousPageLink={`/propertyunit/${propertyUnitId}`}
          />

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    New Advertisement
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        {/*Advertisement End Date */}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='date_advert_ends'
                              label='Advert End Date'
                              type={'date'}
                              value={advertFormData.date_advert_ends}
                              onChange={handleChange}
                              error={errors.date_advert_ends}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='rental_unit_price'
                              label='Rental Amount'
                              type={'number'}
                              value={advertFormData.rental_unit_price}
                              onChange={handleChange}
                              error={errors.rental_unit_price}
                            />
                          </div>
                        </div>
                        {/* Rental Currency*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Rental Currency
                            </label>
                            <Select
                              value={selectedCurrencyOption}
                              onChange={handleCurrencyChange}
                              options={currencyOptions}
                            />
                          </div>
                        </div>
                        {/* Security Deposit*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='security_deposit'
                              label='Security Deposit'
                              type={'number'}
                              value={advertFormData.security_deposit}
                              onChange={handleChange}
                              error={errors.security_deposit}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* Screening Pack */}
                            <label htmlFor='form-grid-category'>
                              Screening Pack
                            </label>
                            <Select
                              value={selectedScreeningPack}
                              onChange={handleScreeningPackChange}
                              options={screeningPackOptions}
                            />
                            {/* Nice to have: button to show a popup preview of whats in the pack*/}
                          </div>
                        </div>
                      </div>
                      <div className='row gy-3 pt-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <label htmlFor='form-grid-category'>
                            Unit Images
                          </label>
                          <MultiImageUploader
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            base64Data={base64Data}
                            setBase64Data={setBase64Data}
                            imagesToUpload={imagesToUpload}
                            setImagesToUpload={setImagesToUpload}
                            uploadBtnText='Confirm Selection'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='d-flex justify-content-end'>
                            <button
                              onClick={handleSubmit}
                              className='btn btn-success'
                              type='submit'
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewAdvertisement;
