import React, { useEffect, useState } from 'react';
import Joi from 'joi-browser';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Badge, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { useNavigate, useParams } from 'react-router-dom';

import PageTitleBox from '../../../reusable/title/pageTitleBox';
import Input from '../../../reusable/form/Input';
import { createOrUpdateUnitTicket } from '../../../../services/tickets/unitTicketsManagement';
import { fetchAllPriorities } from '../../../../services/systemDefined/priority/sysPriorityManagement';
import { fetchAllStatuses } from '../../../../services/systemDefined/status/sysStatusManagemetn';
import { getPropertyUnitByPropertyUnitId } from '../../../../services/propertyUnits/propertyUnitManagement';
import config from '../../../../constants/config.json';
import { getPortfolioByOwnershipId } from '../../../../services/portfolio/portfolioManagement';
import {
  fetchPropertyByPortfolioId,
  getPropertyUnitById,
  getPropertyUnitsById,
} from '../../../../services/property/retrieve/propertyRetrievalService';

//version when we know the property_unit_id and type where we dont know and they have to select the portfolio -> property -> unit
const CreateUnitTicket = ({ isDarkMode }) => {
  const { propertyUnitId } = useParams();
  const [isUnitDefined, setIsUnitDefined] = useState(true);
  const [unit, setUnit] = useState({
    market_rent_amount: '',
    number_of_bathrooms: '',
    number_of_bedrooms: '',
    property_id: '',
    property_unit_id: '',
    square_footage: '',
    unit_name: '',
  });
  const [ticketData, setTicketData] = useState({
    ticket_type: '',
    ticket_title: '',
    ticket_description: '',
    ticket_priority_id: '',
    ticket_status_id: '',
    ticket_number: '',
    ticket_status: '',
    //not stored in the database
    portfolio_id: '',
    property_id: '',
  });

  const [errors, setErrors] = useState({});

  const [ticketTypes, setTicketTypes] = useState([
    { value: 'Maintenance', label: 'Maintenance' },
    { value: 'Repair', label: 'Repair' },
    { value: 'Other', label: 'Other' },
  ]);

  //need to come from the API
  const [ticketPriorities, setTicketPriorities] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [ticketStatuses, setTicketStatuses] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [ticketTypesSelected, setTicketTypesSelected] = useState(null);
  const [ticketPrioritiesSelected, setTicketPrioritiesSelected] =
    useState(null);
  const [ticketStatusesSelected, setTicketStatusesSelected] = useState(null);

  const [ticketNumber, setTicketNumber] = useState('');

  const navigate = useNavigate();

  const schema = {
    ticket_id: Joi.string().allow('').optional(),
    property_unit_id: Joi.string().allow('').optional(),
    ticket_type: Joi.string().required().label('Ticket Type'),
    ticket_title: Joi.string().required().label('Ticket Title'),
    ticket_description: Joi.string().required().label('Ticket Description'),
    ticket_priority_id: Joi.string().required().label('Ticket Priority'),
    ticket_status_id: Joi.string().required().label('Ticket Status'),
    ticket_number: Joi.string().required().label('Ticket Number'),
    ticket_status: Joi.string().required().label('Ticket Status'),
    //not stored in the database
    portfolio_id: Joi.string().allow('').optional(),
    property_id: Joi.string().allow('').optional(),
  };

  const validate = () => {
    const options = { abortEarly: false };

    const { error } = Joi.validate(ticketData, schema, options);

    //console.log('error');
    //console.log(error);

    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    const { error } = Joi.validate(obj, schemaLocal, options);

    return error ? error.details[0].message : null;
  };
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    }
    const errorMessage = validateProperty({ name, value });

    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...ticketData };
    if (value !== undefined) {
      data[name] = value;
    }

    setTicketData(data);
    setErrors(currentErrors);
  };

  const handleTicketTypeChange = (ticketTypesSelected) => {
    setTicketTypesSelected(ticketTypesSelected);
    const data = { ...ticketData };
    data['ticket_type'] = ticketTypesSelected.value;
    setTicketData(data);
  };

  const handleTicketPriorityChange = (ticketPrioritiesSelected) => {
    setTicketPrioritiesSelected(ticketPrioritiesSelected);
    const data = { ...ticketData };
    data['ticket_priority_id'] = ticketPrioritiesSelected.value;

    setTicketData(data);
  };

  const handleTicketStatusChange = (ticketStatusesSelected) => {
    setTicketStatusesSelected(ticketStatusesSelected);
    const data = { ...ticketData };

    data['ticket_status_id'] = ticketStatusesSelected.value;
    data['ticket_status'] = ticketStatusesSelected.label;
    setTicketData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();

    setErrors(errors || {});

    if (errors) return;

    try {
      //remove the portfolio_id and property_id from the ticketData
      //not needed in the database
      const { portfolio_id, property_id, ...rest } = ticketData;

      //add ticket_id etc
      const ticketId = uuidv4();
      ticketData.ticket_id = ticketId;
      ticketData.property_unit_id = propertyUnitId;

      console.log('ticketData');
      console.log(ticketData);

      const result = await createOrUpdateUnitTicket(ticketData);

      if (result) {
        toast.success('Unit Ticket Created Successfully');
        navigate(`/ticket/${ticketId}`); //the ticket
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...errors };
        errors.ticket_title = ex.response.data;
        setErrors(errors);
      }
    }
  };

  useEffect(() => {
    //if the propertyUnitId is not passed, then we need to get the propertyUnitId from the user
    if (!propertyUnitId || propertyUnitId === 'undefined') {
      toast.info('Please select the portfolio -> property -> unit');
      setIsUnitDefined(false);
      getAllPriorities();
      getAllStatuses();
      getExistingPortfolios();
      return;
    }

    //if the propertyUnitId is passed, then we can get the unit details
    getAllPriorities();
    getAllStatuses();
    getUnitDetails();
  }, []);

  const getAllPriorities = async () => {
    const result = await fetchAllPriorities();
    console.log(result);
    const priorities = result.map((p) => {
      return { value: p.priority_id, label: p.priority, data: p };
    });

    setTicketPriorities(priorities);
  };

  //get all statuses
  const getAllStatuses = async () => {
    const result = await fetchAllStatuses();
    console.log(result);
    const statuses = result.map((s) => {
      return { value: s.status_id, label: s.status, data: s };
    });

    setTicketStatuses(statuses);
  };

  const generateTicketNumber = (unitName) => {
    const uniqueNumber = uuidv4().substring(0, 8);
    return `${unitName}-${uniqueNumber}`;
  };

  //get the unit details
  const getUnitDetails = async () => {
    //get the unit details

    const unit = await getPropertyUnitByPropertyUnitId(propertyUnitId);
    console.log(unit);
    setUnit(unit);

    //set the ticket number
    const data = { ...ticketData };
    //remove spaces from unit name
    const unitName = unit.unit_name.replace(/\s/g, '');
    const ticketNumber = generateTicketNumber(unitName);
    data['ticket_number'] = ticketNumber;
    setTicketData(data);
    setTicketNumber(ticketNumber);

    console.log('Updated ticketData:', {
      ...ticketData,
      ticket_number: ticketNumber,
    });
  };

  //dropdown list for the portfolio
  const [existingPortfolioOptions, setExistingPortfolioOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  //selected portfolio options
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);

  const handlePortfolioChange = (option) => {
    if (!option) return;
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log('Portfolio changed:', option);
    // You can update the state or perform other side effects here
    ticketData.portfolio_id = option.value;

    //set the selected portfolio
    setSelectedPortfolioOption(option);

    //re-enable after i tested the portfolio
    //when the unit is found then create the unit ticket number
    //get all the properties for the portfolio
    getPropertiesByPortfolioId(option.value);
  };

  const getExistingPortfolios = async () => {
    //set existingPortfolio

    const userId = localStorage.getItem(config.user_id);
    const response = await getPortfolioByOwnershipId(userId);

    if (!response) {
      console.log('No portfolios found for this user');
      return;
    }

    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((portfolio) => ({
      value: portfolio.portfolio_id,
      label: portfolio.portfolio_name,
      data: portfolio,
    }));
    setExistingPortfolioOptions(options);
  };

  //get properties section
  const [existingPropertiesOptions, setExistingPropertiesOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  //selected portfolio options
  const [selectedPropertyOption, setSelectedPropertyOption] = useState(null);

  const getPropertiesByPortfolioId = async (portfolioId) => {
    //get all the properties for the portfolio
    const response = await fetchPropertyByPortfolioId(portfolioId);

    if (!response) {
      console.log('No properties found for this portfolio');
      return;
    }

    //clear the selected property
    setSelectedPropertyOption(null);
    setSelectedUnitOption(null);
    ticketData.property_id = '';
    ticketData.property_unit_id = '';

    console.log('Properties:', response);
    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((property) => ({
      value: property.property_id,
      label: property.property_alias_name,
      data: property,
    }));
    setExistingPropertiesOptions(options);
  };

  const handlePropertyChange = (option) => {
    if (!option) {
      console.log('Cleared pressed');
      setSelectedPropertyOption(null);
      setSelectedUnitOption(null);
      ticketData.property_id = '';
      ticketData.property_unit_id = '';
      return;
    }
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log('Property changed:', option);
    // You can update the state or perform other side effects here
    ticketData.property_id = option.value;

    //set the selected portfolio
    setSelectedPropertyOption(option);

    //get the units for the property
    getUnitsByPropertyId(option.value);
  };

  //units section
  const [existingUnitsOptions, setExistingUnitsOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [selectedUnitOption, setSelectedUnitOption] = useState(null);

  const getUnitsByPropertyId = async (_propertyId) => {
    //get all the units for the property
    const response = await getPropertyUnitsById(_propertyId);

    if (!response) {
      console.log('No units found for this property');
      return;
    }

    //clear the selected property unit option
    setSelectedUnitOption(null);
    ticketData.property_unit_id = '';

    console.log('Units:', response);
    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((unit) => ({
      value: unit.property_unit_id,
      label: unit.unit_name,
      data: unit,
    }));
    setExistingUnitsOptions(options);
  };

  const handleUnitChange = (option) => {
    if (!option) {
      console.log('Cleared pressed');
      setSelectedUnitOption(null);
      ticketData.property_unit_id = '';
      return;
    }
    // This function will be called whenever the unit changes
    // 'option' is the selected option from the CreatableSelect
    console.log('Unit changed:', option);
    // You can update the state or perform other side effects here
    ticketData.property_unit_id = option.value;

    //set the selected portfolio
    setSelectedUnitOption(option);

    //update the url to the property unit id without navigating
    navigate(`/newUnitTicket/${option.value}`);

    //set the ticket number
    //const data = { ...ticketData };
    //data['ticket_number'] = generateTicketNumber(option.label);
    const unitName = option.label;
    // console.log('unitName:', unitName);

    // Remove spaces
    const unitNameNoSpaces = unitName.replace(/\s/g, '');
    //console.log('unitNameNoSpaces:', unitNameNoSpaces);

    // Generate ticket number
    const ticketNumber = generateTicketNumber(unitNameNoSpaces);
    //console.log('ticketNumber:', ticketNumber);

    // Set ticket data
    setTicketData((prevData) => ({
      ...prevData,
      ticket_number: ticketNumber,
    }));

    setTicketNumber(ticketNumber);

    console.log('Updated ticketData:', {
      ...ticketData,
      ticket_number: ticketNumber,
    });
  };

  //then get the unit details

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox pageTitle='Create Unit Ticket' />
          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>New Ticket</h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        {/* if isUnitDefined is false then give the option to select the portfolio -> property -> unit*/}
                        {!isUnitDefined && (
                          <>
                            <div className='col-xxl-3 col-md-6'>
                              <label htmlFor='portfolio_id'>Portfolio</label>
                              <Select
                                name='portfolio_id'
                                options={existingPortfolioOptions}
                                value={selectedPortfolioOption}
                                onChange={handlePortfolioChange}
                              />
                            </div>
                            <div className='col-xxl-3 col-md-6'>
                              <label htmlFor='property_id'>Property</label>
                              <Select
                                name='property_id'
                                options={existingPropertiesOptions}
                                value={selectedPropertyOption}
                                onChange={handlePropertyChange}
                              />
                            </div>

                            <div className='col-xxl-3 col-md-6'>
                              <label htmlFor='property_unit_id'>Unit</label>
                              <Select
                                name='property_unit_id'
                                options={existingUnitsOptions}
                                value={selectedUnitOption}
                                onChange={handleUnitChange}
                              />
                            </div>
                          </>
                        )}
                        <div className='col-xxl-3 col-md-6'>
                          <Input
                            name='ticket_number'
                            label='Ticket Number'
                            value={ticketNumber}
                            onChange={handleChange}
                            error={errors.ticket_number}
                            isDisabled={true}
                          />
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <Input
                            name='ticket_title'
                            label='Ticket Title'
                            value={ticketData.ticket_title}
                            onChange={handleChange}
                            error={errors.ticket_title}
                          />
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <Input
                            name='ticket_description'
                            label='Ticket Description'
                            value={ticketData.ticket_description}
                            onChange={handleChange}
                            error={errors.ticket_description}
                          />
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <label htmlFor='ticket_type'>Ticket Type</label>
                          <Select
                            name='ticket_type'
                            options={ticketTypes}
                            value={ticketTypesSelected}
                            onChange={handleTicketTypeChange}
                          />
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <label htmlFor='ticket_priority_id'>
                            Ticket Priority
                          </label>
                          <Select
                            name='ticket_priority_id'
                            options={ticketPriorities}
                            value={ticketPrioritiesSelected}
                            onChange={handleTicketPriorityChange}
                          />
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <label htmlFor='ticket_status_id'>
                            Ticket Status
                          </label>
                          <Select
                            name='ticket_status_id'
                            options={ticketStatuses}
                            value={ticketStatusesSelected}
                            onChange={handleTicketStatusChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row gy-4 mt-3'>
                      <div className='col-xxl-3 col-md-6'>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          disabled={validate()}
                        >
                          Create Unit Ticket
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateUnitTicket;
