//similar style as tenantPendingRequest.jsx and a button to add new screening pack
import React, { useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import NoRecordsFound from '../../../tickets/list/sub/noRecordsFound';
import { getScreeningPacksByUserCreatorId } from '../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement';
import config from '../../../../../constants/config.json';

const ScreeningPackList = ({ isDarkMode }) => {
  const navigate = useNavigate();

  const user_id = localStorage.getItem(config.user_id);

  /*
    screening_pack_id: str
    user_creator_id: str
    ownership_group_id: str
    pack_name: str
    pack_description: str
    minimum_income_multiple: float
    minimum_credit_score: Optional[int]
    maximum_number_of_occupants: Optional[int]
    has_declared_bankruptcy: Optional[bool]
    has_declared_eviction: Optional[bool]
    can_provide_previous_landlord_reference: Optional[bool]
    has_been_convicted: Optional[bool]
    is_smoking_allowed: Optional[bool]
    is_pet_allowed: Optional[bool]
*/
  const [screeningPacks, setScreeningPacks] = useState([
    {
      screening_pack_id: '',
      user_creator_id: '',
      ownership_group_id: '',
      pack_name: '',
      pack_description: '',
      minimum_income_multiple: 0,
      minimum_credit_score: 0,
      maximum_number_of_occupants: 0,
      has_declared_bankruptcy: false,
      has_declared_eviction: false,
      can_provide_previous_landlord_reference: false,
      has_been_convicted: false,
      is_smoking_allowed: false,
      is_pet_allowed: false,
    },
  ]);

  const screenPackColumns = [
    {
      field: 'pack_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        //todo: add link to screening pack detail page
        /*
        <Link to={`/screenPack/${params.row.screening_pack_id}`}>
          {params.value}
        </Link>
        */
        <div>{params.value}</div>
      ),
    },
    { field: 'pack_description', headerName: 'Description', flex: 2 },
    {
      field: 'minimum_income_multiple',
      headerName: 'Income Multiple',
      flex: 1,
    },
    {
      field: 'minimum_credit_score',
      headerName: 'Credit Score',
      flex: 1,
    },
    {
      field: 'maximum_number_of_occupants',
      headerName: 'Max Occupants',
      flex: 1,
    },
    {
      field: 'has_declared_bankruptcy',
      headerName: 'Bankruptcy',
      flex: 1,
    },
    {
      field: 'has_declared_eviction',
      headerName: 'Eviction',
      flex: 1,
    },
    {
      field: 'can_provide_previous_landlord_reference',
      headerName: 'Landlord Reference',
      flex: 1,
    },
    {
      field: 'has_been_convicted',
      headerName: 'Convicted',
      flex: 1,
    },
    {
      field: 'is_smoking_allowed',
      headerName: 'Smoking',
      flex: 1,
    },
    {
      field: 'is_pet_allowed',
      headerName: 'Pets',
      flex: 1,
    },
  ];

  const getAllScreeningPacks = async () => {
    const screeningPacks = await getScreeningPacksByUserCreatorId(user_id);
    if (screeningPacks) {
      setScreeningPacks(screeningPacks);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllScreeningPacks();
  }, []);

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='card' id='ticketsList'>
          <div className='card-header border-0'>
            <div className='d-flex align-items-center'>
              <h5 className='card-title mb-0 flex-grow-1'>Screening Packs</h5>
            </div>
            {/* New Screening Pack Button */}
            <div className='d-flex align-items-center justify-content-end'>
              <Link
                to='/createScreeningPack'
                className='btn btn-primary btn-sm'
              >
                <i className='ri-add-line align-middle me-1'></i> New Screening
                Pack
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <Box sx={{ height: 650, width: '100%' }}>
                <DataGrid
                  rows={screeningPacks || []}
                  getRowId={(row) => row.screening_pack_id || 0}
                  columns={screenPackColumns}
                  pageSize={5}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  onRowClick={(row) => {
                    //Define this next
                    //todo
                    //navigate(`/screenPack/${row.row.screening_pack_id}`);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                    noResultsOverlay: NoRecordsFound,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreeningPackList;
