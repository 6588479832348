import React, { useState } from "react";
import Joi from "joi-browser";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Input from "../../../../../reusable/form/Input";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Modal, Badge, Button } from "react-bootstrap";
import { createPropertyUnits } from "../../../../../../services/propertyUnits/propertyUnitManagement";

const PropertyUnitStep = ({
  isDarkMode,
  property_id,
  propertyUnitsData,
  setPropertyUnitsData,
  isPropertyAlreadyCreated,
  selectedCurrencyOption,
  handleSubmit,
}) => {
  //handle the change of the property unit and creating the list of property units
  const [currentPropertyUnitData, setCurrentPropertyUnitData] = useState({
    property_unit_id: "",
    property_id: property_id,
    unit_name: "",
    number_of_bedrooms: 0,
    number_of_bathrooms: 0,
    square_footage: 0,
    market_rent_amount: 0,
    is_rented: false,
    is_active: true,
  });

  //property unit data to edit
  const [propertyUnitDataToEdit, setPropertyUnitDataToEdit] = useState({
    property_unit_id: "",
    property_id: property_id,
    unit_name: "",
    number_of_bedrooms: 0,
    number_of_bathrooms: 0,
    square_footage: 0,
    market_rent_amount: 0,
    is_rented: false,
    is_active: true,
  });
  //schema
  const unitSchema = {
    property_unit_id: Joi.string().allow(""),
    property_id: Joi.string().required().label("Property"),
    unit_name: Joi.string().required().label("Unit Name"),
    number_of_bedrooms: Joi.number()
      .min(0)
      .required()
      .label("Number of Bedrooms"),
    number_of_bathrooms: Joi.number()
      .min(0)
      .required()
      .label("Number of Bathrooms"),
    square_footage: Joi.number().min(0).required().label("Square Footage"),
    market_rent_amount: Joi.number()
      .min(0)
      .required()
      .label("Market Rent Amount"),
    is_rented: Joi.boolean().required(),
    is_active: Joi.boolean().required(),
  };

  //errors
  const [errors, setErrors] = useState({});

  //validate the form
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(
      currentPropertyUnitData,
      unitSchema,
      options
    );
    console.log("Error:");
    console.log(error);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
    }
    return errors;
  };

  //handle the change of the property unit
  const handleChange = ({ currentTarget: input }) => {
    const data = { ...currentPropertyUnitData };
    if (
      [
        "number_of_bedrooms",
        "number_of_bathrooms",
        "square_footage",
        "market_rent_amount",
      ].includes(input.name)
    ) {
      data[input.name] = parseFloat(input.value) || 0;
    } else if (["is_rented", "is_active"].includes(input.name)) {
      data[input.name] = input.checked;
    } else {
      data[input.name] = input.value;
    }
    setCurrentPropertyUnitData(data);
  };

  //handle the submit of the property unit
  const handleUnitSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    handleSubmit();
  };

  //submit the list of property units to the server
  const handleCreatePropertyUnits = async () => {
    //submit the list of property units to the server
    console.log("Property Units Data to submit to the server:");
    console.log(propertyUnitsData);

    if (propertyUnitsData && propertyUnitsData.length > 0) {
      const updatedUnits = await createPropertyUnits(propertyUnitsData);
      console.log("Updated Units:");
      console.log(updatedUnits);
      //if successful, then show a success message
      if (updatedUnits) {
        toast.success("Property Units created successfully");
        handleSubmit(); // Call handleSubmit to move to the next step
      } else {
        toast.error("Failed to create property units");
      }
    } else {
      toast.warning("Please add property units to the list");
    }
  };

  //add the property unit to the list of property units
  const handleAddPropertyUnit = () => {
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    const newUnit = {
      ...currentPropertyUnitData,
      property_unit_id: uuidv4(),
    };

    setPropertyUnitsData((prevState) => [...prevState, newUnit]);

    // Reset currentPropertyUnitData
    setCurrentPropertyUnitData({
      property_unit_id: "",
      property_id: property_id,
      unit_name: "",
      number_of_bedrooms: 0,
      number_of_bathrooms: 0,
      square_footage: 0,
      market_rent_amount: 0,
      is_rented: false,
      is_active: true,
    });
  };

  //remove the property unit from the list of property units
  const handleRemovePropertyUnit = (property_unit_id) => {
    //remove the property unit from the list of property units
    setPropertyUnitsData((prevState) =>
      prevState.filter((unit) => unit.property_unit_id !== property_unit_id)
    );
  };
  //copy the property unit from the list of property units
  const handleCopyPropertyUnit = (property_unit_id) => {
    //create a temp object so i can copy the property unit and edit it
    const tempPropertyUnit = propertyUnitsData.filter(
      (unit) => unit.property_unit_id === property_unit_id
    );
    console.log("Temp Property Unit:");
    console.log(tempPropertyUnit);

    //copy the property unit from the list of property units
    setCurrentPropertyUnitData({
      property_unit_id: uuidv4(),
      property_id: property_id,
      unit_name: "",
      number_of_bedrooms: tempPropertyUnit[0].number_of_bedrooms,
      number_of_bathrooms: tempPropertyUnit[0].number_of_bathrooms,
      square_footage: tempPropertyUnit[0].square_footage,
      market_rent_amount: tempPropertyUnit[0].market_rent_amount,
      is_rented: false,
      is_active: true,
    });
  };

  //may put in own file later
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [errorsForEdit, setErrorsForEdit] = useState({});

  //edit the property unit from the list of property units
  const handleEditPropertyUnit = (property_unit_id) => {
    //create a temp object so i can copy the property unit and edit it
    const tempPropertyUnit = propertyUnitsData.find(
      (unit) => unit.property_unit_id === property_unit_id
    );

    console.log("Temp Property Unit:");
    console.log(tempPropertyUnit);

    if (tempPropertyUnit) {
      //copy the property unit from the list of property units
      setPropertyUnitDataToEdit({
        property_unit_id: tempPropertyUnit.property_unit_id,
        property_id: tempPropertyUnit.property_id,
        unit_name: tempPropertyUnit.unit_name,
        number_of_bedrooms: tempPropertyUnit.number_of_bedrooms,
        number_of_bathrooms: tempPropertyUnit.number_of_bathrooms,
        square_footage: tempPropertyUnit.square_footage,
        market_rent_amount: tempPropertyUnit.market_rent_amount,
        is_rented: tempPropertyUnit.is_rented,
        is_active: tempPropertyUnit.is_active,
      });
    } else {
      console.log(`No property unit found with id ${property_unit_id}`);
    }
    setShowModal(true);
  };

  //handleChangeForEdit
  const handleChangeForEdit = ({ currentTarget: input }) => {
    const data = { ...propertyUnitDataToEdit };
    if (
      [
        "number_of_bedrooms",
        "number_of_bathrooms",
        "square_footage",
        "market_rent_amount",
      ].includes(input.name)
    ) {
      data[input.name] = input.value === "" ? null : parseFloat(input.value);
    } else {
      data[input.name] = input.value;
    }
    setPropertyUnitDataToEdit(data);
  };

  //error handling for edit
  const validateForEdit = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(propertyUnitDataToEdit, unitSchema, options);
    if (!error) return null;

    const errorsForEdit = {};
    for (let item of error.details) {
      errorsForEdit[item.path[0]] = item.message;
      toast.error(item.message);
    }
    return errorsForEdit;
  };

  //handle save changes for edit
  const handleEditSaveChanges = () => {
    const errorsForEdit = validateForEdit();
    setErrorsForEdit(errorsForEdit || {});
    if (errorsForEdit) return;

    //save the changes to the property unit
    console.log("Property Unit Data to save changes:");
    console.log(propertyUnitDataToEdit);

    //find the property unit in the list of property units and update it
    const updatedPropertyUnitsData = propertyUnitsData.map((unit) =>
      unit.property_unit_id === propertyUnitDataToEdit.property_unit_id
        ? {
            ...unit,
            unit_name: propertyUnitDataToEdit.unit_name,
            number_of_bedrooms: propertyUnitDataToEdit.number_of_bedrooms,
            number_of_bathrooms: propertyUnitDataToEdit.number_of_bathrooms,
            square_footage: propertyUnitDataToEdit.square_footage,
            market_rent_amount: propertyUnitDataToEdit.market_rent_amount,
            is_rented: propertyUnitDataToEdit.is_rented,
            is_active: propertyUnitDataToEdit.is_active,
          }
        : unit
    );

    setPropertyUnitsData(updatedPropertyUnitsData);
    setShowModal(false);
  };

  //Next Steps:
  //put a hint that if they press a unit it will set the current property unit data to that unit (unit name, number of bedrooms, number of bathrooms, square footage, market rent amount)
  //next do the cards
  //should be able to remove or edit a unit using the modal like expenses in SavingPalz
  //is property created flag so the user knows they need to add a property in order to add a unit or create unit or the other steps
  return (
    <React.Fragment>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Property Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row gy-4">
            <div className="col-xxl-6 col-md-6">
              <div>
                <Input
                  name="unit_name"
                  label="Unit Name"
                  type={"text"}
                  value={propertyUnitDataToEdit.unit_name}
                  onChange={handleChangeForEdit}
                  error={errorsForEdit.unit_name}
                />
              </div>
            </div>

            <div className="col-xxl-6 col-md-6">
              <div>
                <Input
                  name="number_of_bedrooms"
                  label="Number of Bedrooms"
                  type={"number"}
                  value={propertyUnitDataToEdit.number_of_bedrooms}
                  onChange={handleChangeForEdit}
                  error={errorsForEdit.number_of_bedrooms}
                />
              </div>
            </div>

            <div className="col-xxl-6 col-md-6">
              <div>
                <Input
                  name="number_of_bathrooms"
                  label="Number of Bathrooms"
                  type={"number"}
                  value={propertyUnitDataToEdit.number_of_bathrooms}
                  onChange={handleChangeForEdit}
                  error={errorsForEdit.number_of_bathrooms}
                />
              </div>
            </div>

            <div className="col-xxl-6 col-md-6">
              <div>
                <Input
                  name="square_footage"
                  label="Square Footage"
                  type={"number"}
                  value={propertyUnitDataToEdit.square_footage}
                  onChange={handleChangeForEdit}
                  error={errorsForEdit.square_footage}
                />
              </div>
            </div>

            <div className="col-xxl-6 col-md-6">
              <div>
                <Input
                  name="market_rent_amount"
                  label="Market Rent Amount"
                  type={"number"}
                  value={propertyUnitDataToEdit.market_rent_amount}
                  onChange={handleChangeForEdit}
                  error={errorsForEdit.market_rent_amount}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleEditSaveChanges();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <form>
        <div className="card-body">
          <div className="live-preview">
            <div className="row gy-4">
              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    name="unit_name"
                    label="Unit Name"
                    type={"text"}
                    value={currentPropertyUnitData.unit_name}
                    onChange={handleChange}
                    error={errors.unit_name}
                  />
                </div>
              </div>

              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    name="number_of_bedrooms"
                    label="Number of Bedrooms"
                    type="number"
                    value={currentPropertyUnitData.number_of_bedrooms}
                    onChange={handleChange}
                    error={errors.number_of_bedrooms}
                    min="0"
                  />
                </div>
              </div>

              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    name="number_of_bathrooms"
                    label="Number of Bathrooms"
                    type={"number"}
                    value={currentPropertyUnitData.number_of_bathrooms}
                    onChange={handleChange}
                    error={errors.number_of_bathrooms}
                  />
                </div>
              </div>

              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    name="square_footage"
                    label="Square Footage"
                    type={"number"}
                    value={currentPropertyUnitData.square_footage}
                    onChange={handleChange}
                    error={errors.square_footage}
                  />
                </div>
              </div>

              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    name="market_rent_amount"
                    label="Market Rent Amount"
                    type={"number"}
                    value={currentPropertyUnitData.market_rent_amount}
                    onChange={handleChange}
                    error={errors.market_rent_amount}
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="is_rented"
                      checked={currentPropertyUnitData.is_rented}
                      onChange={handleChange}
                    />{" "}
                    Is Rented
                  </label>
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="is_active"
                      checked={currentPropertyUnitData.is_active}
                      onChange={handleChange}
                    />{" "}
                    Is Active
                  </label>
                </div>
              </div>
            </div>
            {/* Add to the list*/}
            <div className="row gy-4 mt-3">
              <div className="col-xxl-3 col-md-6">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddPropertyUnit}
                  //disabled={validate()}
                >
                  Add Unit to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {propertyUnitsData &&
        propertyUnitsData.length > 0 &&
        propertyUnitsData[0].unit_name && (
          <div className="row gy-4">
            <div className="col-12">
              <div className="card-body">
                <div className="live-preview">
                  {/* List of property units*/}
                  {/* Create the list of units button*/}

                  <React.Fragment>
                    <div className="row gy-4 mt-3">
                      <div className="col-12">
                        <h4>Property Units</h4>
                        {/* Need to add cards and can remove from list
                        
                           <div
                              key={unit.property_unit_id}
                              className='col-sm-4'
                            >
                              {unit.unit_name}
                            </div>
                        */}
                        <div className="row">
                          {propertyUnitsData.map((unit, index) => (
                            <React.Fragment>
                              <div key={index} className="col-sm-2">
                                <div className="card card-body border">
                                  <div className="d-flex mb-4 align-items-center">
                                    <div className="flex-grow-1 ">
                                      <h3 className="card-title mb-0 fs-2 fw-medium">
                                        {unit.unit_name}
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="d-flex mb-2 align-items-center">
                                    <div className="col-2 text-start">
                                      <h5 className="mb-1">
                                        <i className="bx bx-bed"></i>{" "}
                                        {unit.number_of_bedrooms}
                                      </h5>
                                    </div>
                                    <div className="col-2 text-start">
                                      <h5 className="mb-1">
                                        <i className="bx bx-bath"></i>{" "}
                                        {unit.number_of_bathrooms}
                                      </h5>
                                    </div>
                                    <div className="col-3 text-start">
                                      <h5 className="mb-1">
                                        <i className="bx bx-ruler"></i>{" "}
                                        {unit.square_footage}
                                      </h5>
                                    </div>

                                    <div className="col-5 text-start">
                                      <h5 className="mb-1">
                                        <i className="bx bx-money"></i>{" "}
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency:
                                            selectedCurrencyOption?.data
                                              ?.currency_code || "USD",
                                        }).format(unit.market_rent_amount)}{" "}
                                        {selectedCurrencyOption?.data
                                          ?.currency_code || "USD"}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col text-start">
                                      <a
                                        onClick={() => {
                                          handleRemovePropertyUnit(
                                            unit.property_unit_id
                                          );
                                        }}
                                        className="btn btn-danger btn-sm"
                                      >
                                        Delete Unit
                                      </a>
                                    </div>
                                    <div className="col text-center">
                                      <a
                                        onClick={() => {
                                          handleEditPropertyUnit(
                                            unit.property_unit_id
                                          );
                                        }}
                                        className="btn btn-warning btn-sm"
                                      >
                                        Edit Unit
                                      </a>
                                    </div>
                                    <div className="col text-end">
                                      <a
                                        onClick={() => {
                                          handleCopyPropertyUnit(
                                            unit.property_unit_id
                                          );
                                        }}
                                        className="btn btn-success btn-sm"
                                      >
                                        Copy Unit
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-xxl-3 col-md-6 ms-auto text-end">
                {!isPropertyAlreadyCreated && (
                  <p>
                    Please create the property first before adding property
                    units
                  </p>
                )}
                <button
                  type="button"
                  disabled={
                    (propertyUnitsData && propertyUnitsData.length < 1) ||
                    !isPropertyAlreadyCreated
                  }
                  className="btn btn-primary"
                  onClick={handleCreatePropertyUnits}
                >
                  Save and Continue
                </button>
              </div>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default PropertyUnitStep;
