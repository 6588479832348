import { sub } from 'date-fns';
import React, { createContext, useState, useContext } from 'react';

const SubscriptionUsageDetailContext = createContext();

export function SubscriptionUsageDetailProvider({ children }) {
  const [subscriptionUsageDetail, setSubscriptionUsageDetail] = useState({
    subscription_id: '',
    user_id: '',
    amt_of_units_used: 0,
    max_allowed_units: 0,
    number_of_free_trial_days_left: 0,
    subscription_end_date: new Date(),
    subscription_start_date: new Date(),
    free_trial_start_date: new Date(),
    free_trial_end_date: new Date(),
    subscription_status: '',
    subscription_status_reason: '',
    subscription_cancelled_by: '',
    subscription_cancelled_reason: '',
    subscription_cancelled_comment: '',
    first_subscription_date: new Date(),
    last_churned: false,
    last_payment_date: new Date(),
    cost_per_additional_unit: 0,
    bulk_discount_threshold: 0,
    bulk_discount_rate: 0,
    subscription_name: '',
    product_id: '',
  });

  return (
    <SubscriptionUsageDetailContext.Provider
      value={{ subscriptionUsageDetail, setSubscriptionUsageDetail }}
    >
      {children}
    </SubscriptionUsageDetailContext.Provider>
  );
}

export function useSubscriptionUsageDetail() {
  return useContext(SubscriptionUsageDetailContext);
}
