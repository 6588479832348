import React, { useEffect, useState } from 'react';
import Joi from 'joi-browser';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import PageTitleBox from '../../../../reusable/title/pageTitleBox';
import Input from '../../../../reusable/form/Input';
import {
  getSubContractorById,
  updateSubContractor,
} from '../../../../../services/contractor/contractorManagement';

import config from '../../../../../constants/config.json';

const UpdateSubContractor = ({ isDarkMode }) => {
  //wont be a param so i need to show the portfolio and property to assign the contractor, the property is optional which means they cover the entire portfolio
  const pageTitle = 'Update Sub-Contractor';

  const { subContractorId } = useParams();
  const navigate = useNavigate();

  const [subContractor, setSubContractor] = useState({
    sub_contractor_id: subContractorId,
    contractor_id: '',
    first_name: '',
    last_name: '',
    email_address: '',
    phone_number: '',
    company_name: '',
    company_license: '',
    company_insurance: '',
    is_active: true,
    created_at: new Date(),
    updated_at: new Date(),
  });

  const schema = {
    sub_contractor_id: Joi.string().required().label('Sub Contractor Id'),
    contractor_id: Joi.string().required().label('Contractor Id'),
    first_name: Joi.string().required().label('First Name'),
    last_name: Joi.string().required().label('Last Name'),
    email_address: Joi.string().required().label('Email Address'),
    phone_number: Joi.string().required().label('Phone Number'),
    company_name: Joi.string().required().label('Company Name'),
    company_license: Joi.string().allow('').label('Company License'),
    company_insurance: Joi.string().allow('').label('Company Insurance'),
    is_active: Joi.boolean().label('Is Active'),
    created_at: Joi.date().optional().label('Created At'),
    updated_at: Joi.date().optional().label('Updated At'),
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };

    const { error } = Joi.validate(subContractor, schema, options);

    //console.log('Error:', error);

    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    // console.log('Name:', name);
    //console.log('Value:', value);
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    console.log('Schema:', schemaLocal);

    const { error } = Joi.validate(obj, schemaLocal, options);

    console.log('Error in  validateProperty:', error);

    return error ? error.details[0].message : null;
  };
  //handle the form input changes
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else {
      // If not, assume it's a date from DatePicker and use the provided datePickerName
      name = 'company_first_licensure_date'; // Use the passed datePickerName instead of hardcoding 'date'
      value = input;
    }
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...subContractor };
    if (value !== undefined) {
      data[name] = value;
    }

    setSubContractor(data);
    setErrors(currentErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      console.log('subContractor:', subContractor);

      const response = await updateSubContractor(subContractor);
      console.log(response);
      if (response.is_error) {
        toast.error(response.server_message);
        return;
      }

      toast.success('Sub-Contractor has been successfully created!');
      navigate(`/contractor/${subContractor.contractor_id}`);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log('Error:', ex.response.data); //need to log this
        const currentErrors = { ...errors };
        currentErrors.email_address = ex.response.data;
        setErrors(currentErrors);
      }
    }
  };

  useEffect(() => {
    //fetch the sub-contractor by id
    fetchSubContractorById();
  }, []);

  //get the sub-contractor by id
  const fetchSubContractorById = async () => {
    //get the sub-contractor by id

    const response = await getSubContractorById(subContractorId);
    console.log('Sub-Contractor:', response);
    if (response.is_error) {
      toast.error(response.server_message);
      return;
    }

    //set the sub-contractor
    setSubContractor(response);
  };

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle='My Properties'
            previousPageLink={`/myproperties`}
          />

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Update Sub-Contractor
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='first_name'
                              label='First Name'
                              type={'text'}
                              value={subContractor.first_name}
                              onChange={handleChange}
                              error={errors.first_name}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='last_name'
                              label='Last Name'
                              type={'text'}
                              value={subContractor.last_name}
                              onChange={handleChange}
                              error={errors.last_name}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='email_address'
                              label='Email Address'
                              type={'text'}
                              value={subContractor.email_address}
                              onChange={handleChange}
                              error={errors.email_address}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='phone_number'
                              label='Phone Number'
                              type={'text'}
                              value={subContractor.phone_number}
                              onChange={handleChange}
                              error={errors.phone_number}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='company_name'
                              label='Company Name'
                              type={'text'}
                              value={subContractor.company_name}
                              onChange={handleChange}
                              error={errors.company_name}
                            />
                          </div>
                        </div>

                        {/* company license*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='company_license'
                              label='Company License (Optional)'
                              type={'text'}
                              value={subContractor.company_license}
                              onChange={handleChange}
                              error={errors.company_license}
                            />
                          </div>
                        </div>

                        {/* company insurance*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='company_insurance'
                              label='Company Insurance (Optional)'
                              type={'text'}
                              value={subContractor.company_insurance}
                              onChange={handleChange}
                              error={errors.company_insurance}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Add to the list*/}
                      <div className='row gy-4 mt-3'>
                        <div className='col-xxl-3 col-md-6'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={validate()}
                          >
                            Update Sub-Contractor
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateSubContractor;
