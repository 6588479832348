import http from "../../../../services/general/httpService";
import config from "../../../../constants/config.json";

//after the tenant application process management is the rent and other fee set up and payment

// /tenant_rental_application/get_all_tenants_for_campaign_id
export async function getAllTenantsForCampaignId(campaignId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_all_tenants_for_campaign_id/${campaignId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//get_all_tenants_for_property_unit_id
export async function getAllTenantsForPropertyUnitId(propertyUnitId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_all_tenants_for_property_unit_id/${propertyUnitId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//get_tenant_for_tenant_id
export async function getTenantForTenantId(tenantId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_tenant_for_tenant_id/${tenantId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//update_tenant_application_status
export async function updateTenantApplicationStatus(updateData) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/update_tenant_application_status`;
  const { data: result } = await http.put(fullApiEndpoint, updateData);
  return result;
}
