import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import LoginPage from "./components/portal/authentication/login";
import Dashboard from "./components/portal/dashboard/dashboard";
import ProtectedRoute from "./components/portal/authentication/route/protectedroute";
import {
  getCurrentUser,
  getCurrentUserProfileByEmail,
} from "./services/authentication/auth";
import { ToastContainer } from "react-toastify";
import LogOut from "./components/portal/authentication/logout";
import NavigationBar from "./components/portal/navigation/navigationbar";
import MyProperties from "./components/portal/properties/myproperties";
import config from "./constants/config.json";
import PropertyDetail from "./components/portal/properties/propertydetail";
import PropertyUnitDetail from "./components/portal/properties/units/propertunitdetail";
import CreateNewProperty from "./components/portal/properties/form/createnewproperty";
import Signup from "./components/portal/authentication/signup";
import CreatePropertyUnit from "./components/portal/properties/units/create/createPropertyUnit";
import UpdatePropertyUnit from "./components/portal/properties/units/edit/updatePropertyUnit";
import UpdateProperty from "./components/portal/properties/edit/updateProperty";
import UnitPropertyTransactions from "./components/portal/properties/units/transactions/unitPropertyTransactions";
import CreateNewUnitTransaction from "./components/portal/properties/units/transactions/create/createNewUnitTransaction";
import CreateUnitTicket from "./components/portal/tickets/create/createUnitTicket";
import AllUnitTickets from "./components/portal/tickets/list/allUnitTickets";
import TicketDetail from "./components/portal/tickets/detail/ticketDetail";
import EditUnitTicket from "./components/portal/tickets/update/editUnitTicket";
import CreateNewContractor from "./components/portal/contractor/create/createNewContractor";
import ContractorProfileDetail from "./components/portal/contractor/detail/contractorProfileDetail";
import UpdateContractorProfile from "./components/portal/contractor/update/updateContractorProfile";
import CreateNewSubContractor from "./components/portal/contractor/create/subContractor/createNewSubContractor";
import UpdateSubContractor from "./components/portal/contractor/update/subContractor/updateSubContractor";
import AllContractors from "./components/portal/contractor/list/allContractors";
import CreateNewContractTemplate from "./components/portal/rentalContract/create/createNewContractTemplate";
import ContractTemplateDetail from "./components/portal/rentalContract/detail/contractTemplateDetail";
import UpdateContractTemplate from "./components/portal/rentalContract/update/updateContractTemplate";
import AllContractTemplates from "./components/portal/rentalContract/list/allContractTemplates";
import CreateExistingTenant from "./components/portal/lease/tenant/existing/create/createExistingTenant";
import AllTicketsInPortfolio from "./components/portal/tickets/list/portfolio/allTicketsInPortfolio";
import PropertyUnitBankAccounts from "./components/portal/properties/units/bank/list/propertyUnitBankAccounts";
import CreatePropertyUnitBankAccounts from "./components/portal/properties/units/bank/create/createPropertyUnitBankAccounts";
import PropertyBankAccountDetail from "./components/portal/properties/units/bank/detail/propertyBankAccountDetail";
import Paywall from "./components/portal/subscription/paywall/paywall";
import Billing from "./components/portal/subscription/billing/billing";
import {
  getActiveSubscriptionsFromStripe,
  getLastPaymentStatus,
  getOrCreateSubscriptionDetail,
} from "./services/authentication/subscription/subscriptionManagementService";
import { useSubscriptionUsageDetail } from "./services/context/subscription/subscriptionUsageDetailContext";
import { useSubscription } from "./services/context/subscription/subscriptionContext";
import AllTicketsInProperty from "./components/portal/tickets/list/property/allTicketsInProperty";
import TenantPendingRequest from "./components/portal/tenantScreening/existingTenants/pendingRequest/tenantPendingRequest";
import PendingContractDetail from "./components/portal/tenantScreening/existingTenants/pendingRequest/detail/pendingContractDetail";
import CreateNewAdvertisement from "./components/portal/properties/units/advertisement/create/createNewAdvertisement";
import ScreeningPackList from "./components/portal/tenantScreening/screeningPack/list/screeningPackList";
import CreateNewScreeningPack from "./components/portal/tenantScreening/screeningPack/create/createNewScreeningPack";
import MyAdvertisements from "./components/portal/properties/units/advertisement/list/myAdvertisements";
import AdvertisementDetail from "./components/portal/properties/units/advertisement/detail/advertisementDetail";
import CalendarManagement from "./components/portal/properties/units/advertisement/calendar/calendarManagement";
import ViewingScheduleDetail from "./components/portal/properties/units/advertisement/calendar/viewingScheduleDetail";
import ExpenseDetail from "./components/portal/properties/units/transactions/expenses/detail/expenseDetail";
import RevenueDetail from "./components/portal/properties/units/transactions/revenue/detail/revenueDetail";
import CreateNewUniversalList from "./components/portal/universalTasks/create/createNewUniversalList";
import UniversalList from "./components/portal/universalTasks/list/universalList";
import UniversalListDetail from "./components/portal/universalTasks/detail/universalListDetail";
import TenantApplicantPool from "./components/portal/properties/units/advertisement/prospectives/applicantPool/list/tenantApplicantPool";
import ProspectiveTenantProfileDetail from "./components/portal/properties/units/advertisement/prospectives/applicantPool/detail/prospectiveTenantProfileDetail";

//add the add viewing calendar for the property screening into the app
//need to be able to set the calendy if they want to use calendly

//IMPORTANT: need to restrict by if subscripted (view only) and if they are at their unit limit (just cant add more units)
//Any delete feature require a login code sent to the email to confirm the delete

//Plaid Check as an alternative to the credit report (but have credit report as a back up)
//Use the manual tenant screening for the Jamaica rental but can add the identity verification if it works there
function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userToken, setUserToken] = useState("");

  const { subscriptionUsageDetail, setSubscriptionUsageDetail } =
    useSubscriptionUsageDetail();
  const { subscription, setSubscription } = useSubscription();

  //get and set the landlord profile (use redux to update this)
  const [userProfile, setUserProfile] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    profileImg: "",
    stripeCustomerId: "",
  });

  //const { palRequests, setPalRequests } = usePalRequest();
  //const { subscription, setSubscription } = useSubscription();
  const [currentSubscriptionFromStripe, setCurrentSubscriptionFromStripe] =
    useState({
      id: "",
      object: "",
      application: "",
      application_fee_percent: "",
      automatic_tax: {
        enabled: true,
        liability: {
          type: "",
        },
      },
      billing_cycle_anchor: "",
      billing_cycle_anchor_config: "",
      billing_thresholds: "",
      cancel_at: "",
      cancel_at_period_end: false,
      canceled_at: "",
      cancellation_details: {
        comment: "",
        feedback: "",
        reason: "",
      },
      collection_method: "",
      created: "",
      currency: "",
      current_period_end: "",
      current_period_start: "",
      customer: "",
      days_until_due: "",
      default_payment_method: "",
      default_source: "",
      default_tax_rates: [],
      description: "",
      discount: "",
      discounts: [],
      ended_at: "",
      invoice_settings: {
        account_tax_ids: "",
        issuer: {
          type: "",
        },
      },
      items: {
        object: "",
        data: [
          {
            id: "",
            object: "",
            billing_thresholds: "",
            created: "",
            discounts: [],
            metadata: {},
            plan: {
              id: "",
              object: "",
              active: true,
              aggregate_usage: "",
              amount: "",
              amount_decimal: "",
              billing_scheme: "",
              created: "",
              currency: "",
              interval: "",
              interval_count: 1,
              livemode: false,
              metadata: {},
              meter: "",
              nickname: "",
              product: "",
              tiers_mode: "",
              transform_usage: "",
              trial_period_days: "",
              usage_type: "",
            },
            price: {
              id: "",
              object: "",
              active: true,
              billing_scheme: "",
              created: "",
              currency: "",
              custom_unit_amount: "",
              livemode: false,
              lookup_key: "",
              metadata: {},
              nickname: "",
              product: "",
              recurring: {
                aggregate_usage: "",
                interval: "",
                interval_count: 1,
                meter: "",
                trial_period_days: "",
                usage_type: "",
              },
              tax_behavior: "",
              tiers_mode: "",
              transform_quantity: "",
              type: "",
              unit_amount: "",
              unit_amount_decimal: "",
            },
            quantity: 1,
            subscription: "",
            tax_rates: [],
          },
        ],
        has_more: false,
        total_count: 1,
        url: "",
      },
      latest_invoice: "",
      livemode: false,
      metadata: {},
      next_pending_invoice_item_invoice: "",
      on_behalf_of: "",
      pause_collection: "",
      payment_settings: {
        payment_method_options: {
          acss_debit: "",
          bancontact: "",
          card: {
            network: "",
            request_three_d_secure: "automatic",
          },
          customer_balance: "",
          konbini: "",
          sepa_debit: "",
          us_bank_account: "",
        },
        payment_method_types: "",
        save_default_payment_method: "off",
      },
      pending_invoice_item_interval: "",
      pending_setup_intent: "",
      pending_update: "",
      plan: {
        id: "",
        object: "",
        active: true,
        aggregate_usage: "",
        amount: "",
        amount_decimal: "",
        billing_scheme: "",
        created: "",
        currency: "",
        interval: "",
        interval_count: 1,
        livemode: false,
        metadata: {},
        meter: "",
        nickname: "",
        product: "",
        tiers_mode: "",
        transform_usage: "",
        trial_period_days: "",
        usage_type: "",
      },
      quantity: 1,
      schedule: "",
      start_date: "",
      status: "active",
      test_clock: "",
      transfer_data: "",
      trial_end: "",
      trial_settings: {
        end_behavior: {
          missing_payment_method: "create_invoice",
        },
      },
      trial_start: "",
    });

  //const { settings, setSettings } = useSettings();

  //const isDarkMode = settings.isDarkMode;

  const getSubscriptionDetails = async () => {
    const subscriptionDetails = await getOrCreateSubscriptionDetail();

    if (!subscriptionDetails) return null;

    console.log("Subscription Details");
    console.log(subscriptionDetails);

    setSubscriptionUsageDetail(subscriptionDetails);
  };

  useEffect(() => {
    //dark mode start
    const theme = isDarkMode ? "dark" : "light";
    document.documentElement.setAttribute("data-bs-theme", theme);
    //dark mode end

    getUserToken();

    getSubscriptionDetails();
  }, [isDarkMode]);

  const getUserToken = async () => {
    try {
      const userToken = await getCurrentUser();

      if (!userToken) return null;

      setUserToken({ userToken });

      //localStorage.setItem(config.access_token, userToken);
      //it means the person is logged in
      getLoggedInUserProfile();
    } catch (error) {
      console.log(`Error getting user token: ${error}`);
      // Handle the error appropriately here
    }
  };

  const getLoggedInUserProfile = async () => {
    const userProfile = await getCurrentUserProfileByEmail();

    if (!userProfile) return null;

    //set if userProfile.user_id not empty
    if (
      userProfile.userId !== "" &&
      userProfile.userId !== null &&
      userProfile.userId !== undefined
    ) {
      localStorage.setItem(config.user_id, userProfile.userId);
    }

    if (
      userProfile.firstName !== "" &&
      userProfile.firstName !== null &&
      userProfile.firstName !== undefined
    ) {
      localStorage.setItem(config.first_name, userProfile.firstName);
    }

    if (
      userProfile.lastName !== "" &&
      userProfile.lastName !== null &&
      userProfile.lastName !== undefined
    ) {
      localStorage.setItem(config.last_name, userProfile.lastName);
    }

    if (
      userProfile.profileImg !== "" &&
      userProfile.profileImg !== null &&
      userProfile.profileImg !== undefined
    ) {
      localStorage.setItem(config.profile_img, userProfile.profileImg);
    }

    if (
      userProfile.stripeCustomerId !== "" &&
      userProfile.stripeCustomerId !== null &&
      userProfile.stripeCustomerId !== undefined
    ) {
      localStorage.setItem(
        config.stripe_customer_id,
        userProfile.stripeCustomerId
      );
    }

    setUserProfile({
      userId: userProfile.userId,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      emailAddress: userProfile.emailAddress,
      profileImg: userProfile.profileImg,
      stripeCustomerId: userProfile.stripeCustomerId,
    });

    // Check if stripeCustomerId exists before calling subscription-related functions
    if (userProfile.stripeCustomerId) {
      //check the subscription
      const subscriptionSuccess = await getCurrentSubscription();
      if (subscriptionSuccess) {
        //check the last payment status
        await findLastPaymentStatus();
      }
    }
    //check if there is any pending pal request
    //findAllPendingPalRequests();
  };

  const findLastPaymentStatus = async () => {
    const stripeCustomerId = localStorage.getItem(config.stripe_customer_id);
    const paymentStatus = await getLastPaymentStatus(stripeCustomerId);

    if (!paymentStatus) return null;

    //set the payment status
    //console.log('Payment Status');

    setSubscription(paymentStatus);

    //console.log(paymentStatus);
  };

  const getCurrentSubscription = async () => {
    //get the current subscription
    const currentSubscription = await getActiveSubscriptionsFromStripe();

    if (!currentSubscription) {
      localStorage.setItem(config.is_paid, false);
      return false;
    }

    setCurrentSubscriptionFromStripe(currentSubscription);
    // Check if the subscription is active
    const isPaid =
      currentSubscription.status === "active" ||
      currentSubscription.status === "trialing";
    //console.log('Payment Status: ', isPaid);
    localStorage.setItem(config.is_paid, isPaid);

    // Get the next expiration date
    //can be used for ssetting reminders
    const nextExpirationDate = new Date(
      currentSubscription.current_period_end * 1000
    );
    //console.log('Next Expiration Date: ', nextExpirationDate);

    // Get the last payment date
    const lastPaymentDate = new Date(
      currentSubscription.current_period_start * 1000
    );
    //console.log('Last Payment Date: ', lastPaymentDate);

    return true;
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <div id="layout-wrapper">
          {/*<NavBar /> need a protected view only show the nav when logged in*/}
          <div className="navbar-header">
            <NavigationBar userProfile={userProfile} isDarkMode={isDarkMode} />
          </div>

          <div className="vertical-overlay"></div>
          <div className="layout-width">
            <Routes>
              {/*<Route path='/' element={<LoginPage />} /> */}
              <Route path="/" element={<LoginPage />}>
                <Route path=":loginCode" element={<LoginPage />} />
              </Route>

              {/*<Route path='/signup' element={<Signup />} />*/}

              <Route path="/signup" element={<Signup />}>
                <Route path=":inviteCode" element={<Signup />} />
              </Route>

              {/*<ProtectedRoute
							user={userToken}
							path='/dashboard'
							element={<Dashboard />}
  />*/}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <Dashboard
                      userProfile={userProfile}
                      isDarkMode={isDarkMode}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/myproperties"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <MyProperties userProfile={userProfile} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newproperty"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <CreateNewProperty userProfile={userProfile} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newUnit"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route path=":propertyId" element={<CreatePropertyUnit />} />
              </Route>
              <Route
                path="/editUnit"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<UpdatePropertyUnit />}
                />
              </Route>
              <Route
                path="/unitTransactions"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<UnitPropertyTransactions />}
                />
              </Route>
              <Route
                path="/newUnitTransaction"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<CreateNewUnitTransaction />}
                />
              </Route>
              <Route
                path="/propertyUnitBankAccounts"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<PropertyUnitBankAccounts isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/newAdvertisement"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<CreateNewAdvertisement isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/myAdvertisements"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<MyAdvertisements isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/createPropertyUnitBankAccounts"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={
                    <CreatePropertyUnitBankAccounts isDarkMode={isDarkMode} />
                  }
                />
              </Route>
              <Route
                path="/bankAccountDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyBankAccountId"
                  element={
                    <PropertyBankAccountDetail isDarkMode={isDarkMode} />
                  }
                />
              </Route>

              <Route
                path="/advertisementCampaign"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":campaignId"
                  element={<AdvertisementDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/unitViewingCalendar"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":campaignId"
                  element={<CalendarManagement isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/unitViewingSchedule"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":campaignId"
                  element={<ViewingScheduleDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/expenseDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":unitExpenseId"
                  element={<ExpenseDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/revenueDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":unitRevenueId"
                  element={<RevenueDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/createUniversalList"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<CreateNewUniversalList isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/universalList"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<UniversalList isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/universalListDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":universalListId"
                  element={<UniversalListDetail isDarkMode={isDarkMode} />}
                />
              </Route>
              {/* PropertyUnitDetail	<Route
								path='/property'
								element={<PropertyDetail landlordProfile={landlordProfile} />}>
								<Route
									path=':propertyId'
									element={
										<ProtectedRoute user={userToken} redirectPath='/'>
											<PropertyDetail landlordProfile={landlordProfile} />
										</ProtectedRoute>
									}
								/>
							</Route>*/}
              <Route
                path="/allUnitTickets"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route path=":propertyUnitId" element={<AllUnitTickets />} />
              </Route>
              <Route
                path="/allPropertyTickets"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route path=":propertyId" element={<AllTicketsInProperty />} />
              </Route>
              <Route
                path="/newUnitTicket"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route path=":propertyUnitId?" element={<CreateUnitTicket />} />
              </Route>
              <Route
                path="/editUnitTicket"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route path=":ticketId" element={<EditUnitTicket />} />
              </Route>
              <Route
                path="/ticket"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route path=":ticketId" element={<TicketDetail />} />
              </Route>
              <Route
                path="/property"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={
                    <PropertyDetail landlordPruserProfileofile={userProfile} />
                  }
                />
              </Route>
              <Route
                path="/updateProperty"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<UpdateProperty isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/propertyunit"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<PropertyUnitDetail userProfile={userProfile} />}
                />
              </Route>
              <Route
                path="/createContractor"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <CreateNewContractor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contractors"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <AllContractors />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newContractTemplate"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <CreateNewContractTemplate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contractTemplate"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractTemplateId"
                  element={<ContractTemplateDetail isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/updateContractTemplate"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractTemplateId"
                  element={<UpdateContractTemplate isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/contractTemplates"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <AllContractTemplates />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/createExistingTenant"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<CreateExistingTenant isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/contractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractorId"
                  element={<ContractorProfileDetail isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/updateContractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractorId"
                  element={<UpdateContractorProfile isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/createSubContractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractorId"
                  element={<CreateNewSubContractor isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/updateSubContractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":subContractorId"
                  element={<UpdateSubContractor isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/applicantPool"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":campaignId"
                  element={<TenantApplicantPool isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/applicantPoolDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":tenantId"
                  element={
                    <ProspectiveTenantProfileDetail isDarkMode={isDarkMode} />
                  }
                />
              </Route>

              <Route
                path="/allTicketsInPortfolio"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <AllTicketsInPortfolio isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/paywall"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <Paywall isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/billing"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <Billing isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pendingExistingTenantRequest"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<TenantPendingRequest isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/contract"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":rentalContractId"
                  element={<PendingContractDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/screeningPacks"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <ScreeningPackList isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/createScreeningPack"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <CreateNewScreeningPack isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />

              {/*	
							<Route path='/property' element={<PropertyDetail />}>
								<Route
									path=':propertyId'
									element={
										<ProtectedRoute user={userToken} redirectPath='/'>
											<PropertyDetail landlordProfile={landlordProfile} />
										</ProtectedRoute>
									}
								/>
							</Route>
						

								<Route path='/service' element={<ServiceDetail />}>
							<Route path=':title' element={<ServiceDetail />} />
						</Route>

						<Route element={<ProtectedRoute user={userToken} />}>
						
							<Route path='/dashboard' element={<Dashboard />} />
						</Route>
						<Route
							path='/dashboard'
							element={
								<ProtectedRoute user={userToken}>
									<Dashboard />
								</ProtectedRoute>
							}
						/>
					
						<Route path='/contactus' element={<ContactUs />} />
						<Route path='/tenantapplication' element={<TenantApplication />} />
						<Route path='/blogs' element={<Blogs />} />
						<Route path='/blog' element={<BlogDetail />} />
						<Route path='/*' element={<NotFoundPage />} />*/}
              <Route path="/logout" element={<LogOut />} />
            </Routes>

            {/*	<GeneralFooter />*/}
            <footer className="footer ">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <script>document.write(new Date().getFullYear())</script> ©
                    Gurenter Landlord.
                  </div>
                  <div className="col-sm-6">
                    <div className="text-sm-end d-none d-sm-block">
                      Designed & Developed by Gurenter LLC
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
