import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  LinearProgress,
  Box,
  Chip,
  Container,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getAllTenantsForCampaignId } from "../../../../../../../../services/propertyUnits/tenantScreening/prospectives/tenantRentalApplicationManagement";
import { getScreeningPackById } from "../../../../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement";
import { getAdvertisementListingByCampaignId } from "../../../../../../../../services/propertyUnits/advertisment/advertismentManagement";

import NoRecordsFound from "../../../../../../tickets/list/sub/noRecordsFound";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PageTitleBox from "../../../../../../../reusable/title/pageTitleBox";

const screeningPhases = [
  { name: "Pre-screening", field: "has_passed_pre_screening" },
  { name: "Viewing", field: "has_passed_viewing_screening" },
  { name: "Application", field: "has_passed_application_screening" },
  { name: "Background Check", field: "has_passed_background_check" },
  { name: "Final Steps", field: "has_been_selected_for_the_rental" },
];

const TenantApplicantPool = ({ isDarkMode }) => {
  const pageTitle = "Applicant Pool";

  const [tenants, setTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [screeningPack, setScreeningPack] = useState(null);
  const [rentAmount, setRentAmount] = useState(null);
  const { campaignId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch tenants
        const tenantsData = await getAllTenantsForCampaignId(campaignId);

        // Fetch advertisement
        const advertisement = await getAdvertisementListingByCampaignId(
          campaignId
        );

        if (advertisement) {
          setRentAmount(advertisement.rental_unit_price);
          if (advertisement.screening_pack_id) {
            const screeningPackData = await getScreeningPackById(
              advertisement.screening_pack_id
            );
            setScreeningPack(screeningPackData);
          }
        }

        console.log("advertisement", advertisement);

        // Fetch screening pack
        let screeningPackData = null;
        if (advertisement && advertisement.screening_pack_id) {
          screeningPackData = await getScreeningPackById(
            advertisement.screening_pack_id
          );
          console.log("screeningPackData", screeningPackData);
          setScreeningPack(screeningPackData);
        }

        const formattedData = tenantsData.map((tenant, index) => ({
          id: tenant.profile?.tenant_id || `unknown-${index}`,
          fullName:
            `${tenant.profile?.first_name || ""} ${
              tenant.profile?.last_name || ""
            }`.trim() || "N/A",
          applicationDate: tenant.application?.application_date
            ? new Date(tenant.application.application_date).toLocaleDateString()
            : "N/A",
          screeningProgress: calculateScreeningProgress(tenant.application),
          status: getApplicationStatus(tenant.application),
          income:
            tenant.application?.prescreening_info?.total_monthly_income ||
            "N/A",
          ...tenant,
        }));
        setTenants(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenants([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [campaignId]);

  const calculateScreeningProgress = (application) => {
    if (!application) return 0;
    return screeningPhases.findIndex((phase) => !application[phase.field]) * 20;
  };

  const getApplicationStatus = (application) => {
    if (!application) return "Not Started";
    if (application.application_status === "rejected") return "Rejected";
    if (application.has_been_selected_for_the_rental) return "Approved";
    return "In Progress";
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "success";
      case "Rejected":
        return "error";
      case "In Progress":
        return "warning";
      default:
        return "default";
    }
  };

  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center", // This centers the content vertically
          }}
        >
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() =>
              navigate(`/applicantPoolDetail/${params.row.profile.tenant_id}`)
            }
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    { field: "applicationDate", headerName: "Application Date", flex: 1 },
    {
      field: "income",
      headerName: "Monthly Income",
      flex: 1,
      renderCell: (params) => {
        const income = params.value;
        const incomeValue =
          typeof income === "number" ? `$${income.toLocaleString()}` : income;

        let meetsIncomeCriteria = false;
        if (screeningPack && rentAmount && typeof income === "number") {
          const requiredIncome =
            rentAmount * screeningPack.minimum_income_multiple;
          meetsIncomeCriteria = income >= requiredIncome;
        }

        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            height="100%"
            gap={1} // Adds a small gap between income and icon
          >
            <Typography>{incomeValue}</Typography>
            {typeof income === "number" && screeningPack && rentAmount && (
              <Tooltip
                title={
                  meetsIncomeCriteria
                    ? "Meets income requirement"
                    : "Does not meet income requirement"
                }
              >
                {meetsIncomeCriteria ? (
                  <CheckCircleIcon color="success" fontSize="small" />
                ) : (
                  <CancelIcon color="error" fontSize="small" />
                )}
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "screeningProgress",
      headerName: "Screening Progress",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "8px 0", // Add some vertical padding
          }}
        >
          <Box sx={{ flexGrow: 1, mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={params.value}
              sx={{ height: 8 }}
            />
          </Box>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(params.value)}%`}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={getStatusColor(params.value)}
          variant="outlined"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          startIcon={<VisibilityIcon />}
          onClick={() =>
            navigate(`/applicantPoolDetail/${params.row.profile.tenant_id}`)
          }
        >
          Profile
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        className="page-content"
        data-bs-theme={isDarkMode ? "dark" : "light"}
      >
        <div className="container-fluid">
          {/* start page title */}
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Advertisement Campaign"
            previousPageLink={`/advertisementCampaign/${campaignId}`}
          />
          {/* end page title */}
          <div className="col-12">
            <Container maxWidth="xxl">
              <Card>
                <CardHeader title="Tenant Applicants" />
                <CardContent>
                  <Box sx={{ height: 600, width: "100%" }}>
                    <DataGrid
                      rows={tenants}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[5, 10, 20]}
                      loading={isLoading}
                      components={{
                        Toolbar: GridToolbar,
                        NoRowsOverlay: NoRecordsFound,
                      }}
                      disableSelectionOnClick
                    />
                  </Box>
                </CardContent>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TenantApplicantPool;
