import React, { useEffect, useState } from 'react';
import Joi from 'joi-browser';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import PageTitleBox from '../../../../../reusable/title/pageTitleBox';
import './sub/css/pendingContractDetail.css';
//import '../detail/sub/css/pendingContractDetail.css';
import config from '../../../../../../constants/config.json';
import 'simplebar'; // for the side effects
import 'simplebar/dist/simplebar.min.css';
import HTMLEditor from '../../../../../reusable/form/ckeditor/cKEditorComponent';
import Input from '../../../../../reusable/form/Input';
import { fetchPropertyByPortfolioId } from '../../../../../../services/property/retrieve/propertyRetrievalService';
import {
  createOrUpdateUserRentalContractTemplate,
  getUserRentalContractTemplateById,
} from '../../../../../../services/rental_contract/rentalContractService';
import { getPortfolioByOwnershipId } from '../../../../../../services/portfolio/portfolioManagement';
import {
  getRentalContractById,
  getTenantById,
} from '../../../../../../services/rent/tenant/invite/tenantRentalContractService';
import ContractEditorComponent from '../../../../../reusable/form/custom/contractEditorComponent';
import { fetchOwnerProfileRecord } from '../../../../../../services/landlords/landlordProfileService';
import { formatDate } from '../../../../../../services/utils/dateUtils';

const PendingContractDetail = ({ isDarkMode }) => {
  const pageTitle = 'Pending Contract Detail';
  const navigate = useNavigate();
  //rentalContractId useParams
  const { rentalContractId } = useParams();

  const [contractTemplate, setContractTemplate] = useState({
    contract_template_id: uuidv4(),
    user_id: localStorage.getItem(config.user_id),
    portfolio_id: '',
    property_id: '',
    contract_name: '',
    contract_description: '',
    contract_template: '',
    created_at: Date.now(),
    updated_at: Date.now(),
    is_active: true,
    is_deleted: false,
  });

  //tenant record
  //later will become a list
  const [tenantRecord, setTenantRecord] = useState({
    tenant_id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email_address: '',
    stripe_customer_id: '',
    phone_number: '',
    is_verfied: false,
    profile_img_url: '',
  });

  const [landlordProfiles, setLandlordProfiles] = useState([
    {
      user_id: '',
      first_name: '',
      last_name: '',
      email_address: '',
      profile_img: '',
      user_type: '',
    },
  ]);

  const [rentalContract, setRentalContract] = useState({
    rental_contract_id: '',
    tenant_id: '',
    portfolio_id: '',
    property_id: '',
    property_unit_id: '',
    start_date: Date.now(),
    end_date: Date.now(),
    monthly_rent: 0,
    security_deposit: 0,
    pet_deposit: 0,
    pet_rent: 0,
    contract_template_id: '',
    contract_detail: '',
    created_at: Date.now(),
    updated_at: Date.now(),
    is_active: true,
    is_deleted: false,
    portfolio_ownership_id: '',
    property_name: '',
    property_unit_name: '',
    portfolio_name: '',
    owner_manager_user_id: '',
    owner_manager_name: '',
    owner_manager_email: '',
    end_of_lease_action: '',
  });

  const schema = {
    contract_template_id: Joi.string().required().label('Contract Template Id'),
    user_id: Joi.string().required().label('User'),
    portfolio_id: Joi.string().allow('').label('Portfolio'),
    property_id: Joi.string().allow('').label('Property'),
    contract_name: Joi.string().required().label('Contract Name'),
    contract_description: Joi.string()
      .optional()
      .allow('')
      .label('Contract Description'),
    contract_template: Joi.string().required().label('Contract Template'),
    created_at: Joi.date().optional().label('Created At'),
    updated_at: Joi.date().optional().label('Updated At'),
    is_active: Joi.boolean().optional().label('Is Active'),
    is_deleted: Joi.boolean().optional().label('Is Deleted'),
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };

    const { error } = Joi.validate(contractTemplate, schema, options);

    //console.log('Error:', error);

    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    // console.log('Name:', name);
    //console.log('Value:', value);
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    console.log('Schema:', schemaLocal);

    const { error } = Joi.validate(obj, schemaLocal, options);

    console.log('Error in  validateProperty:', error);

    return error ? error.details[0].message : null;
  };
  //handle the form input changes
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else {
      name = 'contract_template';
      value = input;
    }
    // console.log('Name:', name);
    //console.log('Value:', value);
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...contractTemplate };
    if (value !== undefined) {
      data[name] = value;
    }

    setContractTemplate(data);
    setErrors(currentErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      console.log('contractTemplate:', contractTemplate);

      const response = await createOrUpdateUserRentalContractTemplate(
        contractTemplate
      );
      console.log(response);
      if (response.is_error) {
        toast.error(response.server_message);
        return;
      }

      toast.success('Contract Template has been successfully created!');
      navigate(`/contractTemplate/${contractTemplate.contract_template_id}`);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log('Error:', ex.response.data); //need to log this
        const currentErrors = { ...errors };
        currentErrors.email_address = ex.response.data;
        setErrors(currentErrors);
      }
    }
  };

  //dropdown list for the portfolio
  const [existingPortfolioOptions, setExistingPortfolioOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  //selected portfolio options
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);

  const handlePortfolioChange = (option) => {
    if (!option) return;
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log('Portfolio changed:', option);
    // You can update the state or perform other side effects here
    contractTemplate.portfolio_id = option.value;

    //set the selected portfolio
    setSelectedPortfolioOption(option);

    //get all the properties for the portfolio
    getPropertiesByPortfolioId(option.value);
  };

  const getExistingPortfolios = async () => {
    //set existingPortfolio

    const userId = localStorage.getItem(config.user_id);
    const response = await getPortfolioByOwnershipId(userId);

    if (!response) {
      console.log('No portfolios found for this user');
      return;
    }

    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((portfolio) => ({
      value: portfolio.portfolio_id,
      label: portfolio.portfolio_name,
      data: portfolio,
    }));
    setExistingPortfolioOptions(options);
  };

  //get properties section
  const [existingPropertiesOptions, setExistingPropertiesOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  //selected portfolio options
  const [selectedPropertyOption, setSelectedPropertyOption] = useState(null);

  const getPropertiesByPortfolioId = async (portfolioId) => {
    //get all the properties for the portfolio
    const response = await fetchPropertyByPortfolioId(portfolioId);

    if (!response) {
      console.log('No properties found for this portfolio');
      return;
    }

    //clear the selected property
    setSelectedPropertyOption(null);
    contractTemplate.property_id = '';

    console.log('Properties:', response);
    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((property) => ({
      value: property.property_id,
      label: property.property_alias_name,
      data: property,
    }));
    setExistingPropertiesOptions(options);
  };

  const handlePropertyChange = (option) => {
    if (!option) {
      console.log('Cleared pressed');
      setSelectedPropertyOption(null);
      contractTemplate.property_id = '';
      return;
    }
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log('Property changed:', option);
    // You can update the state or perform other side effects here
    contractTemplate.property_id = option.value;

    //set the selected portfolio
    setSelectedPropertyOption(option);
  };

  //get the rental contract then get the template out of it
  const fetchRentalContractById = async () => {
    //get the rental contract by id
    //alert(rentalContractId);
    const response = await getRentalContractById(rentalContractId);
    console.log('Rental Contract:', response);
    if (!response) {
      console.log('No rental contract found for this user');
      return;
    }

    //set the contract template
    setRentalContract(response);

    //get the contract template
    //contract_template_id
    const contractTemplateId = response.contract_template_id;
    if (!contractTemplateId) {
      console.log('No contract template found for this user');
      return;
    } else {
      fetchRentalContractTemplate(contractTemplateId);
      //get the tenant profile
      //need to support multiple tenants
      fetchTenantProfile(response.tenant_id);

      //get the landlord information (owner_manager_user_id)
      fetchLandlordProfile(response.owner_manager_user_id);
    }
  };

  //get the
  const fetchRentalContractTemplate = async (_contractTemplateId) => {
    const contractTemplate = await getUserRentalContractTemplateById(
      _contractTemplateId
    );
    console.log('contractTemplate', contractTemplate);
    setContractTemplate(contractTemplate);

    //now I need to set the preview of the contract with the tenant names etc in the preview

    //set the selected portfolio
    getExistingPortfolios(
      contractTemplate.portfolio_id,
      contractTemplate.property_id
    );
  };

  // Initialize landlordProfiles as an array

  //get the landlord (owner_manager) profile
  const fetchLandlordProfile = async (_owner_manager_user_id) => {
    //get the landlord profile
    const landlordRecord = await fetchOwnerProfileRecord(
      _owner_manager_user_id
    );
    console.log('Landlord Record:', landlordRecord);
    if (!landlordRecord) {
      console.log('No landlord record found for this user');
      return;
    }

    //set the landlord records
    //adding to the list unique using set
    const updatedProfiles = new Set([
      ...landlordProfiles.filter(
        (profile) =>
          profile.first_name !== landlordRecord.first_name ||
          profile.last_name !== landlordRecord.last_name ||
          profile.email_address !== landlordRecord.email_address
      ),
      landlordRecord,
    ]);
    setLandlordProfiles(Array.from(updatedProfiles));
  };
  //get the tenant profile
  const fetchTenantProfile = async (_tenant_id) => {
    //get the tenant profile

    const tenantRecord = await getTenantById(_tenant_id);
    console.log('Tenant Record:', tenantRecord);
    if (!tenantRecord) {
      console.log('No tenant record found for this user');
      return;
    }

    //set the tenant record
    setTenantRecord(tenantRecord);
  };

  useEffect(() => {
    fetchRentalContractById();

    getExistingPortfolios();
  }, []);

  const [processedTemplate, setProcessedTemplate] = useState('');

  useEffect(() => {
    const processTemplate = async () => {
      const result = replacePlaceholders(contractTemplate.contract_template, {
        tenant: `${tenantRecord.first_name} ${tenantRecord.last_name}`,
        landlord: getLandlordNames(landlordProfiles),
        //add the other parameters here next
      });
      setProcessedTemplate(result);
    };

    processTemplate();
  }, [contractTemplate, tenantRecord, landlordProfiles]);

  //TODO: need to have the resent button that sends email plus show the formatted date with  2024-06-30T23:34:23.301000 format etc
  //Put the following functions in a separate file start
  const getLandlordNames = (profiles) => {
    return profiles
      .filter((profile) => profile.first_name && profile.last_name) // Filter out empty records
      .map((profile) => `${profile.first_name} ${profile.last_name}`) // Map to full names
      .join(', '); // Join names with a comma
  };

  const replacePlaceholders = (_contract_template, records) => {
    let processedTemplate = _contract_template;
    Object.keys(records).forEach((key) => {
      const placeholder = `@${key.charAt(0).toUpperCase() + key.slice(1)}`;
      processedTemplate = processedTemplate.replace(
        new RegExp(placeholder, 'g'),
        records[key]
      );
    });
    return processedTemplate;
  };

  //Put the following functions in a separate file end

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle='All Contract Templates'
            previousPageLink={`/contractTemplates`}
          />

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Lease Contract
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                {/* Contract Detail */}
                <div className='card-body'>
                  <div className='row gy-4'>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>Property</label>
                        <h5>{rentalContract.property_name}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>Unit</label>
                        <h5>{rentalContract.property_unit_name}</h5>
                      </div>
                    </div>
                    {/* Address (Need to get this from the server)*/}
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>Address</label>
                        <h5>{rentalContract.property_address}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>Tenant</label>
                        <h5>{`${tenantRecord.first_name} ${tenantRecord.last_name}`}</h5>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>Start Date</label>
                        <h5>{formatDate(rentalContract.start_date)}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>End Date</label>
                        <h5>{rentalContract.end_date}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>Monthly Rent</label>
                        <h5>{rentalContract.monthly_rent}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>
                          Security Deposit
                        </label>
                        <h5>{rentalContract.security_deposit}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label*/}
                        <label htmlFor='form-grid-category'>Pet Deposit</label>
                        <h5>{rentalContract.pet_deposit}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label- Late Fee*/}
                        <label htmlFor='form-grid-category'>Late Fee</label>
                        <h5>{rentalContract.late_fee}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label- Contract Type*/}
                        <label htmlFor='form-grid-category'>
                          Contract Type
                        </label>
                        <h5>{rentalContract.contract_type}</h5>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        {/* Label- End of Lease Action (TODO)*/}
                        <label htmlFor='form-grid-category'>
                          End of Lease Action
                        </label>
                        <h5>{rentalContract.end_of_lease_action}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Divider for the tenant section (Need to become a list)*/}
                {tenantRecord && tenantRecord.first_name && (
                  <>
                    <div className='card-header align-items-center d-flex'>
                      <h4 className='card-title mb-0 flex-grow-1'>Tenant</h4>
                      <div className='flex-shrink-0'></div>
                    </div>

                    <div className='card-body'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* Label*/}
                            <label htmlFor='form-grid-category'>
                              First Name
                            </label>
                            <h5>{tenantRecord.first_name}</h5>
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* Label*/}
                            <label htmlFor='form-grid-category'>
                              Last Name
                            </label>
                            <h5>{tenantRecord.last_name}</h5>
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* Label*/}
                            <label htmlFor='form-grid-category'>
                              Email Address
                            </label>
                            <h5>{tenantRecord.email_address}</h5>
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* Label*/}
                            {/* Has signed or not checkbox*/}
                            <label htmlFor='form-grid-category'>
                              Has Signed
                            </label>
                            <h5>
                              {tenantRecord.has_signed
                                ? 'Signed'
                                : 'Not Signed'}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Divider for the landlord section */}
                {landlordProfiles && landlordProfiles.length > 0 && (
                  <>
                    <div className='card-header align-items-center d-flex'>
                      <h4 className='card-title mb-0 flex-grow-1'>
                        Landlord
                        {landlordProfiles.length > 1 ? 's' : ''}
                      </h4>
                      <div className='flex-shrink-0'></div>
                    </div>

                    <div className='card-body'>
                      <ul className='landlord-list'>
                        {landlordProfiles
                          .filter((landlord) => landlord.first_name) // Filter out empty records
                          .map((landlord, index) => (
                            <li key={index} className='col-xxl-4 col-md-8'>
                              <div className='row'>
                                <div className='col-md-4'>
                                  <label htmlFor='form-grid-category'>
                                    First Name
                                  </label>
                                  <h5>{landlord.first_name}</h5>
                                </div>
                                <div className='col-md-4'>
                                  <label htmlFor='form-grid-category'>
                                    Last Name
                                  </label>
                                  <h5>{landlord.last_name}</h5>
                                </div>
                                <div className='col-md-4'>
                                  <label htmlFor='form-grid-category'>
                                    Email Address
                                  </label>
                                  <h5>{landlord.email_address}</h5>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </>
                )}

                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        {/* Select Portfolio cant add a new one*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Portfolio
                            </label>
                            <Select
                              name='portfolio_id'
                              options={existingPortfolioOptions}
                              onChange={handlePortfolioChange}
                              isClearable
                            />
                          </div>
                        </div>
                        {/* Select Property*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Property (Optional)
                            </label>
                            <Select
                              name='property_id'
                              options={existingPropertiesOptions}
                              onChange={handlePropertyChange}
                              value={selectedPropertyOption}
                              isClearable
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='contract_name'
                              label='Contract Name'
                              type={'text'}
                              value={contractTemplate.contract_name}
                              onChange={handleChange}
                              error={errors.contract_name}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='contract_description'
                              label='Contract Description'
                              type={'text'}
                              value={contractTemplate.contract_description}
                              onChange={handleChange}
                              error={errors.contract_description}
                            />
                          </div>
                        </div>
                        <div>
                          <div className='row g-3 pt-3'>
                            <div className='col-lg-12'>
                              <div className='row'>
                                <div className='col-md-8 d-flex flex-column'>
                                  <label
                                    for='exampleFormControlTextarea1'
                                    className='form-label'
                                  >
                                    Contract Template
                                  </label>

                                  <ContractEditorComponent
                                    handleChange={handleChange}
                                    name='contract_template'
                                    content={contractTemplate.contract_template}
                                    height='400px'
                                  />
                                </div>
                                {processedTemplate &&
                                  processedTemplate !== '' && (
                                    <div className='col-md-4'>
                                      <label
                                        for='exampleFormControlTextarea1'
                                        className='form-label'
                                      >
                                        Preview
                                      </label>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: processedTemplate.replace(
                                            /<img /g,
                                            '<img style="max-width: 100%; height: auto;" '
                                          ),
                                        }}
                                        style={{
                                          padding: '10px',
                                          border: '1px solid #ddd',
                                          borderRadius: '5px',
                                          overflow: 'auto', // Changed from 'hidden' to 'auto'
                                          height: '440px',
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Add to the list*/}
                        <div className='row gy-4 mt-3'>
                          <div className='col-xxl-3 col-md-6'>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={validate()}
                            >
                              Create Contract
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendingContractDetail;
