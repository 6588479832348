import React, { useEffect, useState } from 'react';
import Joi from 'joi-browser';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Badge, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import PageTitleBox from '../../../../../reusable/title/pageTitleBox';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../../../../reusable/form/Input';
import { getExpenseCategories } from '../../../../../../services/systemDefined/categories/expenses/sysExpenseCategoryManagement';
import { getRevenueCategories } from '../../../../../../services/systemDefined/categories/revenue/sysRevenueCategoryManagement';
import { createOrUpdateUnitExpense } from '../../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement';
import { createOrUpdateUnitRevenue } from '../../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement';
import { getPropertyUnitBankAccounts } from '../../../../../../services/bank/bankManager';
import { set } from 'date-fns';
import { getPropertyDetailByPropertyUnitId } from '../../../../../../services/property/retrieve/propertyRetrievalService';
import { getContractorsByPortfolioId } from '../../../../../../services/contractor/contractorManagement';
import MultiImageUploader from '../../../../../reusable/uploader/multiImageUploader';

//Continue here
//create the revnue or expense then work on show transactions
const CreateNewUnitTransaction = ({ isDarkMode }) => {
  const { propertyUnitId } = useParams();
  const pageTitle = 'Create New Unit Transaction';
  const navigate = useNavigate();

  //upload receipt proof
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [base64Data, setBase64Data] = useState([]);

  //formData
  const [showContractorOptions, setShowContractorOptions] = useState(false);

  const [formData, setFormData] = useState({
    is_revenue_or_expense: '',
    unit_revenue_expense_id: '',
    property_unit_id: '',
    category_id: '',
    amount: '',
    date: Date(),
    is_active: '',
    is_received_from_tenant: '',
    payment_date: '',
    is_capital_revenue: '',
    title: '',
    description: '',
    receipt_link: '',
    day: 0,
    month: 0,
    year: 0,
    created_at: new Date(),
    updated_at: new Date(),
    //new fields to store
    property_bank_account_id: '',
    bank_account_id: '',
    bank_id: '',
    bank_name: '',
    contractor_id: '',
    contractor_name: '',
  });

  const [bankAccounts, setBankAccounts] = useState([
    {
      property_bank_account_id: '',
      bank_account_id: '',
      property_id: '',
      property_unit_id: '',
      bank_alias_name: '',
      account_balance: 0,
      is_reserve_account: false,
      reserve_amount: 0,
      bank_account: {
        account_id: '',
        account_name: '',
        account_mask: '',
        account_subtype: '',
        account_type: '',
        available_balance: 0,
        bank_account_id: '',
        bank_id: '',
        bank_name: '',
        created_at: '',
        currency_id: '',
        current_balance: 0,
        institution_id: '',
        is_default_account: false,
        logo: '',
        routing_number: '',
        routing_numbers: [''],
        updated_at: '',
        url: '',
      },
      bank: {
        bank_country: '',
        bank_name: '',
        ownership_id: '',
        bank_id: '',
        created_at: '',
        institution_id: '',
        is_default_bank: false,
        logo: '',
        routing_number: '',
        routing_numbers: [''],
        swift_code: '',
        updated_at: '',
        url: '',
      },
    },
  ]);

  const [sysExpenseCategories, setSysExpenseCategories] = useState([
    {
      revenue_category_id: '',
      created_at: '',
      is_active: true,
      revenue_category: '',
      updated_at: '',
    },
  ]);

  const [sysRevenueCategories, setSysRevenueCategories] = useState([
    {
      expense_category_id: '',
      created_at: '',
      expense_category: '',
      is_active: true,
      updated_at: '',
    },
  ]);

  const [propertyPortfolioUnit, setPropertyPortfolioUnit] = useState({
    property_unit: {
      property_unit_id: '',
      property_id: '',
      unit_name: '',
      number_of_bedrooms: '',
      number_of_bathrooms: '',
      square_footage: '',
      market_rent_amount: '',
    },
    property: {
      city_town: '',
      country: '',
      country_id: '',
      portfolio_id: '',
      property_id: '',
      state_parish: '',
      street_address_1: '',
      zip_postal_code: '',
      currency_id: '',
      property_alias_name: '',
      property_type_id: '',
      street_address_2: '',
      sub_property_type_id: '',
    },
    portfolio: {
      ownership_id: '',
      portfolio_id: '',
      portfolio_name: '',
    },
  });

  const [contractors, setContractors] = useState([
    {
      contractor_id: '',
      portfolio_id: '',
      property_id: '',
      first_name: '',
      last_name: '',
      email_address: '',
      phone_number: '',
      company_name: '',
      company_address: '',
      company_address_2: '',
      company_city: '',
      company_state: '',
      company_zip: '',
      company_country: '',
      company_logo: '',
      company_website: '',
      company_phone: '',
      company_email: '',
      company_description: '',
      company_license: '',
      company_insurance: '',
      company_insurance_expiration: new Date(),
      company_first_licensure_date: new Date(),
      company_license_expiration: new Date(),
      company_license_status: '', //nt used
      company_tax_rate: 0,
      company_tax_type: '', //not used
      payment_rate_type: '', //not used
      payment_rate_other: '', //not used
      payment_rate: 0, //not used need to use the payment rate type
      payment_rate_frequency: '',
      company_tax_id: '',
      payment_rate_method: '',
      created_at: new Date(),
      updated_at: new Date(),
      is_active: true,
      is_verified: false,
      is_deleted: false,
      is_suspended: false,
      is_paused: false,
      job_count: 0,
      job_count_completed: 0,
      company_license_state: '',
    },
  ]);

  const [contractorOptions, setContractorOptions] = useState([
    {
      value: '',
      label: '',
      data: {},
    },
  ]);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  const [revenueOrExpenseData, setRevenueOrExpenseData] = useState([
    { value: 'revenue', label: 'Revenue' },
    { value: 'expense', label: 'Expense' },
  ]);

  //schema
  const schema = {
    is_revenue_or_expense: Joi.string()
      .optional()
      .allow('')
      .label('Revenue or Expense'),
    unit_revenue_expense_id: Joi.string()
      .optional()
      .allow('')
      .label('Revenue or Expense ID'),
    property_unit_id: Joi.string()
      .optional()
      .allow('')
      .label('Property Unit ID'),
    category_id: Joi.string().optional().allow('').label('Category ID'), //need to add the dropdownlist
    amount: Joi.number().required().label('Amount'),
    date: Joi.date().required().label('Date'),
    is_active: Joi.string().optional().allow('').label('Active'),
    is_received_from_tenant: Joi.string()
      .optional()
      .allow('')
      .label('Received from Tenant'),
    payment_date: Joi.string().optional().allow('').label('Payment Date'),
    is_capital_revenue: Joi.string()
      .optional()
      .allow('')
      .label('Capital Revenue'),
    title: Joi.string().required().label('Title'),
    description: Joi.string().optional().allow('').label('Description'),
    receipt_link: Joi.string().optional().allow('').label('Receipt Link'),
    day: Joi.number().required().label('Day'),
    month: Joi.number().required().label('Month'),
    year: Joi.number().required().label('Year'),
    created_at: Joi.date().optional().label('Created At'),
    updated_at: Joi.date().optional().label('Updated At'),
    //new fields to store
    property_bank_account_id: Joi.string().required().label('Bank Account'),
    bank_account_id: Joi.string().optional().allow('').label('Bank Account ID'),
    bank_id: Joi.string().optional().allow('').label('Bank ID'),
    bank_name: Joi.string().optional().allow('').label('Bank Name'),
    contractor_id: Joi.string().optional().allow('').label('Contractor ID'),
    contractor_name: Joi.string().optional().allow('').label('Contractor Name'),
    //new
    transaction_receipt_image_uploads: Joi.array()
      .optional()
      .label('Transaction Receipt Image Uploads'),
  };
  const [errors, setErrors] = useState({});

  //validate the form
  const validate = () => {
    const options = { abortEarly: false };

    const { error } = Joi.validate(formData, schema, options);
    //console.log(error);
    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    const { error } = Joi.validate(obj, schemaLocal, options);

    return error ? error.details[0].message : null;
  };

  // Function to check if a year is a leap year
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  // Function to get the number of days in a given month and year
  const getDaysInMonth = (month, year) => {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;
      case 4:
      case 6:
      case 9:
      case 11:
        return 30;
      case 2:
        return isLeapYear(year) ? 29 : 28;
      default:
        return 0; // Invalid month
    }
  };

  // Validate and print the day, month, year
  const validateDate = (day, month, year) => {
    const daysInMonth = getDaysInMonth(month, year);

    if (day < 1 || day > daysInMonth) {
      console.error(
        `Invalid day: ${day} for month: ${month} and year: ${year}`
      );
      return false;
    } else {
      console.log(`Day: ${day}`);
    }

    if (month < 1 || month > 12) {
      console.error(`Invalid month: ${month}`);
      return false;
    } else {
      console.log(`Month: ${month}`);
    }

    if (year < 1900 || year > 2100) {
      console.error(`Invalid year: ${year}`);
      return false;
    } else {
      console.log(`Year: ${year}`);
    }

    return true;
  };

  //handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate();
    setErrors(newErrors || {});
    if (newErrors) return;

    //submit the formData
    doSubmit();
  };

  const doSubmit = async () => {
    //call the server to save the changes

    console.log('submitting the form data');
    console.log(formData.is_revenue_or_expense);
    console.log(formData);

    // Parse the date field to extract day, month, and year
    const [year, month, day] = formData.date.split('-').map(Number);

    // Validate the date
    if (!validateDate(day, month, year)) {
      toast.error('Invalid date selected');
      return;
    }

    // Update formData with parsed day, month, and year
    formData.day = day;
    formData.month = month;
    formData.year = year;

    //if is an expense
    if (formData.is_revenue_or_expense === 'expense') {
      //put the in own function

      //Set the unitExpenseModel
      const unitExpenseModel = {
        unit_expense_id: uuidv4(),
        property_unit_id: propertyUnitId,
        expense_category_id: formData.category_id,
        expense_amount: formData.amount,
        expense_date: formData.date,
        is_active: true,
        is_paid_by_tenant: false,
        is_paid_by_landlord: true,
        payment_date: formData.date,
        is_capital_expense: false,
        expense_title: formData.title,
        expense_description: formData.description,
        receipt_link: '', //to do to upload the receipt image
        expense_day: formData.day,
        expense_month: formData.month,
        expense_year: formData.year,
        created_at: new Date(),
        updated_at: new Date(),
        property_bank_account_id: formData.property_bank_account_id,
        bank_account_id: formData.bank_account_id,
        bank_id: formData.bank_id,
        bank_name: formData.bank_name,
        //optional
        contractor_id: formData.contractor_id,
        contractor_name: formData.contractor_name,
        //new fields
        transaction_receipt_image_uploads: imagesToUpload,
      };

      console.log('unitExpenseModel');
      console.log(unitExpenseModel);
      //call the createOrUpdateUnitExpense
      const result = await createOrUpdateUnitExpense(unitExpenseModel);

      if (result) {
        if (result.error) {
          toast.error(result.error);
          return;
        }

        toast.success('Expense created successfully');
        // Redirect to the unit transactions
        navigate(`/unitTransactions/${propertyUnitId}`);
      } else {
        toast.error('Error creating the expense');
      }
    } else if (formData.is_revenue_or_expense === 'revenue') {
      // Set the unitRevenueModel
      const unitRevenueModel = {
        unit_revenue_id: uuidv4(),
        property_unit_id: propertyUnitId,
        revenue_category_id: formData.category_id,
        revenue_amount: formData.amount,
        revenue_date: formData.date,
        is_active: true,
        is_received_from_tenant: false,
        payment_date: formData.date,
        is_capital_revenue: false,
        revenue_title: formData.title,
        revenue_description: formData.description,
        receipt_link: '', // to do to upload the receipt image
        revenue_day: formData.day,
        revenue_month: formData.month,
        revenue_year: formData.year,
        created_at: new Date(),
        updated_at: new Date(),
        property_bank_account_id: formData.property_bank_account_id,
        bank_account_id: formData.bank_account_id,
        bank_id: formData.bank_id,
        bank_name: formData.bank_name,
        // optional
        contractor_id: formData.contractor_id,
        contractor_name: formData.contractor_name,
        //new fields
        transaction_receipt_image_uploads: imagesToUpload,
      };

      console.log('unitRevenueModel');
      console.log(unitRevenueModel);

      // Call the createOrUpdateUnitRevenue function
      const result = await createOrUpdateUnitRevenue(unitRevenueModel);

      if (result) {
        if (result.error) {
          toast.error(result.error);
          return;
        }
        toast.success('Revenue created successfully');

        // Redirect to the unit transactions
        navigate(`/unitTransactions/${propertyUnitId}`);
      } else {
        toast.error('Error creating the revenue');
      }
    }
  };
  //handle the form input changes
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else {
      // If not, assume it's a date from DatePicker
      name = 'date';
      value = input;
    }
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...formData };
    if (value !== undefined) {
      data[name] = value;
    }

    if (name === 'date') {
      const dateValue = new Date(value);
      data.day = dateValue.getDate() + 1;
      data.month = dateValue.getMonth() + 1; // JavaScript months are 0-indexed
      data.year = dateValue.getFullYear();
    }

    if (name === 'category_id') {
      //if the label contains or is contractor (ignore case) then show the contractor options
      //search the
      categories.forEach((category) => {
        if (category.value === value) {
          if (category.label.toLowerCase().includes('contractor')) {
            setShowContractorOptions(true);
          } else {
            setShowContractorOptions(false);
          }
        }
      });
    }
    setFormData(data);
    setErrors(currentErrors);
  };
  //handle the select changes

  const handleSelectChange = (name, value) => {
    console.log('handleSelectChange');
    console.log(name);
    console.log(value);
    setFormData((prevState) => ({
      ...prevState,
      is_revenue_or_expense: value,
    }));
  };

  useEffect(() => {
    setCategoryDropDown();
  }, [formData.is_revenue_or_expense]);

  //get expense categories
  const fetchExpenseCategories = async () => {
    //get the expense categories

    const sysExpenseCategories = await getExpenseCategories();

    if (sysExpenseCategories) {
      setSysExpenseCategories(sysExpenseCategories);

      console.log(sysExpenseCategories);
    }
  };

  //get revenue categories
  const fetchRevenueCategories = async () => {
    //get the revenue categories
    const sysRevenueCategories = await getRevenueCategories();

    if (sysRevenueCategories) {
      setSysRevenueCategories(sysRevenueCategories);

      console.log(sysRevenueCategories);
    }
  };

  const setCategoryDropDown = () => {
    if (formData.is_revenue_or_expense === 'expense') {
      console.log('Expense');
      //map the sysExpenseCategories to categories
      const categories = sysExpenseCategories.map((category) => {
        return {
          value: category.expense_category_id,
          label: category.expense_category,
        };
      });

      setCategories(categories);
    } else if (formData.is_revenue_or_expense === 'revenue') {
      console.log('Revenue');
      //map the sysRevenueCategories to categories
      const categories = sysRevenueCategories.map((category) => {
        return {
          value: category.revenue_category_id,
          label: category.revenue_category,
        };
      });

      setCategories(categories);
    }
  };

  const [bankAccountOptions, setBankAccountOptions] = useState([
    {
      value: '',
      label: '',
      data: {},
    },
  ]);
  const [selectedBankAccount, setSelectedBankAccount] = useState({
    value: '',
    label: '',
    data: {},
  });
  //get all bank accounts for this unit
  const fetchBankAccounts = async () => {
    //get the bank accounts for this unit
    const bankAccounts = await getPropertyUnitBankAccounts(propertyUnitId);

    console.log('Bank Accounts');
    console.log(bankAccounts);
    //set the bank accounts
    if (bankAccounts) {
      setBankAccounts(bankAccounts);

      //map the bank accounts to the options
      const bankAccountOptions = bankAccounts.map((bankAccount) => {
        return {
          value: bankAccount.property_bank_account_id,
          label:
            bankAccount.bank_account.account_name +
            ' - ' +
            bankAccount.bank_account.bank_name,
          data: bankAccount,
        };
      });

      setBankAccountOptions(bankAccountOptions);
    }
  };

  const handleBankAccountChange = (selectedOption) => {
    console.log('handleBankAccountChange');
    console.log(selectedOption);
    setSelectedBankAccount(selectedOption);

    //set the property_bank_account_id, bank_account_id, bank_id, bank_name
    setFormData((prevState) => ({
      ...prevState,
      property_bank_account_id: selectedOption?.value,
      bank_account_id: selectedOption?.data?.bank_account_id,
      bank_id: selectedOption?.data?.bank?.bank_id,
      bank_name: selectedOption?.data.bank?.bank_name,
    }));
    //may want to filter to show the bank account details

    //if new
    if (selectedOption.__isNew__) {
      toast.info('Creating New Bank Account, refresh the page when done.');
      //create a new bank account in a new tab
      console.log('New Bank Account');
      console.log(selectedOption);

      //navigate to the create bank account page on a new tab
      window.open(
        `/createPropertyUnitBankAccounts/${propertyUnitId}`,
        '_blank'
      );
    }
  };

  useEffect(() => {
    //fetch the unit details
    //set the date

    //toast.info(propertyUnitId);
    fetchExpenseCategories();

    fetchRevenueCategories();

    fetchBankAccounts();

    getPropertyPortfolioByUnitId();
  }, []);

  //propertyPortfolioUnit
  const getPropertyPortfolioByUnitId = async () => {
    const propertyPortfolioUnit = await getPropertyDetailByPropertyUnitId(
      propertyUnitId
    );

    console.log('propertyPortfolioUnit');
    console.log(propertyPortfolioUnit);
    if (propertyPortfolioUnit) {
      setPropertyPortfolioUnit(propertyPortfolioUnit);

      //find the contractors for the portfolioId
      fetchContractorsByPortfolioId(
        propertyPortfolioUnit.portfolio.portfolio_id
      );
    }
  };

  const fetchContractorsByPortfolioId = async (_portfolioId) => {
    //get the contractors for the portfolioId
    const contractors = await getContractorsByPortfolioId(_portfolioId);

    console.log('contractors');
    console.log(contractors);

    if (contractors) {
      setContractors(contractors);

      //map the contractors to the options
      const contractorOptions = contractors.map((contractor) => {
        return {
          value: contractor.contractor_id,
          label:
            contractor.first_name +
            ' ' +
            contractor.last_name +
            ' - ' +
            contractor.company_name,
          data: contractor,
        };
      });

      setContractorOptions(contractorOptions);
    }
  };

  const handleContractorChange = (selectedOption) => {
    console.log('handleContractorChange');
    console.log(selectedOption);

    //set the contractor_id
    setFormData((prevState) => ({
      ...prevState,
      contractor_id: selectedOption?.value,
      contractor_name:
        selectedOption?.data?.first_name +
        ' ' +
        selectedOption?.data?.last_name,
    }));

    //if new
    if (selectedOption.__isNew__) {
      toast.info('Creating New Contractor, refresh the page when done.');
      //create a new contractor in a new tab
      console.log('New Contractor');
      console.log(selectedOption);

      //navigate to the create contractor page on a new tab
      window.open(`/createContractor`, '_blank');
    }
  };

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle='Property Unit Transactions'
            previousPageLink={`/unitTransactions/${propertyUnitId}`}
          />

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    New Transaction
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='title'
                              label='Transaction Title'
                              type={'text'}
                              value={formData.title}
                              onChange={handleChange}
                              error={errors.title}
                            />
                          </div>
                        </div>
                        {/* Bank Account*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label>Bank Account</label>
                            <CreatableSelect
                              name='bank_id'
                              options={bankAccountOptions}
                              value={selectedBankAccount} // find the corresponding object in the options array
                              onChange={handleBankAccountChange}
                              placeholder='Select Bank Account'
                            />
                          </div>
                        </div>

                        {/* is Revenue or Expense*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label>Revenue or Expense</label>
                            <Select
                              name='is_revenue_or_expense'
                              options={revenueOrExpenseData}
                              value={revenueOrExpenseData.find(
                                (option) =>
                                  option.value ===
                                  formData.is_revenue_or_expense
                              )} // find the corresponding object in the options array
                              onChange={(selectedOption) =>
                                handleSelectChange(
                                  'is_revenue_or_expense',
                                  selectedOption.value
                                )
                              }
                              placeholder='Select Revenue or Expense'
                            />
                          </div>
                        </div>
                        {/* TODO: show the revenue or expense catergory option beased on is_revenue_or_expense*/}
                        {formData.is_revenue_or_expense && (
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <label>Category</label>
                              <Select
                                name='category_id'
                                options={categories}
                                value={categories.find(
                                  (option) =>
                                    option.value === formData.category_id
                                )} // find the corresponding object in the options array
                                onChange={(selectedOption) =>
                                  handleChange({
                                    currentTarget: {
                                      name: 'category_id',
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                placeholder='Select Category'
                              />
                            </div>
                          </div>
                        )}

                        {showContractorOptions && (
                          <>
                            <div className='col-xxl-3 col-md-6'>
                              <div>
                                <label>Contractor</label>
                                <CreatableSelect
                                  name='contractor_id'
                                  options={contractorOptions}
                                  value={contractorOptions.find(
                                    (option) =>
                                      option.value === formData.contractor_id
                                  )} // find the corresponding object in the options array
                                  onChange={(selectedOption) =>
                                    handleContractorChange(selectedOption)
                                  }
                                  placeholder='Select Contractor'
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='amount'
                              label='Amount'
                              type={'number'}
                              value={formData.amount}
                              onChange={handleChange}
                              error={errors.amount}
                            />
                          </div>
                        </div>
                        {/* revenue_date*/}

                        <div className='col-xxl-3 col-md-6 '>
                          <div>
                            {/*
                            <Input
                              name='date'
                              label='Date'
                              type={'date'}
                              value={formData.date}
                              onChange={handleChange} // <-- handleChange will be called with an event object
                              error={errors.date}
                            />*/}

                            <Input
                              name='date'
                              className='form-control'
                              type='date'
                              label={' Select Transaction Date'}
                              selected={formData.date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        {/* description */}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='description'
                              label='Description'
                              type={'text'}
                              value={formData.description}
                              onChange={handleChange}
                              error={errors.description}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row gy-3 pt-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <label htmlFor='form-grid-category'>
                            Transaction Receipt(s)
                          </label>
                          <MultiImageUploader
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            base64Data={base64Data}
                            setBase64Data={setBase64Data}
                            imagesToUpload={imagesToUpload}
                            setImagesToUpload={setImagesToUpload}
                            uploadBtnText='Confirm Selection'
                          />
                        </div>
                      </div>

                      {/* Add to the list*/}
                      <div className='row gy-4 mt-3'>
                        <div className='col-xxl-3 col-md-6'>
                          <button
                            type='submit'
                            className='btn btn-success'
                            disabled={validate()}
                          >
                            Add Transaction
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewUnitTransaction;
