import http from "../general/httpService";
import config from "../../constants/config.json";
import api from "../../constants/config.json";

//authentication/fetch_user_by_user_id
export async function fetchOwnerProfileRecord(owner_manager_user_id) {
  const fullApiEndpoint = api.apiEndpoint + `/authentication/fetch_user_by_user_id`;
  const { data } = await http.post(fullApiEndpoint, { owner_manager_user_id: owner_manager_user_id });
  return data;
}

