import React, { useEffect, useState } from 'react';

import PageTitleBox from '../../../../reusable/title/pageTitleBox';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getCompletedTicketsByPropertyUnitId,
  getCompletedTicketsByUserId,
  getInProgressTicketsByPropertyUnitId,
  getInProgressTicketsByUserId,
  getNewTicketsByPropertyUnitId,
  getNewTicketsByUserId,
  getPendingTicketsByPropertyUnitId,
  getPendingTicketsByUserId,
  getUnitTicketsByPropertyUnitId,
} from '../../../../../services/tickets/unitTicketsManagement';

import {
  getCompletedTicketCountByPropertyUnitId,
  getInProgressTicketCountByPropertyUnitId,
  getNewTicketCountByPropertyUnitId,
  getPendingTicketCountByPropertyUnitId,
} from '../../../../../services/tickets/unitTicketsStatisticsManagement';

import LoaderView from '../../../../reusable/loading/loaderView';
import { getAllTicketsInPortfolioByUserId } from '../../../../../services/tickets/unitTicketsManagement';
import TicketsList from '../table/ticketList';
import { getAllExpensesForPropertyUnitYear } from '../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement';
import TicketsKanbanBoard from '../kanban/ticketsKanbanBoard';

//lottie here

const AllTicketsInPortfolio = ({ isDarkMode }) => {
  const { propertyUnitId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [isKanbanView, setIsKanbanView] = useState(false);

  const toggleView = () => setIsKanbanView(!isKanbanView);

  const [unitTickets, setUnitTickets] = useState([
    {
      ticket_id: '',
      property_unit_id: '',
      ticket_type: '',
      ticket_title: '',
      ticket_description: '',
      ticket_priority_id: '',
      ticket_status_id: '',
      ticket_number: '',
      ticket_status: '',
      priority_info: {
        created_at: '',
        is_active: true,
        priority: '',
        priority_id: '',
        updated_at: '',
      },
    },
  ]);

  //new tickets
  const [newUnitTickets, setNewUnitTickets] = useState([
    {
      ticket_id: '',
      property_unit_id: '',
      ticket_type: '',
      ticket_title: '',
      ticket_description: '',
      ticket_priority_id: '',
      ticket_status_id: '',
      ticket_number: '',
      ticket_status: '',
      priority_info: {
        created_at: '',
        is_active: true,
        priority: '',
        priority_id: '',
        updated_at: '',
      },
    },
  ]);

  //in progress tickets
  const [inProgressUnitTickets, setInProgressUnitTickets] = useState([
    {
      ticket_id: '',
      property_unit_id: '',
      ticket_type: '',
      ticket_title: '',
      ticket_description: '',
      ticket_priority_id: '',
      ticket_status_id: '',
      ticket_number: '',
      ticket_status: '',
      priority_info: {
        created_at: '',
        is_active: true,
        priority: '',
        priority_id: '',
        updated_at: '',
      },
    },
  ]);

  //pending tickets
  const [pendingUnitTickets, setPendingUnitTickets] = useState([
    {
      ticket_id: '',
      property_unit_id: '',
      ticket_type: '',
      ticket_title: '',
      ticket_description: '',
      ticket_priority_id: '',
      ticket_status_id: '',
      ticket_number: '',
      ticket_status: '',
      priority_info: {
        created_at: '',
        is_active: true,
        priority: '',
        priority_id: '',
        updated_at: '',
      },
    },
  ]);

  //completed tickets
  const [completedUnitTickets, setCompletedUnitTickets] = useState([
    {
      ticket_id: '',
      property_unit_id: '',
      ticket_type: '',
      ticket_title: '',
      ticket_description: '',
      ticket_priority_id: '',
      ticket_status_id: '',
      ticket_number: '',
      ticket_status: '',
      priority_info: {
        created_at: '',
        is_active: true,
        priority: '',
        priority_id: '',
        updated_at: '',
      },
    },
  ]);

  const [newTicketsCount, setNewTicketsCount] = useState(0);
  const [inProgressTicketsCount, setInProgressTicketsCount] = useState(0);
  const [pendingTicketsCount, setPendingTicketsCount] = useState(0);
  const [completedTicketsCount, setCompletedTicketsCount] = useState(0);

  //table components start

  const ticketColumns = [
    {
      field: 'ticket_number',
      headerName: 'Number',
      width: 190,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_number}</strong>,
    },
    {
      field: 'ticket_title',
      headerName: 'Title',
      width: 300,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_title}</strong>,
    },
    {
      field: 'ticket_status',
      headerName: 'Status',
      width: 120,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_status}</strong>,
    },
    {
      field: 'ticket_priority_id',
      headerName: 'Priority',
      width: 120,
      editable: false,
      renderCell: (params) => (
        <strong>{params.row.priority_info?.priority}</strong>
      ),
    },
    {
      field: 'ticket_type',
      headerName: 'Type',
      width: 150,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_type}</strong>,
    },
    {
      field: 'ticket_description',
      headerName: 'Description',
      width: 800,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_description}</strong>,
    },
  ];

  //table components end

  const fetchUnitTickets = async () => {
    try {
      const response = await getAllTicketsInPortfolioByUserId();
      setUnitTickets(response);

      setIsLoading(false);

      console.log(response);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  //statistic data start
  //get the number of new tickets
  const getNewTicketsCount = async () => {
    try {
      //no need to get the statistics for this
      const response = await getNewTicketsByUserId(propertyUnitId);
      console.log(response);
      setNewTicketsCount(response);
    } catch (error) {
      console.error(error);
    }
  };

  //get the number of in progress tickets
  const getInProgressTicketsCount = async () => {
    try {
      const response = await getInProgressTicketsByUserId(propertyUnitId);
      console.log(response);
      setInProgressTicketsCount(response);
    } catch (error) {
      console.error(error);
    }
  };

  //get the number of pending tickets
  const getPendingTicketsCount = async () => {
    try {
      const response = await getPendingTicketsByUserId(propertyUnitId);
      console.log(response);
      setPendingTicketsCount(response);
    } catch (error) {
      console.error(error);
    }
  };

  //get the number of completed tickets
  const getCompletedTicketsCount = async () => {
    try {
      const response = await getCompletedTicketsByUserId(propertyUnitId);
      console.log(response);
      setCompletedTicketsCount(response);
    } catch (error) {
      console.error(error);
    }
  };

  //call all the statistics
  const fetchUnitTicketsStatistics = async () => {
    getNewTicketsCount();
    getInProgressTicketsCount();
    getPendingTicketsCount();
    getCompletedTicketsCount();
  };

  //statistic data end

  useEffect(() => {
    window.scrollTo(0, 0);
    //need loading animation
    setIsLoading(true);

    //need this for the entire tickets
    // fetchUnitTicketsStatistics();
    fetchUnitTickets();

    //TODO: need to be updated to get the tickets by category
    getAllTicketStatus();
  }, []);

  const fetchAllNewTickets = async () => {
    try {
      const response = await getNewTicketsByUserId();
      console.log('new tickets');
      console.log(response);
      setNewUnitTickets(response);

      //set the new tickets count
      if (response) {
        setNewTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllInProgressTickets = async () => {
    try {
      const response = await getInProgressTicketsByUserId(propertyUnitId);
      console.log('in progress tickets');
      console.log(response);
      setInProgressUnitTickets(response);

      //set the in progress tickets count
      if (response) {
        setInProgressTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllPendingTickets = async () => {
    try {
      const response = await getPendingTicketsByUserId();
      console.log('pending tickets');
      console.log(response);
      setPendingUnitTickets(response);

      //set the pending tickets count
      if (response) {
        setPendingTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllCompletedTickets = async () => {
    try {
      const response = await getCompletedTicketsByUserId();
      console.log('completed tickets');
      console.log(response);
      setCompletedUnitTickets(response);

      //set the completed tickets count
      if (response) {
        setCompletedTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllTicketStatus = async () => {
    fetchAllNewTickets();
    fetchAllInProgressTickets();
    fetchAllPendingTickets();
    fetchAllCompletedTickets();

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      {/*UI Start */}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'} className='pt-5'>
        <div className='container-fluid'>
          <PageTitleBox pageTitle='All Unit Tickets in Portfolio' />

          {/*UI Start */}

          <div className='row'>
            <div className='col-xxl-3 col-sm-6'>
              <div className='card card-animate'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-medium text-muted mb-0'>New Tickets</p>
                      <h2 className='mt-4 ff-secondary fw-semibold'>
                        <span className='counter-value' data-target='547'>
                          {newTicketsCount}
                        </span>
                      </h2>
                      <p className='mb-0 text-muted' hidden>
                        <span className='badge bg-light text-success mb-0'>
                          {' '}
                          <i className='ri-arrow-up-line align-middle'></i>{' '}
                          17.32 %{' '}
                        </span>{' '}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className='avatar-sm flex-shrink-0'>
                        <span className='avatar-title bg-info-subtle text-info rounded-circle fs-4'>
                          <i className='ri-ticket-2-line'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>{' '}
              {/* end card*/}
            </div>
            <div className='col-xxl-3 col-sm-6'>
              <div className='card card-animate'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-medium text-muted mb-0'>
                        In Progress Tickets
                      </p>
                      <h2 className='mt-4 ff-secondary fw-semibold'>
                        <span className='counter-value' data-target='107'>
                          {inProgressTicketsCount}
                        </span>
                      </h2>
                      <p className='mb-0 text-muted' hidden>
                        <span className='badge bg-light text-danger mb-0'>
                          {' '}
                          <i className='ri-arrow-down-line align-middle'></i>{' '}
                          3.87 %{' '}
                        </span>{' '}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className='avatar-sm flex-shrink-0'>
                        <span className='avatar-title bg-info-subtle text-info rounded-circle fs-4'>
                          <i className='ri-shopping-bag-line'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/*end col*/}
            {/*end col*/}
            <div className='col-xxl-3 col-sm-6'>
              <div className='card card-animate'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-medium text-muted mb-0'>
                        Pending Tickets
                      </p>
                      <h2 className='mt-4 ff-secondary fw-semibold'>
                        <span className='counter-value' data-target='124'>
                          {pendingTicketsCount}
                        </span>
                      </h2>
                      <p className='mb-0 text-muted' hidden>
                        <span className='badge bg-light text-danger mb-0'>
                          {' '}
                          <i className='ri-arrow-down-line align-middle'></i>{' '}
                          0.96 %{' '}
                        </span>{' '}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className='avatar-sm flex-shrink-0'>
                        <span className='avatar-title bg-info-subtle text-info rounded-circle fs-4'>
                          <i className='mdi mdi-timer-sand'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/*end col*/}

            <div className='col-xxl-3 col-sm-6'>
              <div className='card card-animate'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-medium text-muted mb-0'>
                        Completed Tickets
                      </p>
                      <h2 className='mt-4 ff-secondary fw-semibold'>
                        <span className='counter-value' data-target='15.95'>
                          {completedTicketsCount}
                        </span>
                      </h2>
                      <p className='mb-0 text-muted' hidden>
                        <span className='badge bg-light text-success mb-0'>
                          {' '}
                          <i className='ri-arrow-up-line align-middle'></i> 1.09
                          %{' '}
                        </span>{' '}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className='avatar-sm flex-shrink-0'>
                        <span className='avatar-title bg-info-subtle text-info rounded-circle fs-4'>
                          <i className='ri-delete-bin-line'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          {isKanbanView ? (
            <TicketsKanbanBoard
              isKanbanView={isKanbanView}
              toggleView={toggleView}
              newTicketsCount={newTicketsCount}
              inProgressTicketsCount={inProgressTicketsCount}
              pendingTicketsCount={pendingTicketsCount}
              completedTicketsCount={completedTicketsCount}
              newUnitTickets={newUnitTickets}
              inProgressUnitTickets={inProgressUnitTickets}
              pendingUnitTickets={pendingUnitTickets}
              completedUnitTickets={completedUnitTickets}
              propertyUnitId={propertyUnitId}
            />
          ) : (
            <TicketsList
              isKanbanView={isKanbanView}
              toggleView={toggleView}
              unitTickets={unitTickets}
              ticketColumns={ticketColumns}
              propertyUnitId={propertyUnitId}
            />
          )}
          {/*UI End */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllTicketsInPortfolio;
