import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { createOrUpdateUnitExpense } from '../../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement';
import { createOrUpdateUnitRevenue } from '../../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement';
import { v4 as uuidv4 } from 'uuid';

const SyncTransactionsForm = ({ plaidTransactions, propertyUnitId, onClose, expenseCategories, revenueCategories, onReauthenticate }) => {
  const [transactions, setTransactions] = useState([]);
  const [itemsRequiringAttention, setItemsRequiringAttention] = useState(null);

  useEffect(() => {
    if (plaidTransactions && plaidTransactions.message === "Some items require attention") {
      setItemsRequiringAttention(plaidTransactions.items_requiring_attention);
      setTransactions([]);
    } else if (Array.isArray(plaidTransactions)) {
      const initializedTransactions = plaidTransactions.map(transaction => ({
        ...transaction,
        is_revenue_or_expense: '',
        category_id: '',
        unit_revenue_expense_id: uuidv4(),
      }));
      setTransactions(initializedTransactions);
      setItemsRequiringAttention(null);
    } else {
      console.error('Unexpected plaidTransactions format:', plaidTransactions);
      setTransactions([]);
      setItemsRequiringAttention(null);
    }
  }, [plaidTransactions]);

  const handleCellEdit = (params) => {
    const updatedTransactions = transactions.map(transaction => 
      transaction.transaction_id === params.id ? { ...transaction, [params.field]: params.value } : transaction
    );
    setTransactions(updatedTransactions);
  };

  const columns = [
    { field: 'date', headerName: 'Date', width: 120 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'amount', headerName: 'Amount', width: 120, 
      renderCell: (params) => `$${Math.abs(params.value).toFixed(2)}` 
    },
    { 
      field: 'is_revenue_or_expense', 
      headerName: 'Type', 
      width: 150,
      renderCell: (params) => (
        <Select
          value={{ value: params.value, label: params.value }}
          onChange={(option) => handleCellEdit({ id: params.id, field: 'is_revenue_or_expense', value: option.value })}
          options={[
            { value: 'revenue', label: 'Revenue' },
            { value: 'expense', label: 'Expense' },
          ]}
        />
      ),
    },
    { 
      field: 'category_id', 
      headerName: 'Category', 
      width: 200,
      renderCell: (params) => {
        const categories = params.row.is_revenue_or_expense === 'revenue' ? revenueCategories : expenseCategories;
        return (
          <Select
            value={categories.find(cat => cat.value === params.value)}
            onChange={(option) => handleCellEdit({ id: params.id, field: 'category_id', value: option.value })}
            options={categories}
          />
        );
      },
    },
  ];

  const handleConfirm = async () => {
    const errors = [];
    for (const transaction of transactions) {
      if (!transaction.is_revenue_or_expense || !transaction.category_id) {
        errors.push(`Transaction "${transaction.name}" is missing type or category.`);
      }
    }

    if (errors.length > 0) {
      toast.error(errors.join('\n'));
      return;
    }

    for (const transaction of transactions) {
      const commonFields = {
        property_unit_id: propertyUnitId,
        amount: Math.abs(transaction.amount),
        date: transaction.date,
        title: transaction.name,
        description: transaction.name,
        day: new Date(transaction.date).getDate(),
        month: new Date(transaction.date).getMonth() + 1,
        year: new Date(transaction.date).getFullYear(),
      };

      if (transaction.is_revenue_or_expense === 'expense') {
        await createOrUpdateUnitExpense({
          unit_expense_id: transaction.unit_revenue_expense_id,
          expense_category_id: transaction.category_id,
          ...commonFields,
        });
      } else {
        await createOrUpdateUnitRevenue({
          unit_revenue_id: transaction.unit_revenue_expense_id,
          revenue_category_id: transaction.category_id,
          ...commonFields,
        });
      }
    }

    toast.success('Transactions synchronized successfully');
    onClose();
  };

  if (itemsRequiringAttention) {
    return (
      <div className="items-requiring-attention">
        <h3>Items Requiring Attention</h3>
        <p>Some items require attention before transactions can be synced:</p>
        {itemsRequiringAttention.items_requiring_update.length > 0 && (
          <div>
            <h4>Items Requiring Update</h4>
            <ul>
              {itemsRequiringAttention.items_requiring_update.map((item, index) => (
                <li key={`update-${index}`}>{item.error_message}</li>
              ))}
            </ul>
          </div>
        )}
        {itemsRequiringAttention.items_requiring_consent.length > 0 && (
          <div>
            <h4>Items Requiring Consent</h4>
            <ul>
              {itemsRequiringAttention.items_requiring_consent.map((item, index) => (
                <li key={`consent-${index}`}>{item.error_message}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="mt-3">
          <button className="btn btn-primary" onClick={onReauthenticate}>Re-authenticate Accounts</button>
          <button className="btn btn-secondary ms-2" onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <DataGrid
        rows={transactions}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.transaction_id}
        onCellEditCommit={handleCellEdit}
        components={{
          Toolbar: GridToolbar,
        }}
      />
      <div className="mt-3">
        <button className="btn btn-primary" onClick={handleConfirm}>Confirm and Save</button>
        <button className="btn btn-secondary ms-2" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default SyncTransactionsForm;