import React from 'react';

const FilePDFImagePreview = ({
  itemToPreview,
  setItemToDelete,
  setShowConfirm,
}) => {
  const handleDownload = (e) => {
    e.preventDefault();
    // Open the file in a new tab, which will either display it or download it
    window.open(itemToPreview.url, '_blank');
  };

  const renderPreview = () => {
    const fileType = itemToPreview.file_type;

    if (fileType === 'application/pdf') {
      return (
        <div style={{ height: '400px', width: '100%' }}>
          <iframe
            src={itemToPreview.url}
            title='PDF Preview'
            width='100%'
            height='100%'
            style={{ border: 'none' }}
          />
        </div>
      );
    } else if (
      fileType === 'application/vnd.ms-powerpoint' ||
      fileType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      return (
        <div
          style={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p>PowerPoint preview is not available.</p>
          <a
            href={itemToPreview.url}
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-primary'
          >
            Open PowerPoint
          </a>
        </div>
      );
    } else if (
      fileType === 'application/zip' ||
      fileType === 'application/x-zip-compressed'
    ) {
      return (
        <div
          style={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p>ZIP file preview is not available.</p>
          <button onClick={handleDownload} className='btn btn-primary'>
            Download ZIP File
          </button>
        </div>
      );
    } else {
      return (
        <img
          src={itemToPreview.url}
          alt={itemToPreview.file_name}
          className='img-fluid'
          style={{ maxHeight: '400px', objectFit: 'contain' }}
        />
      );
    }
  };

  return (
    <div className='row gy-4'>
      <div className='col-xxl-12 col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='text-center'>{renderPreview()}</div>
            <div className='mt-3'>
              <h6 className='text-wrap'>
                {itemToPreview.file_name.length > 100
                  ? `${itemToPreview.file_name.substring(0, 100)}...`
                  : itemToPreview.file_name}
              </h6>
              <p className='text-muted fs-12'>
                {itemToPreview.file_size_formatted}
              </p>
              <div className='d-flex justify-content-start'>
                <button
                  onClick={handleDownload}
                  className='btn btn-link btn-sm'
                >
                  Download
                </button>
                <a
                  onClick={() => {
                    // delete the attachment
                    setItemToDelete(itemToPreview);
                    setShowConfirm(true);
                  }}
                  className='btn btn-link btn-sm text-danger'
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilePDFImagePreview;
