import React, { useEffect, useState } from 'react';

import { useSubscription } from '../../../../services/context/subscription/subscriptionContext';
import BillingHeader from './sub/billingHeader';
import { getActiveSubscriptionsFromStripe } from '../../../../services/authentication/subscription/subscriptionManagementService';
import PageTitleBox from '../../../reusable/title/pageTitleBox';
import LoaderView from '../../../reusable/loading/loaderView';

const Billing = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const pageTitle = 'Billing';

  //this should have been the subscription invoice
  const { subscription } = useSubscription();

  const [currentSubscriptionFromStripe, setCurrentSubscriptionFromStripe] =
    useState({
      id: '',
      object: '',
      application: '',
      application_fee_percent: '',
      automatic_tax: {
        enabled: true,
        liability: {
          type: '',
        },
      },
      billing_cycle_anchor: '',
      billing_cycle_anchor_config: '',
      billing_thresholds: '',
      cancel_at: '',
      cancel_at_period_end: false,
      canceled_at: '',
      cancellation_details: {
        comment: '',
        feedback: '',
        reason: '',
      },
      collection_method: '',
      created: '',
      currency: '',
      current_period_end: '',
      current_period_start: '',
      customer: '',
      days_until_due: '',
      default_payment_method: '',
      default_source: '',
      default_tax_rates: [],
      description: '',
      discount: '',
      discounts: [],
      ended_at: '',
      invoice_settings: {
        account_tax_ids: '',
        issuer: {
          type: '',
        },
      },
      items: {
        object: '',
        data: [
          {
            id: '',
            object: '',
            billing_thresholds: '',
            created: '',
            discounts: [],
            metadata: {},
            plan: {
              id: '',
              object: '',
              active: true,
              aggregate_usage: '',
              amount: '',
              amount_decimal: '',
              billing_scheme: '',
              created: '',
              currency: '',
              interval: '',
              interval_count: 1,
              livemode: false,
              metadata: {},
              meter: '',
              nickname: '',
              product: '',
              tiers_mode: '',
              transform_usage: '',
              trial_period_days: '',
              usage_type: '',
            },
            price: {
              id: '',
              object: '',
              active: true,
              billing_scheme: '',
              created: '',
              currency: '',
              custom_unit_amount: '',
              livemode: false,
              lookup_key: '',
              metadata: {},
              nickname: '',
              product: '',
              recurring: {
                aggregate_usage: '',
                interval: '',
                interval_count: 1,
                meter: '',
                trial_period_days: '',
                usage_type: '',
              },
              tax_behavior: '',
              tiers_mode: '',
              transform_quantity: '',
              type: '',
              unit_amount: '',
              unit_amount_decimal: '',
            },
            quantity: 1,
            subscription: '',
            tax_rates: [],
          },
        ],
        has_more: false,
        total_count: 1,
        url: '',
      },
      latest_invoice: '',
      livemode: false,
      metadata: {},
      next_pending_invoice_item_invoice: '',
      on_behalf_of: '',
      pause_collection: '',
      payment_settings: {
        payment_method_options: {
          acss_debit: '',
          bancontact: '',
          card: {
            network: '',
            request_three_d_secure: 'automatic',
          },
          customer_balance: '',
          konbini: '',
          sepa_debit: '',
          us_bank_account: '',
        },
        payment_method_types: '',
        save_default_payment_method: 'off',
      },
      pending_invoice_item_interval: '',
      pending_setup_intent: '',
      pending_update: '',
      plan: {
        id: '',
        object: '',
        active: true,
        aggregate_usage: '',
        amount: '',
        amount_decimal: '',
        billing_scheme: '',
        created: '',
        currency: '',
        interval: '',
        interval_count: 1,
        livemode: false,
        metadata: {},
        meter: '',
        nickname: '',
        product: '',
        tiers_mode: '',
        transform_usage: '',
        trial_period_days: '',
        usage_type: '',
      },
      quantity: 1,
      schedule: '',
      start_date: '',
      status: 'active',
      test_clock: '',
      transfer_data: '',
      trial_end: '',
      trial_settings: {
        end_behavior: {
          missing_payment_method: 'create_invoice',
        },
      },
      trial_start: '',
    });

  //TODO: get the current subscription information so it can be used to ancle or update billing info (put update on another view, updating payment as well)
  useEffect(() => {
    //get active subscriptions from stripe
    fetchActiveSubscriptionFromStripe();
  }, []);

  const fetchActiveSubscriptionFromStripe = async () => {
    setIsLoading(true);

    try {
      const activeSubscriptions = await getActiveSubscriptionsFromStripe();
      console.log('Active Subscriptions from Stripe');
      console.log(activeSubscriptions);
      setCurrentSubscriptionFromStripe(activeSubscriptions);
      setIsLoading(false);
    } catch (ex) {
      //console.log(ex);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          {/* end page title */}
          <div className='row'>
            <div className='col-xl-12'>
              {/* Reusable start */}
              <BillingHeader
                invoice={subscription}
                subscription={currentSubscriptionFromStripe}
              />
              {/* Reusable end */}
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {/* Expense Transaction Grid */}
          {/* {expensesAndTransactions &&
            expensesAndTransactions?.length > 0 &&
            expensesAndTransactions[0]?.expense_id && (
              <ExpenseGrid
                expenses={expensesAndTransactions}
                title='Current Expenses'
              />
            )}*/}

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Billing;
