import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { logout } from '../../../services/authentication/auth';
class LogOut extends Component {
  async componentDidMount() {
    // remove token from local storage and wait for it to complete
    const logoutSuccessful = await logout();

    if (logoutSuccessful) {
      // redirect user to home page (force refresh)
      window.location = '/';
    } else {
      // show an error toast
      toast.error('Logout failed');
    }
  }

  render() {
    return null;
  }
}

export default LogOut;
