import React, { useState } from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Input from "../../../../../reusable/form/Input";

const PropertyStep = ({
  isDarkMode,
  pageTitle,
  existingPortfolioOptions,
  handlePortfolioChange,
  selectedCurrencyOption,
  handleCurrencyChange,
  currencyOptions,
  selectedPropertyTypeOption,
  handlePropertyTypeChange,
  propertyTypeOptions,
  selectedSubPropertyTypeOption,
  handleSubPropertyTypeChange,
  subPropertyTypeOptions,
  propertyData,
  handleChange,
  errors,
  formattedCountryOptions,
  handleCountryChange,
  validate,
  handleSubmit,
}) => {
  const [touchedFields, setTouchedFields] = useState({});
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const requiredFields = [
    { key: "portfolio_id", label: "Portfolio" },
    { key: "currency_id", label: "Currency" },
    { key: "property_type_id", label: "Property Type" },
    { key: "sub_property_type_id", label: "Property Sub Type" },
    { key: "property_alias_name", label: "Property Alias Name" },
    { key: "street_address_1", label: "Street Address 1" },
    { key: "city_town", label: "City/Town" },
    { key: "state_parish", label: "State/Parish/Province" },
    { key: "zip_postal_code", label: "Zip/Postal Code" },
    { key: "country", label: "Country" },
  ];

  const missingFields = requiredFields.filter(
    (field) => !propertyData[field.key] && propertyData[field.key] !== 0
  );

  console.log("Property Data:", propertyData);
  console.log("Missing Fields:", missingFields);
  console.log("Errors:", errors);
  console.log("Validate result:", validate());

  const isButtonDisabled =
    missingFields.length > 0 ||
    Object.keys(errors).length > 0 ||
    validate() !== null;

  const handleBlur = (fieldName) => {
    setTouchedFields((prev) => ({ ...prev, [fieldName]: true }));
  };

  const handleSubmitAttempt = () => {
    setAttemptedSubmit(true);
    if (!isButtonDisabled) {
      handleSubmit();
    }
  };

  const showFieldError = (fieldName) =>
    (touchedFields[fieldName] || attemptedSubmit) &&
    (!propertyData[fieldName] || errors[fieldName]);

  return (
    <React.Fragment>
      <div className="card-body">
        <div className="live-preview">
          <div className="row gy-4">
            <div className="col-xxl-3 col-md-6">
              <div>
                {/* <Input
                              name='expense_name'
                              label='Expense Name'
                              value={expenseData.expense_name}
                              onChange={handleChange}
                              error={errors.expense_name}
                            />*/}
                <label htmlFor="form-grid-category">Portfolio</label>
                <CreatableSelect
                  name="portfolio_id"
                  options={existingPortfolioOptions}
                  onChange={handlePortfolioChange}
                  isClearable
                />
              </div>
            </div>

            <div className="col-xxl-3 col-md-6">
              <div>
                <label htmlFor="form-grid-category">Currency</label>
                <Select
                  value={selectedCurrencyOption}
                  onChange={handleCurrencyChange}
                  options={currencyOptions}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-md-6">
              <div>
                <label htmlFor="form-grid-category">Property Type</label>
                <Select
                  value={selectedPropertyTypeOption}
                  onChange={handlePropertyTypeChange}
                  options={propertyTypeOptions}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-md-6">
              <div>
                <label htmlFor="form-grid-category">Property Sub Type</label>
                <Select
                  value={selectedSubPropertyTypeOption}
                  onChange={handleSubPropertyTypeChange}
                  options={subPropertyTypeOptions}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-md-6">
              <div>
                <Input
                  name="property_alias_name"
                  label="Property Alias Name"
                  type={"text"}
                  value={propertyData.property_alias_name}
                  onChange={handleChange}
                  onBlur={() => handleBlur("property_alias_name")}
                  error={
                    showFieldError("property_alias_name")
                      ? errors.property_alias_name
                      : null
                  }
                />
                {showFieldError("property_alias_name") &&
                  !errors.property_alias_name && (
                    <small className="text-danger">
                      This field is required
                    </small>
                  )}
              </div>
            </div>

            <div className="col-xxl-3 col-md-6">
              <div>
                <Input
                  name="street_address_1"
                  label="Street Address 1"
                  type={"text"}
                  value={propertyData.street_address_1}
                  onChange={handleChange}
                  onBlur={() => handleBlur("street_address_1")}
                  error={
                    showFieldError("street_address_1")
                      ? errors.street_address_1
                      : null
                  }
                />
                {showFieldError("street_address_1") &&
                  !errors.street_address_1 && (
                    <small className="text-danger">
                      This field is required
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xxl-3 col-md-6">
              <div>
                <Input
                  name="street_address_2"
                  label="Street Address 2 (Optional)"
                  type={"text"}
                  value={propertyData.street_address_2}
                  onChange={handleChange}
                  onBlur={() => handleBlur("street_address_2")}
                  error={errors.street_address_2}
                />
                {/* No error message for optional field */}
              </div>
            </div>

            <div className="col-xxl-3 col-md-6">
              <div>
                <Input
                  name="city_town"
                  label="City/Town"
                  type={"text"}
                  value={propertyData.city_town}
                  onChange={handleChange}
                  onBlur={() => handleBlur("city_town")}
                  error={showFieldError("city_town") ? errors.city_town : null}
                />
                {showFieldError("city_town") && !errors.city_town && (
                  <small className="text-danger">This field is required</small>
                )}
              </div>
            </div>

            <div className="col-xxl-3 col-md-6">
              <div>
                <Input
                  name="state_parish"
                  label="State/Parish/Province"
                  type={"text"}
                  value={propertyData.state_parish}
                  onChange={handleChange}
                  onBlur={() => handleBlur("state_parish")}
                  error={
                    showFieldError("state_parish") ? errors.state_parish : null
                  }
                />
                {showFieldError("state_parish") && !errors.state_parish && (
                  <small className="text-danger">This field is required</small>
                )}
              </div>
            </div>
            <div className="col-xxl-3 col-md-6">
              <div>
                <Input
                  name="zip_postal_code"
                  label="Zip/Postal Code"
                  type={"number"}
                  value={propertyData.zip_postal_code}
                  onChange={handleChange}
                  onBlur={() => handleBlur("zip_postal_code")}
                  error={
                    showFieldError("zip_postal_code")
                      ? errors.zip_postal_code
                      : null
                  }
                />
                {showFieldError("zip_postal_code") &&
                  !errors.zip_postal_code && (
                    <small className="text-danger">
                      This field is required
                    </small>
                  )}
              </div>
            </div>

            <div className="col-xxl-3 col-md-6">
              <div>
                {/* <Input
                              name='expense_name'
                              label='Expense Name'
                              value={expenseData.expense_name}
                              onChange={handleChange}
                              error={errors.expense_name}
                            />*/}
                <label htmlFor="form-grid-category">Country</label>

                <CreatableSelect
                  name="country"
                  options={formattedCountryOptions}
                  onChange={handleCountryChange}
                  isClearable
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitAttempt}
              disabled={attemptedSubmit && isButtonDisabled}
            >
              Save and Continue
            </button>
            {attemptedSubmit && isButtonDisabled && (
              <small className="text-muted ms-2">
                Please fill in all required fields and correct any errors before
                submitting.
              </small>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertyStep;
